import React from "react"

import Card from '../Card'
import AsyncSelect from "../common/AsyncSelect";

import ReactTable from "react-table"
import "react-table/react-table.css"

import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'
import Notify from "../../../utils/Notify"
import Event from "../../../utils/Event"

const OBJECTS_FILTERS = {
  name: {
    api: "objects",
    display: "Status"
  },
  values: [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Hidden",
      value: "deleted"
    }
  ]
}

export default class BaseTable extends React.Component {

  constructor(props){
    super(props)


    let objectFilters = General.clone(OBJECTS_FILTERS)
    objectFilters.values[1].label = props.activeObjectLabel
    objectFilters.values[2].label = props.deletedObjectLabel

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: "",
      csvData: [],
      filterValue: {objects: "all"},
      searchFilter: this.props.searchFilter,
      filters: this.props.filters ? [...this.props.filters, objectFilters] : [objectFilters],
    }

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  componentDidMount(){
    Event.on('ASSOCIATION_CHANGED', () => {
      this.refresh()
    })
  }

  refresh() {
    let current = this.reactTable.current;
    if (current) {
      current.state.page = 0
      this.setState({
        loading: false
      }, () => this._fetchData(current.state, current))
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    this.refresh()
  }, 500)

  _handleFilterChange(event){
    let { filterValue } = this.state

    filterValue[event.target.name] = event.target.value
    this.setState({filterValue}, () => this._handleSearch())
  }

  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    if(this.state.filterValue){
      Object.entries(this.state.filterValue).map(filter => {
        params[filter[0]] = filter[1]
      })
    }

    let { association } = this.state
    if(association){
      if(association === "any"){
        params.has_association = true
      }
      else{
        params.association_id = association.id
      }
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    if(this.props.endpoint === ""){
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false
      });
      return
    }
    FetchHelper.get(url)
      .then(response => {
        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          totalRecords: response.count,
          loading: false
        });
      })
      .catch(error => {

      })
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
      url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _exportPressed(){
    if(this.state.exporting){
      return
    }

    let exportableColumns = this.props.columns.filter(column => column.exportable != false)

    let prefetchRelated = []
    let columns = exportableColumns.map(column => {
      let data = {
        name: column.Header,
        accessor: column.id || column.accessor,
        type: column.type,
        format: column.format
      }

      data.accessor.replace(/\./g, "__")
      if(data.accessor.indexOf('__') !== -1){
        prefetchRelated.push(data.accessor)
      }
      return data
    })

    let data = {
      export: true,
      options: encodeURIComponent(JSON.stringify({
        columns,
        prefetch_related: prefetchRelated,
        select_related: [],
      })),
      search_term: this.state.searchTerm
    }

    if(this.state.searchFilter){
      data[this.props.searchFilterName] = this.state.searchFilter
    }

    if(this.props.defaultSorted && this.props.defaultSorted.length > 0){
      data.order_by = this.props.defaultSorted[0].id.replace(/\./g, "__")
      if(this.props.defaultSorted[0].desc){
        data.order_by = "-"+data.order_by
      }
    }

    let endpoint = this._addParams(this.props.endpoint, data)

    this.setState({ exporting: true })
    FetchHelper.download(endpoint, `${this.props.title}.csv`)
      .then(() => {
        this.setState({ exporting: false })
      })
      .catch(error => {
        Notify.error(error.message)
      })
  }

  _renderHeaderLeftContent(){
    let {
      filters,
      association
    } = this.state

    return(
      <>
        <div className="col">
          <div className="input-icon">
            <input
              type="text"
              className="form-control"
              placeholder={this.props.searchPlaceholder}
              onChange={e => {
                this.setState({
                  searchTerm: e.target.value
                }, () => this._handleSearch())
              }}
            />
            <span>
              <i className="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        {
          filters &&
          <>
            {
              filters.map(filter => {
                return (
                  <div className="col my-2 my-md-0">
                    <div className="d-flex align-items-center">
                      <label className="mr-3 mb-0 d-none d-md-block">{General.toTitleCase(filter.name.display)}:</label>
                      <div className="select-wrapper w-100">
                        <select
                          className="form-control m-select"
                          name={filter.name.api}
                          onChange={e => {
                            this._handleFilterChange(e)
                          }}
                          defaultValue={this.props.searchFilter}
                        >
                          {filter.values.map(filterOptions => {
                            return (
                              <option value={filterOptions.value || ""}>
                                {filterOptions.label}
                              </option>
                            )
                          })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </>
        }
      </>
    )
  }

  _renderExportButtons(){
    return null

    if(!this.props.exportButtonsEnabled){
      return null
    }

    return (
      <>
        <label style={{ opacity: 0 }}>Export</label>
        <div className="row">
          <div className="col-md-12 text-right">
            <a
              className="btn btn-secondary"
              onClick={() => this._exportPressed()}
            >
              Export to CSV
            </a>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { data, pagesNo, loading, totalRecords } = this.state;
    return (
      <Card
        title={this.props.title}
        icon={this.props.icon}
        renderToolbar={this.props.renderToolbar()}
        className={this.props.className}
        loading={loading}
        showHeader={this.props.showHeader}
        showExport={this.props.showExport}
        onExportClicked={() => this._exportPressed()}
      >
        <div>
          { this.props.showFilter && this.props.showSearch &&
          <div className="row mb-7">
            <div className="col-md-12">
              <div className="dataTables_filter">
                <div className="row">
                  {this._renderHeaderLeftContent()}
                </div>
              </div>
            </div>
          </div>
          }
          <ReactTable
            ref={this.reactTable}
            manual
            data={data}
            pages={pagesNo}
            totalRecords={totalRecords}
            loading={loading}
            LoadingComponent={Loading}
            onFetchData={this._fetchData.bind(this)}
            columns={this.props.columns}
            getTdProps={this.props.getTdProps}
            getTrProps={this.props.getTrProps}
            getTheadProps={this.props.getTheadProps}
            getTheadThProps={this.props.getTheadThProps}
            showPagination={this.props.showPagination}
            showPaginationTop={this.props.showPaginationTop}
            showPaginationBottom={this.props.showPaginationBottom}
            defaultSorted={this.props.defaultSorted}
            SubComponent={this.props.SubComponent}
            NoDataComponent={() => {
              return (
                <div className="rt-noData">
                  { this.props.noDataMessage}
                </div>
              )
            }}
          />
        </div>
      </Card>
    )
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search...",
  showHeader: true,
  showFilter: true,
  icon: null,
  filters: null,
  searchFilter: null,
  searchFilterName: "name",
  noDataMessage: "No documents found",
  showSearch: true,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
  activeObjectLabel: "Active",
  deletedObjectLabel: "Archived"
}

class Loading extends React.Component {
  render () {
    return (
      this.props.loading
        ? <div className='-loading -active'>
          <div className='-loading-inner'>
          </div>
        </div>
        : null
    )
  }
}
