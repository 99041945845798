import React from "react"

export default class StatCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      amount: props.amount,
      title: props.title,
      plain: props.plain,
      icon: props.icon
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderIcon(icon){

    switch (icon) {
      case 'graph':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
            <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
            <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
            <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
          </g>
        )
      case 'chart':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="13" rx="1.5"></rect>
            <rect fill="#000000" opacity="0.3" x="7" y="9" width="3" height="8" rx="1.5"></rect>
            <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fillRule="nonzero"></path>
            <rect fill="#000000" opacity="0.3" x="17" y="11" width="3" height="6" rx="1.5"></rect>
          </g>
        )
      case 'check':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
          </g>
        )
      case 'warning':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"></path>
            <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"></rect>
            <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"></rect>
          </g>
        )
      case 'send':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <path d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z" fill="#000000"></path>
          </g>
        )
      default: return null
    }

  }

  _renderAmount(){
    let {
      plain,
      amount
    } = this.state

    let titleTextColor = plain ? 'text-dark-75' : 'text-white'

    if(amount != null){
      return (
        <span className={`card-title font-weight-bolder ${titleTextColor} font-size-h2 mb-0 mt-6 d-block`}>{amount}</span>
      )
    }
    return (
      <span className={`card-title font-weight-bolder ${titleTextColor} font-size-h2 mb-0 mt-6 d-block shine w-25`}/>
    )
  }

  render() {
    let {
      title,
      plain,
      icon
    } = this.state

    let style = plain ? {backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: 'url(assets/media/icons/abstract-1.svg)'} : {}
    let className = plain ? 'card card-custom bgi-no-repeat card-stretch gutter-b' : 'card card-custom bg-primary card-stretch gutter-b'
    let subTitleTextColor = plain ? 'text-muted' : 'text-white'
    let iconColor = plain ? 'svg-icon-primary' : 'svg-icon-white'

    return (
      <div className="col-lg-4">
        <div className={className} style={style}>
          <div className="card-body">
            <span className={`svg-icon svg-icon-2x ${iconColor}`}>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                {this._renderIcon(icon)}
              </svg>
            </span>
            {this._renderAmount()}
            <span className={`font-weight-bold ${subTitleTextColor} font-size-sm`}>{title}</span>
          </div>
        </div>
      </div>
    )
  }

}