import React from "react";

import Modal from "react-bootstrap/Modal";

import QRCode from "react-qr-code";

import Notify from "../../../../utils/Notify";
import AuthManager from "../../../../utils/AuthManager";


export default class DeactivateMFAMethodModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      verificationMethod: props.verificationMethod
    };
  }

  componentDidMount(){
    if(this.state.show){
      this._requestCode(false)
    }
  }

  componentWillReceiveProps(nextProps) {
    let requestCode = nextProps.show && !this.state.show
    this.setState(nextProps, () => {
      if(requestCode){
        this._requestCode()
      }
    });
  }

  _isFormValid() {
    let { code } = this.state;

    let error = null;

    if (!code) {
      error = "Please enter a valid code.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }
    return true;
  }

  _deactivateCode() {
    const { verificationMethod, code } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    AuthManager.deactivateCode(verificationMethod.method, code)
    .then(() => {
      this.setState({ code, isLoading: false });
      this.props.onDeactivated();
    })
    .catch((error) => {
      Notify.error(error.message);
      this.setState({
        isLoading: false,
      });
    });
  }

  _requestCode(showAlert=true) {
    const { verificationMethod } = this.state;

    this.setState({ isLoading: true });

    AuthManager.requestCode(verificationMethod.method)
    .then(() => {
      this.setState({ isLoading: false });
      if(showAlert){
        Notify.success("Code resent successfully")
      }
    })
    .catch((error) => {
      Notify.error(error.message);
      this.setState({
        isLoading: false,
      });
    });
  }


  render() {
    let { show, isLoading, code } = this.state;

    const { verificationMethod } = this.props;

    let method = verificationMethod.method

    let subtitle = method == "app" ? "Enter a code from your Authenticator app to confirm" : "Enter the code sent to your phone to confirm"

    return (
      <>
        <Modal show={show} onHide={() => this.props.onHide()}>
          <Modal.Header closeButton>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-dark">
              {subtitle}
            </div>
            <div className="text-dark-75 font-weight-bold mt-8">
              Enter 6-digit code
            </div>
            <div className="form-group row">
              <div className="col">
                <input
                  className="form-control form-control-solid"
                  type="text"
                  name="code"
                  value={code}
                  onChange={(e) => {
                    code = e.target.value;
                    this.setState({ code });
                  }}
                />
                {method !== "app" && (
                  <>
                    <span
                      className="text-muted ml-1"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Didn't receive a code ?
                      <a
                        href="javascript:;"
                        tabIndex="4"
                        className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                        onClick={() => this._requestCode()}
                      >
                        Resend Code
                      </a>
                    </span>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger font-weight-bold px-9 py-3"
              disabled={isLoading}
              onClick={() => this._deactivateCode()}
            >
              Deactivate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

DeactivateMFAMethodModal.defaultProps = {

};
