import React from "react"

import moment from 'moment'

import StatCard from "./components/common/StatCard";
import DateRange from "./components/common/DateRange";
import PaymentRequestsTable from "./components/tables/PaymentRequestsTable";
import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import Event from "../utils/Event";
import Stats from "../utils/Stats";

export default class Requests extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      stats: null,
      dateFrom: null,
      dateTo: null,
      currency: 0
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    let dateTo = moment()
    let dateFrom = moment().add(-1, "years")

    Backend.getPaymentRequestStats(dateFrom.toISOString(), dateTo.toISOString())
      .then(stats => {
        this.setState({
          stats: Stats.getPaymentRequestStats(stats.admin || stats.association)
        })
      })
  }

  _renderStatCards(){
    let {
      stats,
      currency
    } = this.state

    if(!stats) return null

    return (
      <>
        <StatCard
          amount={stats.sent}
          title={'Requests Sent'}
          icon={'send'}
          plain={true}
        />
        <StatCard
          amount={stats.paid}
          title={'Requests Paid'}
          icon={'check'}
          plain={true}
        />
        <StatCard
          amount={stats.totalPaid}
          title={'Total Value of PR’s Paid'}
          icon={'warning'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {
      dateFrom,
      dateTo
    } = this.state

    return (
      <>
        <DateRange
          dateFrom={dateFrom}
          dateTo={dateTo}
          onFromDateUpdated={(dateFrom) => {
            this.setState({
              dateFrom
            }, () => this._loadStats())
          }}
          onToDateUpdated={(dateTo) => {
            this.setState({
              dateTo
            }, () => this._loadStats())
          }}
        />
        <div className={'row'}>
          {this._renderStatCards()}
        </div>
        <PaymentRequestsTable
          title={'All Requests'}
          showExport={false}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </>
    )
  }

}
