import React from "react"

import BaseTable from "./BaseTable"

import moment from "moment"

import Backend from '../../../utils/Backend'

import EditCell from "./cells/EditCell";
import CustomerCell from "./cells/CustomerCell";
import ConfirmationModal from "../modal/ConfirmationModal";
import Notify from "../../../utils/Notify";
import TeamMemberModal from "../modal/TeamMemberModal";

export default class CompanyMembersTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: member => moment(member.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Company',
        id: 'company.name',
        accessor:  member => member.company.name
      },
      {
        Header: 'User',
        id: 'user',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let user = rowInfo.original.user || null
          return (
            <CustomerCell
              customer={user}
            />
          )
        },
        width: 160
      },
      {
        Header: 'Last Logged In',
        id: 'user.last_logged_in',
        sortable: false,
        exportable: false,
        accessor: member => {
          let user = member.user || null
          if(user?.last_seen){
            return moment(user.last_seen).format('DD MMM YYYY')
          }
          return "-"
        }
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        width: 180,
        Cell: rowInfo => {
          let member = rowInfo.original
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              <EditCell
                onEditClicked={() => {
                  this.setState({
                    showMemberModal: true,
                    member: member
                  })
                }}
              />
            </span>
          )
        }
      }
    ]
  }

  _verifyCompany(){
    let {
      company
    } = this.state

    company.settings.live = true

    Backend.updateCompany(company)
    .then(company => {
      Notify.success("Company Has Been Verified")
      this.setState({ loading: false })
      this.props.onAdded(company)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderToolBar(){
    return (
      <></>
    )
  }

  _renderTeamMemberModal(){
    let {
      member,
      showMemberModal,
    } = this.state

    return (
      <TeamMemberModal
        show={showMemberModal}
        company={true}
        member={member}
        onHide={() => this.setState({ showMemberModal: false, member: null })}
        onAdded={teamMember => {
          this.table.current.refresh()
          this.setState({showMemberModal: false, member: null})
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure? This action can't be redone."}
        onConfirm={() => {
          this._verifyCompany()
        }}
        onHide={() => {
          this.setState({
            company: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  render() {
    let {
      live,
    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.CompanyMembers}`}
          noDataMessage={"No company member found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderTeamMemberModal() }
        { this._renderConfirmationModal() }
      </div>
    )
  }
}

CompanyMembersTable.defaultProps = {
  latestResults: false
}
