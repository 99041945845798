import React from 'react'
import Modal from 'react-bootstrap/Modal'


import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import DeleteCell from '../tables/cells/DeleteCell'
import RandomiseCell from '../tables/cells/RandomiseCell'

import Partner from "../../../utils/Partner"

export default class DemoCustomer extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      answers: props.answers,
    }
  }

  render() {
    let {
      answers,
    } = this.state

    return (
      <>
        <table className="table c-table">
            <thead>
              <tr>
                <td>Question</td>
                <td>Answer</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>

                { answers.map((data, index) => {
                    return (
                      <tr className="demo">
                        <td>
                          <input
                            type="text"
                            name="question"
                            placeholder={"question"}
                            className="form-control form-control-solid"
                            value={data.question}
                            onChange={e => {
                              answers[index].question = e.target.value
                              this.props.onUpdated(answers)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="answer"
                            placeholder={"answer"}
                            className="form-control form-control-solid"
                            value={data.answer}
                            onChange={e => {
                              answers[index].answer = e.target.value
                              this.props.onUpdated(answers)
                            }}
                          />
                        </td>
                        <td>
                          <RandomiseCell
                            onRandomiseClicked={() => {
                              answers[index] = Partner.getRandonAnswer()
                              this.props.onUpdated(answers)
                            }}
                          />
                          <DeleteCell
                            tooltip="Delete"
                            onDeleteClicked={() => {
                              answers.splice(index, 1)
                              this.props.onUpdated(answers)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })
                }

            </tbody>
        </table>

        <div className="row mt-6 mb-0 pt-2">
          <div className="col text-right">
            <a
              className="btn btn-light-primary font-weight-bolder"
              onClick={() => {
                answers.push(Partner.getRandonAnswer())
                this.props.onUpdated(answers)
              }}
            >
              <i className="la la-plus"></i>Add Data
            </a>
          </div>
        </div>
      </>
    )
  }
}

DemoCustomer.defaultProps = {

}
