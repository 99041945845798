import React from 'react'
import Modal from 'react-bootstrap/Modal'

import bigDecimal from 'js-big-decimal'

import { components } from 'react-select';
import Select from 'react-select';

import Separator from "../../common/Separator"

import { PAYMENT_METHOD_LOGOS } from "../../../CompanyProcessors"

import Notify from "../../../../utils/Notify"
import General from "../../../../utils/General"
import Backend from "../../../../utils/Backend"
import Processors from "../../../../utils/Processors"

export default class PaymentMethodModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(props){

    this.setState(this._getState(props), () => {
      let { paymentMethod } = this.state
      if(!paymentMethod){
        return
      }
      this._load()
    })
  }

  _getState(props){
    let data = { ...props}
    data.paymentMethods = []
    if(data.paymentMethod){
      data.paymentMethod = General.clone(data.paymentMethod)
      data.paymentMethod.value = data.paymentMethod.id
      data.paymentMethods = [data.paymentMethod]
    }
    return data
  }

  _load(){
    let { company, paymentMethod } = this.state
    Backend.getPaymentMethods(company, paymentMethod.type)
    .then(paymentMethods => {
      this.setState({
        paymentMethods: paymentMethods.map(paymentMethod => {
          paymentMethod.value = paymentMethod.id
          return paymentMethod
        }),
      })
    })
    .catch(error => {

    })
  }

  _save(){
    let { paymentMethod } = this.state
    let data = { ...paymentMethod }
    data.default = true
    this.setState({ loading: true })
    Backend.updatePaymentMethod(data)
    .then(paymentMethod => {
      this.props.onUpdated()
      Notify.success("Payment Method Updated!")
      this.setState({ loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderContent() {
      let {
        show,
        company,
        paymentMethod,
        paymentMethods
      } = this.state

      let isAssociationCompany = company.association != null
      let { rate } = paymentMethod

      rate = rate || {}
      paymentMethod.rate = rate
  
      let key = isAssociationCompany ? "association" : "platform"
      let percentage = rate[`${key}_percentage`]
      let flatCharge = rate[`${key}_flat_charge`]
      let maxCharge = rate[`${key}_max_charge`]

      return (
        <>
          {/* starts : Default Processor */}
          <div className="form-group">
            <label className="col-form-label">Processor</label>
              <div className="">
                <div className="">
                  <div className="select-wrapper w-100">

                    <Select
                        className={'processor custom-async-select__container'}
                        classNamePrefix={'custom-async-select'}
                        options={paymentMethods}
                        defaultValue={paymentMethod}
                        components={{ Option: IconOption, SingleValue: ValueOption }}
                        onChange={option => {
                          this.setState({ paymentMethod: option })
                        }}
                    />

                  </div>

                </div>
              </div>
          </div>
          {/* end : Default Processor */}

          <Separator text="Fees"/>

          {/* starts : Fees */}
          <div className="form-group mb-0">
            <div className="row">
              {/* Starts : Colum : % Fee */}
              <div className="col">
                <label>Percentage</label>
                <div className="input-group">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    defaultValue={percentage ? bigDecimal.multiply(percentage, 100) : ""}
                    placeholder="% Fee"
                    onChange={(e) => {
                      let value = e.target.value ? parseFloat(e.target.value) : null
                      let percentage = value ? parseFloat(bigDecimal.divide(e.target.value, 100)) : 0
                      paymentMethod.rate[`${key}_percentage`] = percentage
                      this.setState({ paymentMethod })
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text border-0">%</span>
                  </div>
                </div>
              </div>
              {/* End : Colum : % Fee */}

              {/* Starts : Colum : Flat Fee */}
              <div className="col">
                <label>Flat</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text border-0">€</span>
                  </div>
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    defaultValue={flatCharge ? flatCharge / 100 : ""}
                    placeholder="Flat Fee"
                    onChange={(e) => {
                      let flatCharge = e.target.value ? Math.round(parseFloat(e.target.value) * 100) : null
                      paymentMethod.rate[`${key}_flat_charge`] = flatCharge
                      this.setState({ paymentMethod })
                    }}
                  />
                </div>
              </div>
              {/* End : Colum : Flat Fee */}

              {/* Starts : Colum : Max Fee */}
              <div className="col">
                <label>Max</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text border-0">€</span>
                  </div>
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    defaultValue={maxCharge ? maxCharge / 100 : ""}
                    placeholder="Max Fee"
                    onChange={(e) => {
                      let maxCharge = e.target.value ? Math.round(parseFloat(e.target.value) * 100) : null
                      paymentMethod.rate[`${key}_max_charge`] = maxCharge
                      this.setState({ paymentMethod })
                    }}
                  />
                </div>
              </div>
              {/* End : Colum : Max Fee */}
            </div>
          </div>
          {/* end : Fees */}
        </>
      )
  }

  render() {
    let {
      show,
      loading,
      paymentMethod
    } = this.state

    if(!show || !paymentMethod){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => {
            this.props.onHide()
        }}
        className={""}
        centered
        size=""
        dialogClassName={'c-modal edit-payment-methods'}
      >

        <Modal.Header closeButton>
          <Modal.Title>
            <img src={PAYMENT_METHOD_LOGOS[paymentMethod.type]}/>
              {General.snakeCaseToTitleCase(paymentMethod.type)}
            <span>|</span> Fees & Processors
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {this._renderContent()}
        </Modal.Body>

        <Modal.Footer>
          <div className='buttons-grid'>
            <button
              type="button"
              className="btn btn-secondary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={loading}
              className="btn btn-primary font-weight-bold"
              onClick={() => this._save()}
            >
              Save Changes
            </button>
          </div>
        </Modal.Footer>

      </Modal>
    )
  }
}

const { Option, SingleValue } = components;
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={Processors.getImage({ slug: props.data.processor })}
    />
    {General.snakeCaseToTitleCase(props.data.processor)}
  </Option>
);

const ValueOption = (props) => (
  <SingleValue {...props}>
    <img
    src={Processors.getImage({ slug: props.data.processor })}
    />
    {General.snakeCaseToTitleCase(props.data.processor)}
  </SingleValue>
);
