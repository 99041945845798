import React from 'react'
import Modal from 'react-bootstrap/Modal'
import slug from "limax";

import AuthManager from "../../../utils/AuthManager"
import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency";
import Calculations from "../../../utils/Calculations";

import ImageEdit from "../common/ImageEdit";
import AsyncSelect from "../common/AsyncSelect";
import ColorPicker from "../common/ColorPicker";
import SmartList from "../common/SmartList";
import Select from "../common/CustomSelect";

import { TAX } from '../../../constants/Variables';

const FREQUENCY_OPTIONS = [
  {
    label: "Monthly",
    value: "monthly"
  },
  {
    label: "Yearly",
    value: "yearly"
  },
  {
    label: "Once Off",
    value: "one_time"
  }
]

export default class PackageModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      packageObject: props.packageObject || {
        currency: window.General.DefaultCurrency,
        add_ons: {},
        benefits: [],
        free_trial_days: 0
      },
    }
  }

  _handleChange(e) {
    let packageObject = this.state.packageObject
    packageObject[e.target.name] = e.target.value
    this.setState({ packageObject, error: null })
  }

  _isFormValid(){
    let {
      packageObject
    } = this.state

    let error = null

    if(packageObject.name == null || packageObject.name.length === 0){
      error = "Please enter a package name"
    }else if(packageObject.slug == null || packageObject.slug.length === 0){
      error = "Please enter a package slug"
    }else if(!/[a-z]/i.test(packageObject.slug)){
      error = "Slug must contain at least 1 letter"
    }else if(packageObject.title == null || packageObject.title.length === 0){
      error = "Please enter a package title"
    }else if(!packageObject.frequency){
      error = "Please select a frequency"
    }else if(packageObject.total < 0){
      error = "Please enter a valid price"
    }else if(packageObject.free_trial_days < 0){
      error = "Please enter a valid free trial period"
    }else if(!packageObject.cta){
      error = "Please enter a CTA"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleUpdatePackage(){
    if(!this._isFormValid()){
      return
    }
    if(this.state.packageObject.id){
      this._updatePackage()
    }else {
      this._createPackage()
    }
  }

  _createPackage(){
    let {
      packageObject
    } = this.state

    this.setState({ loading: true })
    Backend.createPackage(packageObject)
    .then(packageObject => {
      Notify.success("Package Created Successfully")
      this.setState({ loading: false })
      this.props.onAdded(packageObject)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updatePackage(){
    let {
      packageObject
    } = this.state

    this.setState({ loading: true })
    Backend.updatePackage(packageObject)
    .then(packageObject => {
      Notify.success("Package Updated Successfully")
      this.setState({ loading: false })
      this.props.onAdded(packageObject)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderItem(item, index){
    let {
      packageObject
    } = this.state

    return (
      <>
        <div className={"row mb-3"}>
          <div className={"col-8"}>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder={"Title"}
              value={ packageObject.benefits[index]?.title || '' }
              onChange={e => {
                packageObject.benefits[index].title = e.target.value
                this.setState({ packageObject })
              }}
            />
          </div>
        </div>
        <div className={"row mb-3"}>
          <div className={"col-8"}>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder={"Subtitle"}
              value={ packageObject.benefits[index]?.subtitle || '' }
              onChange={e => {
                packageObject.benefits[index].subtitle = e.target.value
                this.setState({ packageObject })
              }}
            />
          </div>
        </div>
      </>
    )
  }

  _renderDelete(item, index){
    let {
      packageObject
    } = this.state

    return (
      <a href="javascript:;" data-repeater-delete=""
         className="btn font-weight-bold btn-danger btn-icon"
         onClick={() => {
           packageObject.benefits.splice(index, 1)
           this.setState({ packageObject })
         }}
      >
        <i className="la la-remove"></i>
      </a>
    )
  }

  render() {
    let {
      show,
      packageObject,
      loading,
    } = this.state

    if(!packageObject) return null

    let title = packageObject.id ? "Update Package" : "Create Package";
    let buttonTitle = packageObject.id ? 'Update' : 'Create';

    let platformRate = parseFloat(packageObject.platform_rate) ? Math.round((packageObject.platform_rate * 100) * 100) / 100 : ''
    let clientCut = parseFloat(packageObject.rate) ? Math.round((packageObject.rate * 100) * 100) / 100 : ''
    let partnerCut = parseFloat(packageObject.association_rate) ? Math.round((packageObject.association_rate * 100) * 100) / 100 : ''

    let platformFixedCharge = packageObject.platform_flat_charge ? packageObject.platform_flat_charge / 100 : ""
    let partnerFixedCharge = packageObject.association_flat_charge ? packageObject.association_flat_charge / 100 : ""

    let price = packageObject.subtotal ? (packageObject.subtotal / 100) : ''
    let percentage = packageObject.vat_percentage ? Math.round((packageObject.vat_percentage * 100) * 100) / 100 || '' : Math.round((Calculations.getPercentage(packageObject.total, packageObject.subtotal)) * 100) / 100 || ''
    let vatEnabled = percentage > 0 || packageObject.enableVat
    let minPayment = packageObject.min_payment ? (packageObject.min_payment / 100) : ''

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-3 col-form-label">Name</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={packageObject.name}
                name={"name"}
                onChange={e => this._handleChange(e)}
                onBlur={() => {
                  packageObject.slug = packageObject.slug || slug(packageObject.name || "")
                  this.setState({ packageObject })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Slug</label>
            <div className="col-9">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{border:'none'}}>dashboard.{window.General.WebsiteDomain}/account?u=c&p=</span>
                </div>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  value={packageObject.slug}
                  name={"slug"}
                  disabled={!!packageObject.id}
                  onChange={e => this._handleChange(e)}
                  onBlur={() => {
                    packageObject.slug = slug(packageObject.slug || "")
                    this.setState({ packageObject })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Title</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={packageObject.title}
                name={"title"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Subtitle</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={packageObject.subtitle}
                name={"subtitle"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Description</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                value={packageObject.description}
                name={"description"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          <div className="form-group row background">
            <label className="col-3 col-form-label">Background Image</label>
            <div className="col-9">
              <ImageEdit
                image={packageObject.background}
                hidePreview={false}
                deletable={true}
                imagePicker={true}
                type={"banner"}
                onUpdated={image => {
                  this.setState({loading: false})
                  packageObject.background = image
                  this.setState({packageObject, error: null})
                }}
                onDeleteClicked={() => {
                  packageObject.background = null
                  this.setState({packageObject, error: null})
                }}
              />
            </div>
          </div>

          {/*
          <div className="form-group row background">
            <label className="col-3 col-form-label">Foreground Image</label>
            <div className="col-9">
              <ImageEdit
                image={packageObject.foreground}
                hidePreview={false}
                deletable={true}
                imagePicker={true}
                type={"banner"}
                onUpdated={image => {
                  this.setState({loading: false})
                  packageObject.foreground = image
                  this.setState({packageObject, error: null})
                }}
                onDeleteClicked={() => {
                  packageObject.foreground = null
                  this.setState({packageObject, error: null})
                }}
              />
            </div>
          </div>
          */}

          <div className="form-group row">
            <label className="col-3 col-form-label">Frequency</label>
            <div className="col-9">
              <Select
                value={FREQUENCY_OPTIONS.find(frequency => frequency.value === packageObject.frequency)}
                className="form-control h-auto border-0 form-control-solid c-selectbox"
                name="account_holder_type"
                placeholder="Please Select A Frequency"
                onChange={option => {
                  packageObject.frequency = option.value
                  this.setState({ packageObject })
                }}
                options={FREQUENCY_OPTIONS}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Currency</label>
            <div className="col-9">
              <AsyncSelect
                endpoint={window.Api.Currencies}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                params={{ }}
                value={packageObject.currency ? General.getSingleAsyncOption(packageObject.currency) : null}
                onSelected={currency => {
                  packageObject.currency = currency
                  this.setState({packageObject})
                }}
                getOptions={currencies => General.getAsyncOptions(currencies)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Price</label>
            <div className="col-9">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                </div>
                <input
                  className="form-control form-control-solid"
                  type="number"
                  value={price}
                  placeholder="0.00"
                  name="subtotal"
                  step={"0.01"}
                  onChange={(e) => {
                    packageObject["subtotal"] = Math.round(e.target.value * 100)
                    if(packageObject.vat_percentage){
                      packageObject["total"] = Math.round(parseFloat(Calculations.totalIncludingVat(e.target.value * 100, packageObject.vat_percentage, false)))
                    }else{
                      packageObject["total"] = Math.round(e.target.value * 100)
                    }
                    this.setState({ packageObject, error: null })
                  }}
                />
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-1 mt-3">
              <span className="switch switch-outline switch-icon switch-primary">
                <label>
                  <input
                    type="checkbox"
                    id="vatSelect"
                    checked={packageObject.enableVat || vatEnabled}
                    onChange={e => {
                      let show = e.target.checked
                      if(show){
                        packageObject.enableVat = show
                        packageObject.vat_percentage = 0
                        packageObject.vat = Math.round(parseFloat(Calculations.totalVat(packageObject.subtotal, 0, false)))
                        packageObject.total = Math.round(parseFloat(Calculations.totalIncludingVat(packageObject.subtotal, 0, false)))
                      }else{
                        packageObject.enableVat = show
                        packageObject.vat_percentage = null
                        packageObject.vat = 0
                        packageObject.total = packageObject.subtotal
                      }
                      this.setState({ packageObject })
                    }}
                  />
                  <span></span>
                </label>
              </span>
            </div>
            <label htmlFor="vatSelect" className="col-6 col-form-label align-left mt-3">Enable {TAX}</label>
            {
              vatEnabled &&
              <>
                <div className="col-3"></div>
                <div className="col-9">
                  <div className="row" id="vatOptions">
                    <div className="col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{border: 'none'}}>%</span>
                        </div>
                        <input
                          className="form-control form-control-solid"
                          type="number"
                          min={0}
                          placeholder="0"
                          name={"vat"}
                          value={percentage}
                          onChange={(e) => {
                            packageObject["vat_percentage"] = e.target.value / 100
                            packageObject["vat"] = Math.round(parseFloat(Calculations.totalVat(packageObject.subtotal, e.target.value / 100, false)))
                            packageObject["total"] = Math.round(parseFloat(Calculations.totalIncludingVat(packageObject.subtotal, e.target.value / 100, false)))
                            this.setState({ packageObject})
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Total"
                          value={Currency.format('', packageObject.total)}
                          disabled=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Free Trial Days</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="number"
                defaultValue={packageObject.free_trial_days}
                name={"free_trial_days"}
                onChange={e => {
                  packageObject.free_trial_days = e.target.value || 0
                  this.setState({ packageObject })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">CTA</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={packageObject.cta}
                name={"cta"}
                onChange={e => this._handleChange(e)}
              />
            </div>
          </div>

          { AuthManager.isPlatformAdmin() &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">splink % Rate</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    placeholder="0.00"
                    step={"0.01"}
                    value={platformRate}
                    onChange={e => {
                      packageObject.platform_rate = (e.target.value / 100).toFixed(4)
                      this.setState({ packageObject })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">splink Fixed Fee</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    placeholder="0.00"
                    step={"0.01"}
                    value={platformFixedCharge}
                    onChange={e => {
                      packageObject.platform_flat_charge = parseFloat(e.target.value) * 100
                      this.setState({ packageObject })
                    }}
                  />
                </div>
              </div>
            </>
          }

          { AuthManager.isPlatformAdmin() &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Partner</label>
              <div className="col-9">
                <AsyncSelect
                  endpoint={window.Api.Associations}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  params={{ }}
                  value={General.getSingleAsyncOption(packageObject.association)}
                  onSelected={association => {
                    packageObject.association = association
                    this.setState({packageObject})
                  }}
                  getOptions={associations => General.getAsyncOptions(associations)}
                />
              </div>
            </div>
          }

          { (packageObject.association || !AuthManager.isPlatformAdmin()) &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">
                { AuthManager.isPlatformAdmin() ? "Partner % Rate" : AuthManager.currentUser.association.name + " % Rate"}
                </label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    placeholder="0.00"
                    step={"0.01"}
                    value={partnerCut}
                    onChange={e => {
                      packageObject.association_rate = (e.target.value / 100).toFixed(4)
                      this.setState({ packageObject })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">
                { AuthManager.isPlatformAdmin() ? "Partner Fixed Fee" : window.General.Branding.Name + " Fixed Fee"}
                </label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    placeholder="0.00"
                    step={"0.01"}
                    value={partnerFixedCharge}
                    onChange={e => {
                      packageObject.association_flat_charge = parseFloat(e.target.value) * 100
                      this.setState({ packageObject })
                    }}
                  />
                </div>
              </div>
            </>
          }


          <div className="form-group row">
            <label className="col-3 col-form-label">Client Cut</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="number"
                placeholder="0.00"
                step={"0.01"}
                value={clientCut}
                onChange={e => {
                  packageObject.rate = (e.target.value / 100).toFixed(4)
                  this.setState({ packageObject })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">
              Minimum Payment
            </label>
            <div className="col-9">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                </div>
                <input
                  className="form-control form-control-solid"
                  type="number"
                  placeholder="0.00"
                  step={"0.01"}
                  value={minPayment}
                  onChange={e => {
                    packageObject.min_payment = parseFloat(e.target.value) * 100
                    this.setState({ packageObject })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group row questionBox" id="questionInput" style={{border: 'none'}}>
            <label className="col-3 col-form-label">Benefits</label>
            <div className="col-9">
              <SmartList
                items={packageObject.benefits}
                itemName="Benefit"
                showItemName={true}
                value={item => item.text}
                disabled={item => item.id != null}
                onChange={(value, item, index) => {
                  packageObject.benefits[index].text = value
                  this.setState({ packageObject })
                }}
                onUpdated={questions => {
                  packageObject.benefits = questions
                  this.setState({ packageObject })
                }}
                onAddItemPressed={() => {
                  packageObject.benefits.push({title: "", subtitle: ""})
                  this.setState({ packageObject })
                }}
                renderItem={(item, index) => this._renderItem(item, index)}
                renderDelete={(item, index) => this._renderDelete(item, index)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button type="button" className="btn btn-primary font-weight-bold" id="add-success" disabled={loading}
                  onClick={() => this._handleUpdatePackage()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
