import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import ImageEdit from "../../common/ImageEdit";

import UnconnectedProcessors from "./UnconnectedProcessors"
import StripeConnectProcessor from "./StripeConnectProcessor"
import AibConnectProcessor from "./AibConnectProcessor"

import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

export default class EMerchantPayConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      company: props.company,
      data: props.data ||  {
        username: "",
        password: "",
        terminals: {
          eur: {},
          gbp: {},
          usd: {}
        },
        public_key: ""
      },
    }
  }

  _isFormValid(){
    let { data } = this.state

    let {
      username,
      password,
      public_key,
      terminals
    } = data

    let error = null
    if(!username){
      error = "Please provide a valid username"
    }
    else if(!password){
      error = "Please provide a valid password"
    }
    else if(!public_key){
      error = "Please provide a valid Public Key"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _connect(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectToEMerchantPay(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render(){
    let {
      data,
      loading
    } = this.state

    let currencyCodes = ["eur", "gbp", "usd"]
    return (
      <>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-3 col-form-label">Username</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.username}
                  onChange={e => {
                    data.username = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Password</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.password}
                  onChange={e => {
                    data.password = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Public Key</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.public_key}
                  onChange={e => {
                    data.public_key = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          { currencyCodes.map(code => {
              let upperCaseCode = code.toUpperCase()
              let terminal = data.terminals[code] || {}
              return (
                <>
                  <div className="c-separator form-group row mt-4 align-items-center text-center">
                    <div className="col-3">
                      <hr/>
                    </div>
                    <div className="col-6">
                      <span>
                        <strong>
                          {upperCaseCode} Terminal
                        </strong>
                      </span>
                    </div>
                    <div className="col-3">
                      <hr/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-3 col-form-label">'Sale' {upperCaseCode} Terminal</label>
                    <div className="col-9">
                      <input
                          className="form-control form-control-solid"
                          type="text"
                          value={terminal.no_3d}
                          onChange={e => {
                            terminal.no_3d = e.target.value || null
                            data.terminals[code] = terminal
                            this.setState({ data })
                          }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-3 col-form-label">'Sale 3D' {upperCaseCode} Terminal</label>
                    <div className="col-9">
                      <input
                          className="form-control form-control-solid"
                          type="text"
                          value={terminal["3d"]}
                          onChange={e => {
                            terminal["3d"] = e.target.value || null
                            data.terminals[code] = terminal
                            this.setState({ data })
                          }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-3 col-form-label">'No CVV' {upperCaseCode} Terminal</label>
                    <div className="col-9">
                      <input
                          className="form-control form-control-solid"
                          type="text"
                          value={terminal.no_cvv}
                          onChange={e => {
                            terminal.no_cvv = e.target.value || null
                            data.terminals[code] = terminal
                            this.setState({ data })
                          }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-3 col-form-label">'NFC' {upperCaseCode} Terminal</label>
                    <div className="col-9">
                      <input
                          className="form-control form-control-solid"
                          type="text"
                          value={terminal.nfc}
                          onChange={e => {
                            terminal.nfc = e.target.value || null
                            data.terminals[code] = terminal
                            this.setState({ data })
                          }}
                      />
                    </div>
                  </div>
                </>
              )
            })
          }
        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }
}
