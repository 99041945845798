import React from 'react'
import Modal from 'react-bootstrap/Modal'
import slug from "limax";

import bigDecimal from 'js-big-decimal'

import AuthManager from "../utils/AuthManager"
import Backend from "../utils/Backend"
import General from "../utils/General";
import Notify from "../utils/Notify"
import Currency from "../utils/Currency";
import Calculations from "../utils/Calculations";
import Processors from "../utils/Processors"

import ImageEdit from "./components/common/ImageEdit";
import AsyncSelect from "./components/common/AsyncSelect";
import ColorPicker from "./components/common/ColorPicker";
import SmartList from "./components/common/SmartList";
import Select from "./components/common/CustomSelect";

import { TAX } from '../constants/Variables';

import Div from './components/layouts/Div'
import Section from './components/common/Section'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

const FREQUENCY_OPTIONS = [
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Yearly",
        value: "yearly"
    },
    {
        label: "Once Off",
        value: "one_time"
    }
]

export default class PackageModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this._getState(props),
            show: props.show,
            loading: false,
            error: null,
            processors: []
        }

    }

    componentDidMount() {
      Backend.getProcessors()
      .then(processors => this.setState({ processors }))

      let id =  this.props.match.params.package_id
      if (id) {
        this._setupPackage(id)
      } else {
        this.setState({ loading: false })
      }
    }

    _setupPackage(id) {
      this.setState({ packageObject: null })
      Backend.getPackage(id)
      .then((packageObject) => {
        this.setState({ packageObject, loading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this._getState(nextProps))
    }

    _getState(props) {
        return {
            ...props,
            packageObject: props.packageObject || {
                currency: window.General.DefaultCurrency,
                add_ons: {},
                benefits: [],
                free_trial_days: 0,
                rates: {
                  default: {
                    card: null,
                    default: true
                  }
                }
            },
        }
    }

    _handleChange(e) {
        let packageObject = this.state.packageObject
        packageObject[e.target.name] = e.target.value
        this.setState({ packageObject, error: null })
    }

    _isFormValid() {
        let {
            packageObject
        } = this.state

        let error = null

        if (packageObject.name == null || packageObject.name.length === 0) {
            error = "Please enter a package name"
        } else if (packageObject.slug == null || packageObject.slug.length === 0) {
            error = "Please enter a package slug"
        } else if (!/[a-z]/i.test(packageObject.slug)) {
            error = "Slug must contain at least 1 letter"
        } else if (packageObject.title == null || packageObject.title.length === 0) {
            error = "Please enter a package title"
        } else if (!packageObject.frequency) {
            error = "Please select a frequency"
        } else if (packageObject.total < 0) {
            error = "Please enter a valid price"
        } else if (packageObject.free_trial_days < 0) {
            error = "Please enter a valid free trial period"
        } else if (!packageObject.cta) {
            error = "Please enter a CTA"
        }

        if (error) {
            Notify.error(error)
            return false
        }
        return true
    }

    _handleUpdatePackage() {
        if (!this._isFormValid()) {
            return
        }
        if (this.state.packageObject.id) {
            this._updatePackage()
        } else {
            this._createPackage()
        }
    }

    _createPackage() {
        let {
            packageObject
        } = this.state

        this.setState({ loading: true })
        Backend.createPackage(packageObject)
        .then(packageObject => {
          Notify.success("Package Created Successfully")
          this.setState({ loading: false })
          this.props.history.push("/packages")
        })
        .catch(error => {
          this.setState({ loading: false })
          Notify.error(error.message)
        })
    }

    _updatePackage() {
        let {
          packageObject
        } = this.state

        this.setState({ loading: true })
        Backend.updatePackage(packageObject)
        .then(packageObject => {
          Notify.success("Package Updated Successfully")
          this.setState({ loading: false })
          this.props.history.push("/packages")
        })
        .catch(error => {
          this.setState({ loading: false })
          Notify.error(error.message)
        })
    }

    _renderItem(item, index) {
        let {
            packageObject
        } = this.state

        return (
            <>
                <div className={"row mb-3"}>
                    <div className={"col-8"}>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder={"Title"}
                            value={packageObject.benefits[index]?.title || ''}
                            onChange={e => {
                                packageObject.benefits[index].title = e.target.value
                                this.setState({ packageObject })
                            }}
                        />
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col-8"}>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder={"Subtitle"}
                            value={packageObject.benefits[index]?.subtitle || ''}
                            onChange={e => {
                                packageObject.benefits[index].subtitle = e.target.value
                                this.setState({ packageObject })
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }

    _renderDelete(item, index) {
        let {
            packageObject
        } = this.state

        return (
            <a href="javascript:;" data-repeater-delete=""
                className="btn font-weight-bold btn-danger btn-icon"
                onClick={() => {
                    packageObject.benefits.splice(index, 1)
                    this.setState({ packageObject })
                }}
            >
                <i className="la la-remove"></i>
            </a>
        )
    }

    _renderSection(
        title,
        content,
        collapsed = false,
        collapsible = false,
        subtitle = null
    ) {
        return (
            <Section
                title={title}
                subtitle={subtitle}
                content={content}
                collapsed={collapsed}
                collapsible={collapsible}
            />
        )
    }

    _renderProcessorFees() {
        let {
          processors,
          packageObject
        } = this.state


        let processorsWithDefault = [
          {
            slug: "default",
            name: "Default",
            className: "default"
          },
          ...processors
        ]

        let isAssociationPackage = packageObject.association != null

        return (
            <Tab.Container defaultActiveKey="0">
                <Nav variant="tabs" className="">
                    {processorsWithDefault.map((processor, index) => {
                      let logo = Processors.getImage(processor)
                      return (
                        <Nav.Item
                            className={processor.className || ""}
                            title={processor.name}
                        >
                            <Nav.Link eventKey={index}>
                                { logo &&
                                  <>
                                    <span
                                        className='img'
                                        style={{ backgroundImage: `url(${logo})` }}
                                    />
                                    <span className='name'>{processor.name}</span>
                                  </>
                                }
                                { !logo &&
                                  <span>{processor.name}</span>
                                }
                            </Nav.Link>
                        </Nav.Item>
                      )
                    }
                  )}
                </Nav>
                <Tab.Content key={packageObject.association?.id || "platform"}>
                    {processorsWithDefault.map((processor, index) => {
                      return (
                        <Tab.Pane eventKey={index}>
                            <div className="c-p-table">
                                <table class="table">
                                    <tbody>
                                    <tr
                                        className={`method`}
                                    >
                                        <td>
                                        </td>
                                        <td className="field">
                                          <label>Percentage</label>
                                        </td>
                                        <td className="field">
                                          <label>Flat</label>
                                        </td>
                                        <td className="field">
                                            <label>Max</label>
                                        </td>
                                    </tr>
                                      {Object.keys(PAYMENT_METHOD_LOGOS).map((paymentMethodType, index) => {
                                        packageObject.rates = packageObject.rates || {}
                                        let packageProcessorRates = packageObject.rates[processor.slug] || {}
                                        let paymentMethodRate = packageProcessorRates[paymentMethodType] || {
                                          default: processor.slug == "default"
                                        }

                                        packageProcessorRates[paymentMethodType] = paymentMethodRate
                                        packageObject.rates[processor.slug] = packageProcessorRates

                                        let logo = PAYMENT_METHOD_LOGOS[paymentMethodType]

                                        let key = isAssociationPackage ? "association" : "platform"
                                        let percentage = paymentMethodRate[`${key}_percentage`]
                                        let flatCharge = paymentMethodRate[`${key}_flat_charge`]
                                        let maxCharge = paymentMethodRate[`${key}_max_charge`]
                                        return (
                                            <tr
                                                className={`method`}
                                                key={index}
                                            >
                                                {/* Starts : Colum : Method */}
                                                <td>
                                                    <div className="name">
                                                        <span
                                                            className='img'
                                                            style={{ backgroundImage: `url(${logo})` }}
                                                        />
                                                        {General.snakeCaseToTitleCase(paymentMethodType)}
                                                    </div>
                                                </td>
                                                {/* End : Colum : Method */}
                                                {/* Starts : Colum : % Fee */}
                                                <td className="field">
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control form-control-solid"
                                                            type="number"
                                                            defaultValue={percentage ? bigDecimal.multiply(percentage, 100) : ""}
                                                            placeholder="% Fee"
                                                            onChange={(e) => {
                                                              let value = e.target.value ? parseFloat(e.target.value) : null
                                                              let percentage = value ? parseFloat(bigDecimal.divide(e.target.value, 100)) : 0
                                                              paymentMethodRate[`${key}_percentage`] = percentage
                                                              this.setState({ packageObject })
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-0">%</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* End : Colum : % Fee */}

                                                {/* Starts : Colum : Flat Fee */}
                                                <td className="field">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                                                        </div>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            type="number"
                                                            defaultValue={flatCharge ? flatCharge / 100 : ""}
                                                            placeholder="Flat Fee"
                                                            onChange={(e) => {
                                                              let flatCharge = e.target.value ? Math.round(parseFloat(e.target.value) * 100) : null
                                                              paymentMethodRate[`${key}_flat_charge`] = flatCharge
                                                              this.setState({ packageObject })
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                {/* End : Colum : Flat Fee */}

                                                {/* Starts : Colum : Max Fee */}
                                                <td className="field">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                                                        </div>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            type="number"
                                                            defaultValue={maxCharge ? maxCharge / 100 : ""}
                                                            placeholder="Max Fee"
                                                            onChange={(e) => {
                                                              let maxCharge = e.target.value ? Math.round(parseFloat(e.target.value) * 100) : null
                                                              paymentMethodRate[`${key}_max_charge`] = maxCharge
                                                              this.setState({ packageObject })
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                {/* End : Colum : Max Fee */}
                                            </tr>
                                          )
                                      })}
                                    </tbody>
                                </table>
                            </div>
                        </Tab.Pane>
                      )}
                    )}
                </Tab.Content>
            </Tab.Container>
        )
    }

    render() {
        let {
            show,
            packageObject,
            loading,
        } = this.state

        if (!packageObject) return null

        let title = packageObject.id ? "Update Package" : "Create Package";
        let buttonTitle = packageObject.id ? 'Update' : 'Create';

        let platformRate = parseFloat(packageObject.platform_rate) ? Math.round((packageObject.platform_rate * 100) * 100) / 100 : ''
        let clientCut = parseFloat(packageObject.rate) ? Math.round((packageObject.rate * 100) * 100) / 100 : ''
        let partnerCut = parseFloat(packageObject.association_rate) ? Math.round((packageObject.association_rate * 100) * 100) / 100 : ''

        let platformFixedCharge = packageObject.platform_flat_charge ? packageObject.platform_flat_charge / 100 : ""
        let partnerFixedCharge = packageObject.association_flat_charge ? packageObject.association_flat_charge / 100 : ""

        let price = packageObject.subtotal ? (packageObject.subtotal / 100) : ''
        let percentage = packageObject.vat_percentage ? Math.round((packageObject.vat_percentage * 100) * 100) / 100 || '' : Math.round((Calculations.getPercentage(packageObject.total, packageObject.subtotal)) * 100) / 100 || ''
        let vatEnabled = percentage > 0 || packageObject.enableVat
        let minPayment = packageObject.min_payment ? (packageObject.min_payment / 100) : ''

        let formClassName = ''

        return (
            <>
                <Div
                    className={`row custom-form ${formClassName}`}
                    disabled={loading}
                >
                    <div className="col-12">

                        {/* starts : 01 Section Details */}
                        {this._renderSection(
                            'Details',
                            <div className="section details">

                                {/* starts : Package Name */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Package Name</label>
                                    <div className="col-lg-9 my-auto">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder={'Package Name'}
                                            className="form-control form-control-solid"
                                            value={packageObject.name}
                                            onChange={e => this._handleChange(e)}
                                            onBlur={() => {
                                                packageObject.slug = packageObject.slug || slug(packageObject.name || "")
                                                this.setState({ packageObject })
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* end : Package Name */}

                                {/* starts : Slug */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Slug
                                    </label>
                                    <div className="col-lg-9 my-auto">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ border: 'none' }}>dashboard.{window.General.WebsiteDomain}/account?u=c&p=</span>
                                            </div>
                                            <input
                                                className="form-control form-control-solid"
                                                type="text"
                                                value={packageObject.slug}
                                                name={"slug"}
                                                disabled={!!packageObject.id}
                                                onChange={e => this._handleChange(e)}
                                                onBlur={() => {
                                                    packageObject.slug = slug(packageObject.slug || "")
                                                    this.setState({ packageObject })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* end : Slug */}

                                {/* starts : Package Title */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Title</label>
                                    <div className="col-lg-9 my-auto">
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder={'Package Title'}
                                            className="form-control form-control-solid"
                                            value={packageObject.title}
                                            onChange={e => this._handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {/* end : Package Title */}

                                {/* starts :Subtitle */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Subtitle</label>
                                    <div className="col-lg-9 my-auto">
                                        <input
                                            type="text"
                                            name="subtitle"
                                            placeholder={'Package Subtitle'}
                                            className="form-control form-control-solid"
                                            value={packageObject.subtitle}
                                            onChange={e => this._handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {/* end : Package Subtitle */}

                                {/* starts :Description */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Description</label>
                                    <div className="col-lg-9 my-auto">
                                        <textarea
                                            name="description"
                                            rows="3"
                                            placeholder={'Package Description'}
                                            className="form-control form-control-solid"
                                            value={packageObject.description}
                                            onChange={e => this._handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {/* end : Package Description */}

                                {/* starts :Background Image */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Background Image</label>
                                    <div className="col-lg-9 my-auto">
                                        <ImageEdit
                                            image={packageObject.background}
                                            hidePreview={false}
                                            deletable={true}
                                            imagePicker={true}
                                            type={"banner"}
                                            onUpdated={image => {
                                                this.setState({ loading: false })
                                                packageObject.background = image
                                                this.setState({ packageObject, error: null })
                                            }}
                                            onDeleteClicked={() => {
                                                packageObject.background = null
                                                this.setState({ packageObject, error: null })
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* end : Background Image */}

                                {/* starts : CTA */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">CTA</label>
                                    <div className="col-lg-9 my-auto">
                                        <input
                                            className="form-control form-control-solid"
                                            type="text"
                                            placeholder={''}
                                            value={packageObject.cta}
                                            name={"cta"}
                                            onChange={e => this._handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {/* end : CTA */}

                            </div>,
                            false,
                            true
                        )}
                        {/* end : 01 Section Details */}

                        {/* starts : 02 Section Payments */}
                        {this._renderSection(
                            'Payments',
                            <div className="section payments">

                                {/* starts : Frequency */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Frequency</label>
                                    <div className="col-lg-9 my-auto">
                                        <Select
                                            value={FREQUENCY_OPTIONS.find(frequency => frequency.value === packageObject.frequency)}
                                            className="form-control h-auto border-0 form-control-solid c-selectbox"
                                            name="account_holder_type"
                                            placeholder="Please Select A Frequency"
                                            onChange={option => {
                                                packageObject.frequency = option.value
                                                this.setState({ packageObject })
                                            }}
                                            options={FREQUENCY_OPTIONS}
                                        />
                                    </div>
                                </div>
                                {/* end : Frequency */}

                                {/* starts : Currency */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Currency
                                    </label>
                                    <div className="col-lg-9 my-auto">
                                        <AsyncSelect
                                            endpoint={window.Api.Currencies}
                                            className={'custom-async-select__container'}
                                            classNamePrefix={'custom-async-select'}
                                            params={{}}
                                            placeholder="Select A Currency"
                                            value={packageObject.currency ? General.getSingleAsyncOption(packageObject.currency) : null}
                                            onSelected={currency => {
                                                packageObject.currency = currency
                                                this.setState({ packageObject })
                                            }}
                                            getOptions={currencies => General.getAsyncOptions(currencies)}
                                        />
                                    </div>
                                </div>
                                {/* end : Currency */}

                                {/* starts : Price */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">
                                        Price
                                    </label>
                                    <div className="col-lg-9 my-auto">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                                            </div>
                                            <input
                                                className="form-control form-control-solid"
                                                type="number"
                                                value={price}
                                                placeholder="0.00"
                                                name="subtotal"
                                                step={"0.01"}
                                                onChange={(e) => {
                                                    packageObject["subtotal"] = Math.round(e.target.value * 100)
                                                    if (packageObject.vat_percentage) {
                                                        packageObject["total"] = Math.round(parseFloat(Calculations.totalIncludingVat(e.target.value * 100, packageObject.vat_percentage, false)))
                                                    } else {
                                                        packageObject["total"] = Math.round(e.target.value * 100)
                                                    }
                                                    this.setState({ packageObject, error: null })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3"></div>
                                    <div className="col-1 mt-3">
                                        <span className="switch switch-outline switch-icon switch-primary">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    id="vatSelect"
                                                    checked={packageObject.enableVat || vatEnabled}
                                                    onChange={e => {
                                                        let show = e.target.checked
                                                        if (show) {
                                                            packageObject.enableVat = show
                                                            packageObject.vat_percentage = 0
                                                            packageObject.vat = Math.round(parseFloat(Calculations.totalVat(packageObject.subtotal, 0, false)))
                                                            packageObject.total = Math.round(parseFloat(Calculations.totalIncludingVat(packageObject.subtotal, 0, false)))
                                                        } else {
                                                            packageObject.enableVat = show
                                                            packageObject.vat_percentage = null
                                                            packageObject.vat = 0
                                                            packageObject.total = packageObject.subtotal
                                                        }
                                                        this.setState({ packageObject })
                                                    }}
                                                />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <label htmlFor="vatSelect" className="col-6 col-form-label align-left mt-3">Enable {TAX}</label>
                                    {
                                        vatEnabled &&
                                        <>
                                            <div className="col-3"></div>
                                            <div className="col-9">
                                                <div className="row" id="vatOptions">
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" style={{ border: 'none' }}>%</span>
                                                            </div>
                                                            <input
                                                                className="form-control form-control-solid"
                                                                type="number"
                                                                min={0}
                                                                placeholder="0"
                                                                name={"vat"}
                                                                value={percentage}
                                                                onChange={(e) => {
                                                                    packageObject["vat_percentage"] = e.target.value / 100
                                                                    packageObject["vat"] = Math.round(parseFloat(Calculations.totalVat(packageObject.subtotal, e.target.value / 100, false)))
                                                                    packageObject["total"] = Math.round(parseFloat(Calculations.totalIncludingVat(packageObject.subtotal, e.target.value / 100, false)))
                                                                    this.setState({ packageObject })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">€</span>
                                                            </div>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Total"
                                                                value={Currency.format('', packageObject.total)}
                                                                disabled="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                {/* end : Price */}

                                {/* starts : Free Trial Days */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Free Trial Days</label>
                                    <div className="col-lg-9 my-auto">
                                        <input
                                            className="form-control form-control-solid"
                                            type="number"
                                            placeholder='0'
                                            defaultValue={packageObject.free_trial_days}
                                            name={"free_trial_days"}
                                            onChange={e => {
                                                packageObject.free_trial_days = e.target.value || 0
                                                this.setState({ packageObject })
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* end : Free Trial Days */}

                                {/* starts : Minimum Payment */}
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Minimum Payment</label>
                                    <div className="col-lg-9 my-auto">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text border-0">{packageObject.currency.symbol}</span>
                                            </div>
                                            <input
                                                className="form-control form-control-solid"
                                                type="number"
                                                placeholder="0.00"
                                                step={"0.01"}
                                                value={minPayment}
                                                onChange={e => {
                                                    packageObject.min_payment = parseFloat(e.target.value) * 100
                                                    this.setState({ packageObject })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* end : Minimum Payment */}

                            </div>,
                            false,
                            true
                        )}
                        {/* end : 02 Section Payments */}

                        {/* starts : 03 Section Fees */}
                        {this._renderSection(
                            'Fees',
                            <div className="section fees">

                                {/* starts : Partner */}
                                {AuthManager.isPlatformAdmin() &&
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Partner</label>
                                        <div className="col-lg-9 my-auto">
                                            <AsyncSelect
                                                endpoint={window.Api.Associations}
                                                className={'custom-async-select__container'}
                                                classNamePrefix={'custom-async-select'}
                                                params={{}}
                                                disabled={packageObject.id}
                                                value={General.getSingleAsyncOption(packageObject.association)}
                                                onSelected={association => {
                                                    packageObject.association = association

                                                    // reset the rates when association changes
                                                    packageObject.rates = {
                                                      default: {
                                                        card: null,
                                                        default: true
                                                      }
                                                    }
                                                    this.setState({ packageObject })
                                                }}
                                                getOptions={associations => General.getAsyncOptions(associations)}
                                            />
                                        </div>
                                    </div>
                                }
                                {/* end : Partner */}

                                {this._renderProcessorFees()}
                            </div>,
                            false,
                            true
                        )}
                        {/* end : 03 Section Fees */}

                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions pull-right">
                                <button
                                    type="button"
                                    className="btn btn-primary ml-3"
                                    disabled={loading}
                                    onClick={() => this._handleUpdatePackage()}
                                >
                                    {buttonTitle}
                                </button>
                            </div>
                        </div>

                    </div>

                </Div>
            </>

        )
    }

}

export const PAYMENT_METHOD_LOGOS = {
  card: require("../assets/media/logos/payment-methods/cards.png"),
  sepa: require("../assets/media/logos/payment-methods/sepa.png"),
  sepa_instant: require("../assets/media/logos/payment-methods/sepa.png"),
  ach: require("../assets/media/logos/payment-methods/ach.png"),
  eft: require("../assets/media/logos/payment-methods/eft.png"),
  interac: require("../assets/media/logos/payment-methods/interac.png"),
  digital_wallet: require("../assets/media/logos/payment-methods/digital-wallet.png"),
  klarna:  require("../assets/media/logos/payment-methods/klarna.png"),
  faster_payments: require("../assets/media/logos/payment-methods/faster-payments.png"),
  bacs: require("../assets/media/logos/payment-methods/bacs.png"),
}
