import React from "react";

import bigDecimal from 'js-big-decimal'

import LazyLoadingList from './components/common/LazyLoadingList';

import ConnectProcessorModal from "./components/modal/processor/ConnectProcessorModal";
import PaymentMethodModal from "./components/modal/processor/PaymentMethodModal";

import UpdateConnectProcessorModal from "./components/modal/processor/UpdateConnectProcessorModal";

import General from '../utils/General'
import AuthManager from '../utils/AuthManager'
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";
import Processors from "../utils/Processors"

export default class CompanyProcessors extends React.Component {

  constructor(props) {
    super(props)

    let companyId = this.props.match.params.company_id

    this.state = {
    }

    this.list = React.createRef()
    this.inactiveProcessors = React.createRef()
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    let companyId = this.props.match.params.company_id
    Backend.getCompanyById(companyId)
    .then(company => {
      this.setState({ company })
    })
    .catch(error => {

    })
  }

  _renderMorePaymentMethodsTable(){
    let { company } = this.state
    return (
      <div className="">
        <table class="table">
          <thead>
            <tr>
              <th>METHOD</th>
              <th className="actions">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(company.payment_methods).map((paymentMethodType, index) => {
                if(company.payment_methods[paymentMethodType]){
                  return null
                }
                else if(["apple_pay", "google_pay"].indexOf(paymentMethodType) > -1){
                  return null
                }
                let logo = PAYMENT_METHOD_LOGOS[paymentMethodType]
                return (
                  <tr
                    className={`method`}
                    key={index}
                  >
                    <td>
                      <div className="name">
                        <span
                          className='img'
                          style={{backgroundImage: `url(${PAYMENT_METHOD_LOGOS[paymentMethodType]})`}}
                        />
                        {General.snakeCaseToTitleCase(paymentMethodType)}
                      </div>
                    </td>
                    <td>
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>

      </div>
    )
  }

  _renderPaymentMethods(){
    let { company } = this.state

    let isAssociationCompany = company.association != null

    let key = isAssociationCompany ? "association" : "platform"

    let currencySymbol = company.settings.payment_plan.currency.symbol
    return (
      <div className="">
        <table class="table">
          <thead>
            <tr>
              <th>METHOD</th>
              <th className="fees">Fees</th>
              <th className="processor">Processor</th>
              <th className="actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(company.payment_methods).map((paymentMethodType, index) => {
                let paymentMethod = company.payment_methods[paymentMethodType]
                if(!paymentMethod){
                  return null
                }
                let logo = PAYMENT_METHOD_LOGOS[paymentMethodType]

                let { rate } = paymentMethod

                let percentage = rate ? rate[`${key}_percentage`] : null
                let flatCharge = rate ? rate[`${key}_flat_charge`] : null
                let maxCharge = rate ? rate[`${key}_max_charge`] : null

                let feeExplainer = ""

                if(percentage){
                  feeExplainer = `${bigDecimal.multiply(percentage, 100)}%`
                }
                if(flatCharge){
                  if(percentage){
                    feeExplainer += " +"
                  }

                  feeExplainer += ` ${Currency.format(currencySymbol, flatCharge)}`
                }
                if(maxCharge){
                  feeExplainer += ` (Max ${Currency.format(currencySymbol, maxCharge)})`
                }

                if(!feeExplainer){
                  feeExplainer = "-"
                }

                let processorName = General.snakeCaseToTitleCase(paymentMethod.processor)
                if(processorName == "Card Connect"){
                  processorName = "Clover"
                }
                return (
                  <tr
                    key={index}
                  >
                    <td>
                      <div className="name t-method">
                        <span
                          className='img'
                          style={{backgroundImage: `url(${logo})`}}
                        />
                        {General.snakeCaseToTitleCase(paymentMethodType)}
                      </div>
                    </td>
                    <td>
                      { feeExplainer }
                    </td>
                    <td>
                      <div className="name t-processor">
                          <span
                              className='img'
                              style={{ backgroundImage: `url(${Processors.getImage({ slug: paymentMethod.processor })})` }}
                          />
                          {processorName}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn c-btn btn-light-primary btn-block c-btn-icon"
                        onClick={() => {
                          this.setState({
                            showPaymentMethodModal: true,
                            selectedPaymentMethod: paymentMethod,
                           })
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>

      </div>
    )
  }

  _renderProcessors(){
    let { company } = this.state
    return (
      <div className="processors">
        <LazyLoadingList
          ref={this.list}
          endpoint={`${window.Api.CompanyProcessors}?company_id=${company.id}&live=${company.settings.live}`}
          renderItem={(companyProcessor, index) => {
            let status = companyProcessor.default ? "default" : "active"
            if(companyProcessor.processor.live != company.settings.live){
              return null
            }
            return (
              <div
                className={`processor ${status}`}
                key={index}
              >
                <span
                  className='img'
                  style={{backgroundImage: `url(${Processors.getImage(companyProcessor.processor)})`}}
                />
                {companyProcessor.processor.name}
                {status === "default" &&
                  <button className="btn">DEFAULT</button>
                }
                {this._renderConnectedProcessorDropDownMenu(companyProcessor)}
              </div>
            )
          }}
        />
        <LazyLoadingList
          ref={this.inactiveProcessors}
          endpoint={`${window.Api.Processors}?company_id=${company.id}&connected=false&live=${company.settings.live}`}
          renderItem={(processor, index) => {
            let status = "inactive"
            return (
              <div
                className={`processor ${status}`}
                key={index}
              >
                <span
                  className='img'
                  style={{backgroundImage: `url(${Processors.getImage(processor)})`}}
                />
                {processor.name}
              </div>
            )
          }}
        />
      </div>
    )
  }

  _renderConnectedProcessorDropDownMenu(companyProcessor){
    return (
      <div className="dropdown dropdown-inline">
        <a href="javascript:;" className="btn btn-clean btn-hover-light-primary btn-icon"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="ki ki-bold-more-hor"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <ul className="navi navi-hover">
            <li className="">
              <a className="btn btn-clean btn-sm"
                onClick={() => {
                 this.setState({
                  selectedCompanyProcessor: companyProcessor,
                  showUpdateConnectProcessorModal: true
                 })
                }}
              >
                Edit
              </a>
            </li>
            { !companyProcessor.default &&
              <li className="">
                <a className="btn btn-clean btn-sm"
                  onClick={() => {

                   Backend.updateDefaultProcessor(companyProcessor)
                   .then(() => {
                     this.list.current.refresh()
                     this._load()
                   })
                   .catch(error => {
                     Notify.error(error.message)
                   })
                  }}
                >
                  Make Default
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    )
  }

  render() {
    let {
      company,
      selectedPaymentMethod,
      selectedCompanyProcessor,
      showConnectProcessorModal,
      showPaymentMethodModal,
      showUpdateConnectProcessorModal
    } = this.state

    if(!company){
      return null
    }

    return (
      <div className="page company-processors">
        <div className="row">

          <div
            className={"col-xl-12"}
          >
            <div className="mb-7">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-8">
                  <div className="row align-items-center">
                    <div className="col-md-12 my-2 my-md-0">
                      <h2>{company.name} | <span className="h5">Processors & Payment Methods</span></h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 mt-5 mt-lg-0">
                  <a href="javascript:;" className="btn btn-primary font-weight-bolder float-right"
                     onClick={() => this.setState({showConnectProcessorModal: true})}
                  >
                    <i className="flaticon2-add-1 icon-sm"></i>
                    Connect Processor
                  </a>
                </div>
              </div>

              {/* Starts : Connected Processors */}
              <div className="p-section c-processors">
                <div className="headings">
                  <h5>Connected Processors</h5>
                  <p>All of the processors you are currently connected to</p>
                  <div className="list">
                    {this._renderProcessors()}
                  </div>
                </div>
              </div>
              {/* End : Connected Processors */}

              {/* Starts : Payment Methods */}
              <div className="p-section c-processors">
                <div className="headings">
                  <h5>Payment Methods</h5>
                  <p>Manage your payment methods and default processors</p>
                  <div className="list c-p-table">
                    {this._renderPaymentMethods()}
                  </div>
                </div>
              </div>
              {/* End : Payment Methods */}

              {/* Starts : More Payment Methods */}
              <div className="p-section c-processors">
                <div className="headings">
                  <h5>More Payment Methods</h5>
                  <p>Talk to us to access more payment methods</p>
                  <div className="list c-p-table">
                    {this._renderMorePaymentMethodsTable()}
                  </div>
                </div>
              </div>
              {/* End : More Payment Methods */}

            </div>
          </div>

          <ConnectProcessorModal
            show={showConnectProcessorModal}
            company={company}
            onUpdated={() => {
              this.setState({
                showConnectProcessorModal: false
              })
              this.list.current.refresh()
              this.inactiveProcessors.current.refresh()
            }}
            onHide={() => {
              this.setState({
                showConnectProcessorModal: false
              })
            }}
          />

          <PaymentMethodModal
            show={showPaymentMethodModal}
            company={company}
            paymentMethod={selectedPaymentMethod}
            onUpdated={() => {
              this.setState({
                selectedPaymentMethod: null,
                showPaymentMethodModal: false
              })
              this._load()
            }}
            onHide={() => {
              this.setState({
                showPaymentMethodModal: false
              })
            }}
          />

          <UpdateConnectProcessorModal
            show={showUpdateConnectProcessorModal}
            company={company}
            companyProcessor={selectedCompanyProcessor}
            onUpdated={() => {
              this.setState({
                showUpdateConnectProcessorModal: false
              })
              this.list.current.refresh()
              this.inactiveProcessors.current.refresh()
            }}
            onHide={() => {
              this.setState({
                showUpdateConnectProcessorModal: false
              })
            }}
          />

        </div>
      </div>
    )
  }

}

export const PAYMENT_METHOD_LOGOS = {
  card: require("../assets/media/logos/payment-methods/cards.png"),
  sepa: require("../assets/media/logos/payment-methods/sepa.png"),
  sepa_instant: require("../assets/media/logos/payment-methods/sepa.png"),
  ach: require("../assets/media/logos/payment-methods/ach.png"),
  eft: require("../assets/media/logos/payment-methods/eft.png"),
  interac: require("../assets/media/logos/payment-methods/interac.png"),
  digital_wallet: require("../assets/media/logos/payment-methods/digital-wallet.png"),
  klarna:  require("../assets/media/logos/payment-methods/klarna.png"),
  faster_payments: require("../assets/media/logos/payment-methods/faster-payments.png"),
  bacs: require("../assets/media/logos/payment-methods/bacs.png"),
}
