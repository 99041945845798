import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/refresh-token"

window.Api.Companies                  = window.Api.Base + "/companies"
window.Api.Payments                   = window.Api.Base + "/payments"
window.Api.PaymentRequests            = window.Api.Base + "/payment-requests"
window.Api.PaymentMethods             = window.Api.Base + "/payment-methods"
window.Api.PaymentAttempts            = window.Api.Base + "/payment-attempts"
window.Api.CompanyMembers             = window.Api.Base + "/company-members"
window.Api.Admins                     = window.Api.Base + "/admins"
window.Api.Associates                 = window.Api.Base + "/associates"
window.Api.Associations               = window.Api.Base + "/associations"
window.Api.Currencies                 = window.Api.Base + "/currencies"
window.Api.Products                   = window.Api.Base + "/products"
window.Api.PaymentLinks               = window.Api.Base + "/payment-links"
window.Api.Images                     = window.Api.Base + "/images"
window.Api.Subscriptions              = window.Api.Base + "/subscriptions"
window.Api.Stats                      = window.Api.Base + "/stats"
window.Api.Admins                     = window.Api.Base + "/admins"
window.Api.Splinks                    = window.Api.Base + "/splinks"
window.Api.Invoices                   = window.Api.Base + "/invoices"
window.Api.BulkUploads                = window.Api.Base + "/bulk-uploads"
window.Api.StripePubishableKey        = window.Api.Base + "/bulk-uploads"
window.Api.BankAccounts               = window.Api.Base + "/bank-accounts"
window.Api.Questions                  = window.Api.Base + "/questions"
window.Api.ImagesSearch               = window.Api.Base + "/images-search"
window.Api.Reminders                  = window.Api.Base + "/reminders"
window.Api.Connect                    = window.Api.Base + "/connect"
window.Api.Receipts                   = window.Api.Base + "/receipts"
window.Api.Packages                   = window.Api.Base + "/packages"
window.Api.CompanySubscription        = window.Api.Base + "/company-subscriptions"
window.Api.Branding                   = window.Api.Base + "/branding"
window.Api.Domains                    = window.Api.Base + "/domains"
window.Api.CompanyProcessors          = window.Api.Base + "/company-processors"
window.Api.Processors                 = window.Api.Base + "/processors"
window.Api.AsyncTasks                 = window.Api.Base + "/async-tasks"
window.Api.Roles                      = window.Api.Base + "/roles"
window.Api.SecretKey                  = window.Api.Base + '/secret-key'
window.Api.Webhooks                   = window.Api.Base + '/webhooks'
window.Api.MFA                        = window.Api.Base + '/mfa'
