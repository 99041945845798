import React from 'react'

import Select from "react-select";


export default class CustomSelect extends React.Component  {
  constructor(props){
    super(props)

  }


  render(){
    return (
      <Select
        { ...this.props }
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#F5F5F5",
            primary: "#F5F5F5",
            neutral0: "white",
          }
        })}
        styles={{
          option: (provided, state) => ({
            ...provided,
            color: null,
          }),
        }}
      />
    )
  }
}