import React from "react"
import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import moment from "moment"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AddCompanyModal from "../modal/AddCompanyModal";
import ViewCell from "./cells/ViewCell";
import EditCell from "./cells/EditCell";
import DeleteCell from "./cells/DeleteCell";
import AssociationModal from "../modal/AssociationModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import Notify from "../../../utils/Notify";

class AssociationsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Name',
        id: 'name',
        accessor:  company => company.name
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let association = rowInfo.original
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              { !association.deleted_at &&
                <>
                  <EditCell
                    onEditClicked={() => {
                      this.props.history.push("partners/"+association.id)
                    }}
                  />
                  <DeleteCell
                    onDeleteClicked={() => {
                      this.setState({
                        showConfirmationModal: true,
                        association: association
                      })
                    }}
                  />
                </>
              }
            </span>
          )
        }
      }
    ]
  }

  _archiveAssociation(){
    let {
      association,
    } = this.state

    Backend.deleteAssociation(association)
    .then(e => {
      Notify.success("Partner has been archived successfully")
      this.setState({
        association: null,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        association: null
      })
    })
  }

  _renderToolBar(){
    return (
      <>
        <a className="btn btn-primary font-weight-bolder ml-4"
           onClick={() => {
             // this.setState({
             //   showAssociationModal: true
             // })
             this.props.history.push('/partners/add')
           }}
        >
          <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                 height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                      x="4" y="11" width="16" height="2" rx="1"></rect>
              </g>
            </svg>
          </span>
          Create Partner
        </a>
      </>
    )
  }

  _renderAssociationModal(){
    let {
      association,
      showAssociationModal
    } = this.state

    if(!showAssociationModal) return null

    return (
      <AssociationModal
        show={showAssociationModal}
        association={association}
        onAdded={() => {
          this.setState({
            association: null,
            showAssociationModal: false
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            association: null,
            showAssociationModal: false
          })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure you want to archive this partner?"}
        onConfirm={() => {
          this._archiveAssociation()
        }}
        onHide={() => {
          this.setState({
            company: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Associations}`}
          noDataMessage={"No partner accounts found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderAssociationModal() }
        { this._renderConfirmationModal() }
      </div>
    )
  }
}

export default withRouter(AssociationsTable);
