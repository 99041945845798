import React from "react";

import { Route, Switch } from "react-router-dom";

import AuthManager from "../utils/AuthManager";

import Aside from "./components/Aside";

import GeneralSettings from "./components/settings/GeneralSettings";
import PasswordSettings from "./components/settings/PasswordSettings";
import ApiSettings from "./components/settings/ApiSettings";

import PaymentAttempts from "./PaymentAttempts";

import MultiFactorAuthentication from "./components/multi-factor-authentication/MultiFactorAuthentication";


const TAB_GENERAL = {
  title: "General",
  subtitle: "Control your basic default settings",
  icon: "person",
  component: GeneralSettings,
  url: "/settings"
};

const TAB_PASSWORD = {
  title: "Change Password",
  subtitle: "Keep your password safe",
  icon: "shield",
  component: PasswordSettings,
  url: "/settings/password"
};

const TAB_API = {
  title: "Api",
  subtitle: "Automate your operations",
  icon: "cpu",
  component: ApiSettings,
  url: "/settings/api",
};

const TAB_PAYMENT_ATTEMPTS = {
  title: "Payment Attempts",
  subtitle: "Manage all your payments",
  icon: "paymentAttempts",
  component: PaymentAttempts,
  url: "/settings/payment-attempts",
};

const TAB_MULTI_FACTOR_AUTHENTICATION = {
  title: "Multi Factor Authentication",
  subtitle: "Manage Multi Factor Authentication",
  icon: "multiFactorAuthentication",
  component: MultiFactorAuthentication,
  url: "/settings/multi-factor-authentication",
};

const TABS = [TAB_GENERAL, TAB_PASSWORD, TAB_MULTI_FACTOR_AUTHENTICATION, TAB_PAYMENT_ATTEMPTS];

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    let tabs = [...TABS]

      if (
        tabs.find((tab) => tab.title === TAB_API.title) == null &&
        AuthManager.isAssociate()
      ) {
        tabs = [...tabs, TAB_API];
      }

    this.state = {
      tabs,
      activeTabIndex: tabs.findIndex(tab => tab.url === window.location.pathname),
    };
  }

  _renderContent() {
    let routes = this.state.tabs.flatMap(route => {
      if(route.title === TAB_PAYMENT_ATTEMPTS.title){
        return []
      }
      return [
        <Route
          exact
          path={route.url}
          component={route.component}
        />
      ]
    })

    return (
      <Switch>
        {routes}
      </Switch>
    )
  }

  render() {
    let { tabs, activeTabIndex } = this.state;

    return (
      <div className={"card card-custom"}>
        <div className={"card-body p-0"}>
          <div
            className={"wizard wizard-2"}
            id={"kt_wizard_v2"}
            data-wizard-state={"first"}
            data-wizard-clickable={"false"}
          >
            <div
              className={"wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"}
            >
              <Aside
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onClick={(tab, index) => {
                  this.props.history.push(tab.url)
                  this.setState({ activeTabIndex: index });
                }}
              />
            </div>
            <div className={"wizard-body py-8 px-8 py-lg-20 px-lg-10"}>
              <div className={"row"}>
                {this._renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
