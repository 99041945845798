import React, { Component } from "react";

import AuthManager from "../../../utils/AuthManager";

export default class VerificationMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationMethod: props.verificationMethod,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { verificationMethod } = this.state;

    let method = verificationMethod.method;
    let isActiveMethod = verificationMethod.active;

    let phoneNumber = `${AuthManager.currentUser.user.phone_country_code} ${AuthManager.currentUser.user.phone_number}`;

    let primaryText = "(Primary)";

    return (
      <div>
        <div className="row" key={verificationMethod.title}>
          <div className="col my-2 text-grey">
            <div
              className={`mt-4 font-size-h6 ${
                isActiveMethod && "text-dark font-weight-bold"
              }`}
            >
              {verificationMethod.title}
              {verificationMethod.primary && (
                <span className="ml-1">{primaryText}</span>
              )}
            </div>
            <div>{verificationMethod.subtitle}</div>
          </div>
          <div className="w-80 mr-5 my-6">
            {isActiveMethod && method === "sms_twilio" && (
              <span
                className="text-grey"
                style={{
                  fontSize: "1rem",
                  marginRight: !verificationMethod.primary ? 55 : 150,
                }}
              >
                {phoneNumber}
              </span>
            )}
            {isActiveMethod ? (
              <>
                {verificationMethod.canDeactivate && (
                  <button
                    className="btn btn-outline-danger font-weight-bold px-9 py-3"
                    onClick={() => this.props.onDeactivateClicked()}
                  >
                    Deactivate
                  </button>
                )}
                {!verificationMethod.primary && (
                  <button
                    className="btn btn-outline-primary font-weight-bold px-9 py-3 ml-3"
                    onClick={() => this.props.onMakeDefaultClicked()}
                  >
                    Set Default
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn btn-outline-primary font-weight-bold px-9 py-3"
                onClick={() => this.props.onActivateClicked()}
                style={{ width: 132.55 }}
              >
                Activate
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
