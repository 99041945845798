import Currency from "./Currency";
import General from "./General";

export default class Calculations {

  static totalIncludingVat(priceExcludingVat, vat, formatted = true){
    let value = Calculations.parseNumber(priceExcludingVat) + (Calculations.parseNumber(priceExcludingVat) * Calculations.parseNumber(vat))
    return !value ? '0.00' : formatted ? Currency.format('', value) : value
  }

  static totalVat(priceExcludingVat, vat, formatted = true){
    let value = (Calculations.parseNumber(priceExcludingVat) * Calculations.parseNumber(vat))
    return !value ? '0.00' : formatted ? Currency.format('', value) : value
  }

  static getPercentage(total, subtotal){
    let value = (total - subtotal)/subtotal * 100
    return value
  }

  static parseNumber(value, locale = navigator.language) {
    if(!value){
      return 0
    }

    const example = Intl.NumberFormat(locale).format('1.1');
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
    const cleaned = value.toString().replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }

}