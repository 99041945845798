import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import ImageEdit from "../../common/ImageEdit";

import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

export default class AibConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props)
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      data: props.data || {

      },
    }
  }

  _connect(){
    let {
      company,
      data,
    } = this.state

    this.setState({ loading: true })
    Backend.connectToAib(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-3 col-form-label">Store/Merchant iD</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.store_id}
                  onChange={e => {
                    data.store_id = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Api Key</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.api_key}
                  onChange={e => {
                    data.api_key = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Api Secret</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.api_secret}
                  onChange={e => {
                    data.api_secret = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }

}
