import slugify from 'slugify'
import moment from 'moment'

import Currency from "../utils/Currency"

export default class Partner {
  static createCompany(currency){
    currency = currency || DEFAULT_CURRENCY

    const firstName = Partner._getRandom(FIRST_NAMES)
    const lastName = Partner._getRandom(LAST_NAMES)

    const name = `${firstName}'s ${Partner._getRandom(INDUSTRIES)}`

    let data = {
      name,
      slug: slugify(name).toLowerCase().replace("'", ""),
      members: [
        {
          user: {
            first_name: firstName,
            last_name: lastName,
            email: `${firstName}.${lastName}@partner.dev`.toLowerCase(),
            password: "P@ssword123",
            phone_country_code: "+353",
            phone_number: "12345678"
          }
        }
      ],
      settings: {
        live: false,
        vat_rate: 0,
        currency,
      },
      demo_data: {
        payments: [
          Partner.generateDemoPayment(currency, false, false),
          Partner.generateDemoPayment(currency, false, true),
          Partner.generateDemoPayment(currency, true, true),
        ],
        payment_requests: [
          Partner.generateDemoPaymentRequest(currency, false, false),
          Partner.generateDemoPaymentRequest(currency, false, true),
          Partner.generateDemoPaymentRequest(currency, true, true),
        ],
        products: [
          Partner.generateDemoProduct(currency, null, null, false, false, false),
          Partner.generateDemoProduct(currency, null, null, false, true, true),
          Partner.generateDemoProduct(currency, null, null, true, true, true),
        ],
        payment_links: [
          Partner.generateDemoPaymentLink(currency, null, null, false, false, false),
          Partner.generateDemoPaymentLink(currency, null, null, false, true, true),
          Partner.generateDemoPaymentLink(currency, null, null, true, true, true),
        ]
      },
      add_ons: {}
    }

    data.stripe_test_connect_data = {
      country_short: "ie",
      persons: [],
      representative: {
        first_name: data.members[0].user.first_name,
        last_name: data.members[0].user.last_name,
        email: data.members[0].user.email,
        phone: '+35312345678',
        relationship: {
          owner: true,
          director: true,
          executive: true
        },
        dob: {
          year: 2000,
          month: 1,
          day: 1
        },
        address: {
          line1: "Line 1",
          line2: "Line 2",
          city: "City",
          state: "State",
          country: "ie",
          postal_code: "Postal Code"
        },
        verification: {
          document: {
            front: "file_identity_document_success"
          },
          additional_document: {
            front: "file_identity_document_success"
          }
        }
      },
      currency: "eur"
    }

    return data
  }

  static generateRandomDemoPayment(currency){
    return Partner.generateDemoPayment(currency, Partner._getRandomBoolean(), Partner._getRandomBoolean())
  }

  static generateDemoPayment(currency, recurring=false, customData=false){

    currency = currency || DEFAULT_CURRENCY
    return {
      customer: Partner.generateCustomer(),
      payment_plan: Partner._generateDemoPaymentPlan(Partner._getRandom(AMOUNTS), currency, recurring),
      answers: customData ? [Partner._getRandom(ANSWERS)] : [],
      paid_at: moment().toDate()
    }
  }

  static generateRandomDemoPaymentRequest(currency){
    return Partner.generateDemoPaymentRequest(currency, Partner._getRandomBoolean(), Partner._getRandomBoolean())
  }

  static generateDemoPaymentRequest(currency, recurring=false, paid=false){

    currency = currency || DEFAULT_CURRENCY
    return {
      paid,
      customer: Partner.generateCustomer(),
      payment_plans: [Partner._generateDemoPaymentPlan(Partner._getRandom(AMOUNTS), currency, recurring)],
      due_at: Partner._generateRandomFutureDate(),
      title: "Payment",
      description: "Please pay the amount due for the goods/services rendered"
    }
  }

  static generateRandomDemoProduct(currency){
    return Partner.generateDemoProduct(currency, null, null, Partner._getRandomBoolean(), Partner._getRandomBoolean(), Partner._getRandomBoolean())
  }

  static generateDemoProduct(currency, name=null, amount=null, recurring=false, payments=false, questions=false){

    currency = currency || DEFAULT_CURRENCY
    name = name || Partner._getRandom(PRODUCT_NAMES)

    let paymentPlan = Partner._generateDemoPaymentPlan(amount || Partner._getRandom(AMOUNTS), currency, recurring)
    return {
      name,
      slug: slugify(name).toLowerCase(),

      description: null,
      featured_image: null,
      images: [],

      payment_plans: [
        paymentPlan
      ],

      payments: payments ? [
        Partner.generateRandomDemoPayment(currency, recurring)
      ] : [],

      questions: questions ? [
        this._getRandom(QUESTIONS)
      ] : []
    }
  }

  static generateRandomDemoPaymentLink(currency){
    return Partner.generateDemoPaymentLink(currency, null, null, Partner._getRandomBoolean(), Partner._getRandomBoolean(), Partner._getRandomBoolean())
  }

  static generateDemoPaymentLink(currency, title=null, amount=null, recurring=false, payments=false, questions=false){

    currency = currency || DEFAULT_CURRENCY

    let paymentPlan = Partner._generateDemoPaymentPlan(amount, currency, recurring)
    title = title || Partner._getRandom(PAYMENT_LINK_NAMES)
    return {
      title,
      slug: slugify(title).toLowerCase(),

      description: null,
      background_image: null,

      payment_plans: [
        paymentPlan
      ],

      payments: payments ? [
        Partner.generateRandomDemoPayment(currency, recurring)
      ] : [],

      questions: questions ? [
        this._getRandom(QUESTIONS)
      ] : [],

      background_image: null
    }
  }


  static generateCustomer(){
    let firstName = Partner._getRandom(FIRST_NAMES)
    let lastName = Partner._getRandom(LAST_NAMES)
    return {
        first_name: firstName,
        last_name: lastName,
        phone_country_code: "+353",
        phone_number: "12345678",
        email: `${firstName}.${lastName}@partner.dev`.toLowerCase(),
    }
  }

  static getRandonAnswer(){
    return this._getRandom(ANSWERS)
  }

  static getRandonQuestion(){
    return this._getRandom(QUESTIONS)
  }

  static _generateDemoPaymentPlan(amount, currency, recurring=false){

    let data = {
        currency,
        type: recurring ? "recurring" : "one_time",
        subtotal: amount,
        vat: amount ? 0 : null,
        total: amount,
        open: amount ? false : true,
        installments: null,
        frequency: null
    }

    if(recurring){
        data = {
          ...data,
          installments: 12,
          type: "recurring",
          frequency: "monthly",
        }
    }

    return data
  }

  static _getRandomFirstName(){
    return
  }

  static _getRandom(array){
    return array[Math.floor(Math.random()*array.length)];
  }

  static _getRandomBoolean(){
    return Math.random() < 0.5
  }

  static _generateRandomFutureDate(){
    const fromTime = new Date().getTime()
    const toTime = moment().add('days', 30).toDate().getTime()
    let d = new Date(fromTime + Math.random() * (toTime - fromTime));
    return d
  }
}


const INDUSTRIES = [
  "Accountancy",
  "Butchers",
  "Bakery",
  "Fish Mongers",
  "Jewellers",
  "Hair & Beauty",
  "Computer Repair",
  "Dog Grooming",
  "Security Systems",
  "Barbers",
  "Bookkeeping",
  "Movers",
  "Plumbing Service",
  "Fundraiser",
  "Office Supplies",
  "Cabs",
  "Printing Services",
  "Carpet Cleaners",
  "Locksmith Service",
  "Landscaping",
  "Cafe & Diner",
  "Coffee Shop",
  "Electrical Services",
  "Delivery Services"
]

const FIRST_NAMES = [
  "Aaron",
  "Amy",
  "Mark",
  "Jenna",
  "Frank",
  "Rob",
  "Darren",
  "John",
  "Stephen",
  "Bob",
  "Fintan",
  "Luke",
  "Liam",
  "Brenda",
  "Sean",
  "Henry",
  "Phil",
  "Thomas",
  "Brenda",
  "Lee",
  "Andrew",
  "Adam",
  "Conor",
  "Senan",
]

const LAST_NAMES = [
  "Andrews",
  "Walters",
  "Greene",
  "Smith",
  "Staunton",
  "Fields",
  "Brown",
  "Miller",
  "Davis",
  "Redmond",
  "Gray",
  "Evans",
  "Robertson",
  "Wilson",
  "Donovan",
  "Johnson",
  "Fitzgerald",
  "Sweeney",
]

const ANSWERS = [
  {
    "question": "Would you like to recieve promotional offers from us?",
    "answer": "Yes"
  },
  {
    "question": "Are you a previous customer?",
    "answer": "No"
  },
  {
    "question": "How did you hear about us?",
    "answer": "From a friend"
  },
  {
    "question": "Appointment Time",
    "answer": "Next Wednesday @ 2pm"
  },
  {
    "question": "Delivery Address",
    "answer": "123 Malbborough Road, Grand Marsh"
  },
  {
    "question": "Branch",
    "answer": "30 Fensworth Rd, Langley Falls"
  }
]

const QUESTIONS = [
  {
    "text": "Would you like to recieve promotional offers from us?",
    "type": "checkbox"
  },
  {
    "text": "Are you a previous customer?",
    "type": "checkbox"
  },
  {
    "text": "How did you hear about us?",
    "type": "text"
  },
  {
    "text": "Appointment Time",
    "type": "text"
  },
  {
    "text": "Delivery Address",
    "type": "text"
  },
  {
    "text": "Branch",
    "type": "text"
  }
]

const PRODUCT_NAMES = [
  "Voucher",
  "Month Membership",
  "Year Membership",
  "2 Day Guarantee",
  "Gold Package",
  "Monthly Special",
  "Full Service",
  "Consultation",
]

const PAYMENT_LINK_NAMES = [
  "Sales",
  "eMailCampaign",
  "Franchise",
  "Sales Rep",
  "Facebook",
]

const AMOUNTS = [
  1000,
  2000,
  2700,
  3500,
  4000,
  4550,
  6100,
  5000,
  5800,
  7200,
  7500,
  8000,
  10000,
  12000
]

const DEFAULT_CURRENCY = { id: 1, code: "eur", symbol: "€", name: "Euro" }
