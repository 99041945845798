import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import ImageEdit from '../common/ImageEdit';
import ColorPicker from "../common/ColorPicker";
import AsyncSelect from "../common/AsyncSelect";

import DemoCustomer from '../splink/DemoCustomer'
import DemoPaymentPlans from '../splink/DemoPaymentPlans'
import DemoCustomData from '../splink/DemoCustomData'
import DemoQuestions from '../splink/DemoQuestions'
import DemoPayments from '../splink/DemoPayments'


import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Splink from "../../../utils/Splink"

export default class DemoPaymentLinkModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company,
      paymentLink: props.paymentLink,
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      paymentLink,
      company,
      error,
    } = this.state

    if(!paymentLink){
      return null
    }

    let url = Splink.getSplinkUrl(company)

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={"sub-modal"}
        dialogClassName={"modal-50"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment Link</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-3 col-form-label">Title*</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                placeholder="ABC Ltd"
                value={paymentLink.title}
                name={"title"}
                onChange={e => {
                  paymentLink.title = e.target.value
                  this.props.onUpdated(paymentLink)
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Channel*</label>
            <div className="col-9">
              <div className="input-group">
                <div className={`input-group-prepend`}>
                  <span className="input-group-text" style={{border:'none'}}>{url}/</span>
                </div>
                <input
                  className="form-control form-control-solid"
                  type="text"
                  placeholder="channel"
                  value={paymentLink.slug}
                  name={"slug"}
                  onChange={e => {
                    paymentLink.slug = e.target.value
                    this.props.onUpdated(paymentLink)
                  }}
                />
              </div>
            </div>
          </div>


          <div className="form-group row">
            <label className="col-3 col-form-label">Background Image</label>
            <div className="col-9">
              <ImageEdit
                image={paymentLink.background_image}
                imagePicker={true}
                hidePreview={false}
                onUpdated={image => {
                  paymentLink.background_image = image
                  this.props.onUpdated(paymentLink)
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Description</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                placeholder="Description"
                value={paymentLink.description}
                name={"description"}
                onChange={e => {
                  paymentLink.description = e.target.value
                  this.props.onUpdated(paymentLink)
                }}
              />
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Payment Details
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Open or Set*</label>
            <div className="col-9">
              <div className="radio-inline"
                   onChange={e => {
                     paymentLink.payment_plans[0].open = e.target.value === "on"
                     if(e.target.value === "on"){
                       paymentLink.payment_plans[0].subtotal = null
                       paymentLink.payment_plans[0].total = null
                       paymentLink.payment_plans[0].vat = null
                     }
                     this.props.onUpdated(paymentLink)
                   }}
              >
                <label className="radio">
                  <input type="radio" name="openSet" value={"on"} checked={paymentLink.payment_plans[0].open}/>
                  Open
                  <span></span>
                </label>
                <label className="radio">
                  <input type="radio" name="openSet" value={"off"} checked={!paymentLink.payment_plans[0].open}/>
                  Set
                  <span></span>
                </label>
              </div>
            </div>
          </div>

          <DemoPaymentPlans
            showOptional={false}
            showCurrency={true}
            priceTitle="Amount"
            company={company}
            paymentPlans={paymentLink.payment_plans}
            updatePaymentPlan={(paymentPlan, index) => {
              paymentLink.payment_plans[index] = paymentPlan
              this.props.onUpdated(paymentLink)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Questions
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoQuestions
            company={company}
            questions={paymentLink.questions}
            onUpdated={questions => {
              paymentLink.questions = questions
              this.props.onUpdated(paymentLink)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Payments
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoPayments
            company={company}
            payments={paymentLink.payments}
            onUpdated={payments => {
              paymentLink.payments = payments
              this.props.onUpdated(paymentLink)
            }}
          />

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this.props.onHide()}
          >
            Ok
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
