import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import moment from "moment"

import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'

import AsyncSelect from '../common/AsyncSelect'
import ColorPicker from '../common/ColorPicker'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Notify from '../../../utils/Notify'
import Currency from '../../../utils/Currency'
import Calculations from '../../../utils/Calculations'


const options = [
  {
    value: false,
    label: 'Force User to pay for this package',
  },
  {
    value: true,
    label: 'Upgrade user to this package for free',
  },
]

export default class UpdateCompanyPackageModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
      isEditMode: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company,
      updatedSubscription: null
    }
  }

  _setSubscriptionPriceForPackage(subscription, total, pkg){
    let vatPercentage = Math.round((Calculations.getPercentage(pkg.total, pkg.subtotal)) * 100) / 100

    let vat = 0
    if(vatPercentage > 0){
      vat = (total / (1 + vatPercentage)) * vatPercentage
      vat = Math.round(vat)
    }

    let subtotal = total - vat
    subscription.total = total
    subscription.subtotal = subtotal
    subscription.vat = vat
    return subscription
  }

  _updateCompanyPackage() {
    let { company, updatedSubscription } = this.state

    if(!updatedSubscription){
      Notify.error("Please select a package")
      return
    }
    let freeTialEndsAt = updatedSubscription.free_trial_ends_at
    let data = {
      package: updatedSubscription.package.id,
      active: updatedSubscription.active,
      vat: updatedSubscription.vat,
      total: updatedSubscription.total,
      subtotal: updatedSubscription.subtotal,
      free_trial_ends_at: freeTialEndsAt,
      min_payment: updatedSubscription.min_payment || null
    }
    if(data.total <= 0 || !data.total){
      data.active = true
    }

    if(data.total > 0 && freeTialEndsAt){
      if(!moment(freeTialEndsAt).isBefore(moment(), "day")){
        data.active = true
      }
    }

    this.setState({ loading: true })
    Backend.updateCompanyPackage(company, data)
      .then(() => {
        Notify.success('Company Package Updated Successfully')
        this.setState({ loading: false })
        this.props.onUpdated()
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _getSelectedOption(subscription){
    let {company} = this.state
    let selected = null
    let isSubscriptionFree = subscription.free_trial_ends_at == null && subscription.active
    options.map(option => {
      if(option.value == isSubscriptionFree){
        selected = option
      }
    })
    return selected
  }

  _renderPendingChanges(){
    let {
      company,
    } = this.state

    let { subscription } = company
    if((!subscription.active && subscription.total == subscription.package.total) ||
       (subscription.active && !subscription.free_trial_ends_at)){
      return null
    }

    return (
      <>
        <div className="c-separator form-group row mt-4 align-items-center text-center">
          <div className="col-3">
            <hr/>
          </div>
          <div className="col-6">
            <span>
              <strong>
                Pending Changes
              </strong>
            </span>
          </div>
          <div className="col-3">
            <hr/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Amount</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="number"
              name="price"
              defaultValue={ subscription.package.total / 100 }
              disabled
            />
            <br/>
          </div>
        </div>
        { (subscription.active && subscription.free_trial_ends_at) &&
          <div className="form-group row">
            <label className="col-3 col-form-label">Active</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                name="active"
                defaultValue={"No"}
                disabled
              />
              <br/>
            </div>
          </div>
        }
      </>
    )
  }

  _renderContent(){
    let { isEditMode } = this.state
    if(isEditMode){
      return this._renderEditPackageContent()
    }
    else{
      return this._renderCurrentPackageContent()
    }
  }

  _renderCurrentPackageContent(){
    let { show, company, loading } = this.state
    let { subscription } = company

    let amount = subscription.total / 100

    let minPayment = subscription.min_payment ? (subscription.min_payment / 100) : ''

    return (
      <>
      <Modal.Header closeButton>
        <Modal.Title>Company Package</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group row">
          <label className="col-3 col-form-label">Amount</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="text"
              value={ amount || "Free" }
              disabled
            />
            <br/>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Minimum Payment</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="text"
              value={ minPayment || "Free" }
              disabled
            />
            <br/>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Active</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="text"
              name="active"
              defaultValue={ company.subscription.active ? "Yes" : "No" }
              disabled
            />
            <br/>
          </div>
        </div>

        { company.subscription.free_trial_ends_at &&
          <div className="form-group row">
            <label className="col-3 col-form-label">Free Trial Ends At</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={ moment(company.subscription.free_trial_ends_at).format('DD-MM-YYYY') }
                disabled
              />
              <br/>
            </div>
          </div>
        }

        { this._renderPendingChanges() }

      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary font-weight-bold"
          onClick={() => this.props.onHide()}
        >
          Close
        </button>

        <button
          type="button"
          className="btn btn-primary font-weight-bold"
          id="add-success"
          disabled={loading}
          onClick={() => this.setState({ isEditMode: true })}
        >
          Edit Package
        </button>
      </Modal.Footer>
      </>
    )
  }

  _renderEditPackageContent(){
    let { show, company, loading, updatedSubscription } = this.state

    let filter = ""
    if(company.association){
      filter += "association_id=" + company.association
    }
    else{
      filter += "association=false"
    }

    let minPayment = updatedSubscription ? (updatedSubscription.min_payment / 100) : ''

    return (
      <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Company Package</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group row">
          <label className="col-3 col-form-label">*Package</label>
          <div className="col-9">
            <AsyncSelect
              endpoint={window.Api.Packages}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              placeholder="Select A Package"
              filter={filter}
              value={General.getSingleAsyncOption(
                updatedSubscription?.package
              )}
              onSelected={(selectedPackage) => {
                if(!updatedSubscription){
                  updatedSubscription = General.clone(company.subscription)
                }

                updatedSubscription.package = selectedPackage
                updatedSubscription.active = updatedSubscription.package.total == 0
                updatedSubscription.free_trial_ends_at = selectedPackage.free_trial_days ? moment().add("daya", selectedPackage.free_trial_days) : null
                updatedSubscription = this._setSubscriptionPriceForPackage(updatedSubscription, updatedSubscription.package.total, selectedPackage)

                this.setState({ updatedSubscription })
              }}
              getOptions={(packages) => General.getAsyncOptions(packages)}
            />
            <label className='font-weight-bold text-muted font-size-sm mt-3'>*Changing a package will change the add ons this company has.</label>
          </div>
        </div>
        { updatedSubscription &&
          <>
            {(updatedSubscription.package.total > 0 || !updatedSubscription.free_trial_ends_at) && (
              <div className="form-group row">
                <label className="col-3 col-form-label">*Payment</label>
                <div className="col-9">
                  <Select
                    className={'custom-async-select__container'}
                    classNamePrefix={'custom-async-select'}
                    value={this._getSelectedOption(updatedSubscription)}
                    onChange={(selectedOption) => {
                      updatedSubscription.active = selectedOption.value
                      if(updatedSubscription.active){
                        updatedSubscription.vat = 0
                        updatedSubscription.total = 0
                        updatedSubscription.subtotal = 0
                        updatedSubscription.free_trial_ends_at = null
                      }
                      else{
                        let pkg = updatedSubscription.package
                        updatedSubscription = this._setSubscriptionPriceForPackage(updatedSubscription, pkg.total, pkg)
                      }
                      this.setState({ updatedSubscription })
                    }}
                    options={options}
                  />
                  <label className='font-weight-bold text-muted font-size-sm mt-3'>*Any changes to price / frequency will be applied on the next billing cycle.</label>
                </div>
              </div>
            )}
            {(!updatedSubscription.active || updatedSubscription.free_trial_ends_at != null) && (
              <>
                <div className="form-group row">
                  <label className="col-3 col-form-label">Price</label>
                  <div className="col-9">
                    <input
                      className="form-control form-control-solid"
                      type="number"
                      name="price"
                      defaultValue={ updatedSubscription.total / 100 }
                      onChange={e => {
                        let pkg = updatedSubscription.package
                        let total = Math.round(Currency.parseNumber(e.target.value) * 100)
                        updatedSubscription = this._setSubscriptionPriceForPackage(updatedSubscription, total, pkg)
                        this.setState({ updatedSubscription })
                      }}
                    />
                  </div>
                </div>
                {/*
                <div className="form-group row">
                  <label className="col-3 col-form-label">Free Trial Ends At</label>
                  <div className="col-9">
                    <Flatpickr
                      value={updatedSubscription.free_trial_ends_at ? moment(updatedSubscription.free_trial_ends_at).format('DD-MM-YYYY') : null}
                      className="form-control"
                      placeholder="No Free Trial"
                      options={{
                        enableTime: false,
                        noCalendar: false,
                        dateFormat: "d-m-Y",
                      }}
                      onChange={dates => {
                        updatedSubscription.free_trial_ends_at = moment(dates[0])
                        this.setState({
                          updatedSubscription
                        })
                      }}
                    />
                    <br/>
                  </div>
                </div>
                */}
              </>
            )}
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Minimum Payment
              </label>
              <div className="col-9">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text border-0">{updatedSubscription.package.currency.symbol}</span>
                  </div>
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    placeholder="0.00"
                    step={"0.01"}
                    value={minPayment}
                    onChange={e => {
                      updatedSubscription.min_payment = parseFloat(e.target.value) * 100
                      this.setState({ updatedSubscription })
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        }
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary font-weight-bold"
          onClick={() => this.setState({ isEditMode: false })}
        >
          Cancel
        </button>

        <button
          type="button"
          className="btn btn-primary font-weight-bold"
          id="add-success"
          disabled={loading}
          onClick={() => this._updateCompanyPackage()}
        >
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                ></path>
              </g>
            </svg>
          </span>
          Edit Package
        </button>
      </Modal.Footer>
      </>
    )
  }

  render() {
    let { show, company } = this.state

    if (!company){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={''}
        dialogClassName={'modal-dialog modal-dialog-centered modal-lg'}
      >
        { this._renderContent() }
      </Modal>
    )
  }
}
