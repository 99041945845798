import React from 'react'
import General from "../../../../utils/General";

export default class PillCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      className: props.className,
      text: props.text
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      className,
      text
    } = this.state

    return (
      <span className={`label label-lg font-weight-bold ${className} label-inline`}>
        {text}
      </span>
    )
  }
}
