import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.PaymentPortalUrl     = env.REACT_APP_PAYMENT_PORTAL_URL
window.General.PaymentPortalDomain  = env.REACT_APP_PAYMENT_PORTAL_DOMAIN
window.General.StatStatus           = env.REACT_APP_STAT_STATUS
window.General.WebsiteUrl           = env.REACT_APP_WEBSITE_URL
window.General.WebsiteDomain        = env.REACT_APP_WEBSITE_DOMAIN

window.General.DefaultCurrency = {}

window.General.Branding = {}

window.General.Branding.Name      = "splink"
window.General.Branding.LinkName  = "splink"
window.General.Branding.Logo      = require('../assets/media/logos/logo-dark.png')
window.General.Branding.Banner    = require('../assets/media/logos/logo-light.png')
window.General.Branding.Favicon   = '/favicon.png'
window.General.Branding.Domain    = window.General.WebsiteDomain
window.General.Branding.Colors    = {
  "primary": "#007bff",
  "text_on_primary": "#fff",
  "secondary": "#007bff",
  "take": "#007bff ",
  "request": "#f64e60",
  "shop": "#1bc5bd",
  "recurring": "#007bff ",
}
