import React from "react"
import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import moment from "moment"

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";

import ViewCell from "./cells/ViewCell"
import EditCell from "./cells/EditCell";
import DeleteCell from "./cells/DeleteCell";
import PlusCell from "./cells/PlusCell";

import ConfirmationModal from "../modal/ConfirmationModal";
import AddOnModal from "../modal/AddOnModal";
import PackageModal from "../modal/PackageModal";

class PackagesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Package',
        id: 'name',
        accessor:  singlePackage => singlePackage.name
      },
      {
        Header: 'Price',
        id: 'total',
        accessor:  singlePackage => Currency.format(singlePackage.currency.symbol, singlePackage.total)
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        accessor:  singlePackage => General.toTitleCase(singlePackage.frequency)
      }
    ]

    if(AuthManager.isPlatformAdmin()){
      columns = [
        ...columns,
        {
          Header: 'Partner',
          id: 'association.name',
          accessor:  singlePackage => singlePackage.association?.name || "-"
        }
      ]
    }

    return [
      ...columns,
      {
        Header: AuthManager.isPlatformAdmin() ? 'Partner Rate' : window.General.Branding.Name + " Rate",
        id: 'association_rate',
        accessor:  singlePackage => Math.round((singlePackage.association_rate * 100) * 100) / 100
      },
      {
        Header: 'splink Rate',
        id: 'platform_rate',
        accessor:  singlePackage => Math.round((singlePackage.platform_rate * 100) * 100) / 100
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        width: 180,
        Cell: rowInfo => {
          let packageObject = rowInfo.original

          let url = `https://dashboard.${window.General.WebsiteDomain}/account?u=c&p=${packageObject.slug}`
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              <ViewCell
                tooltip={"View"}
                onViewClicked={() => {
                  window.open(url, '_blank')
                }}
              />
              <EditCell
                onEditClicked={() => {
                  this.props.history.push(`/packages/${packageObject.id}`)
                }}
              />
              <PlusCell
                onPlusClicked={() => {
                  this.setState({
                    showPackageAddonModal: true,
                    packageObject
                  })
                }}
              />
              { (!packageObject.default && !packageObject.deleted_at) &&
                <DeleteCell
                  onDeleteClicked={() => {
                    this.setState({
                      showConfirmationModal: true,
                      packageObject
                    })
                  }}
                />
              }
            </span>
          )
        }
      }
    ]
  }

  _deletePackage(){
    let {
      packageObject
    } = this.state

    Backend.deletePackage(packageObject)
    .then(company => {
      Notify.success("Package Has Been Deleted")
      this.setState({ loading: false, showConfirmationModal: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderToolBar(){
    return (
      <>
        <a className="btn btn-primary font-weight-bolder"
           onClick={() => {
            //  this.setState({
            //    showPackageModal: true
            //  })
            this.props.history.push('/packages/add')
           }}
        >
          <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                 height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                      x="4" y="11" width="16" height="2" rx="1"></rect>
              </g>
            </svg>
          </span>
          Add Package
        </a>
      </>
    )
  }

  _renderPackageModal(){
    let {
      packageObject,
      showPackageModal,
    } = this.state

    return (
      <PackageModal
        show={showPackageModal}
        packageObject={packageObject}
        onHide={() => this.setState({ showPackageModal: false, packageObject: null })}
        onAdded={() => {
          this.table.current.refresh()
          this.setState({showPackageModal: false, packageObject: null})
        }}
      />
    )
  }

  _renderPackageAddonModal(){
    let {
      packageObject,
      showPackageAddonModal,
    } = this.state

    if(!showPackageAddonModal) return null

    return (
      <AddOnModal
        show={showPackageAddonModal}
        packageObject={packageObject}
        onAdded={() => {
          this.setState({
            showPackageAddonModal: false,
            packageObject: null
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            showPackageAddonModal: false,
            packageObject: null
          })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure? This action can't be redone."}
        onConfirm={() => {
          this._deletePackage()
        }}
        onHide={() => {
          this.setState({
            company: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  render() {
    let {
      live,
    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Packages}`}
          noDataMessage={"No packages found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderPackageModal() }
        { this._renderPackageAddonModal() }
        { this._renderConfirmationModal() }
      </div>
    )
  }
}

export default withRouter(PackagesTable);

PackagesTable.defaultProps = {
  latestResults: false
}
