import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import RecurringCell from '../tables/cells/RecurringCell'
import EditCell from '../tables/cells/EditCell'
import DeleteCell from '../tables/cells/DeleteCell'
import RandomiseCell from '../tables/cells/RandomiseCell'

import DemoPaymentModal from "../modal/DemoPaymentModal";

import Currency from "../../../utils/Currency";
import Partner from "../../../utils/Partner";


export default class DemoPayments extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company,
      payments: props.payments
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      company,
      payments,
      showPaymentModal,
      selectedPayment,
      selectedPaymentIndex
    } = this.state

    return (
      <>
        {/* starts : Table */}
        <table className="table c-table">
            <thead>
              <tr>
                <td width="30%">Name</td>
                <td>Amount</td>
                <td>Recurring</td>
                <td>Date Paid</td>
                <td>C.D.</td>
                <td width="150">Actions</td>
              </tr>
            </thead>
            <tbody>

                { payments.map((payment, index) => {
                    let { customer, payment_plan } = payment
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ml-2">
                              <div className="text-dark-75 font-weight-bold line-height-sm">{ customer.first_name + " " + customer.last_name }</div>
                              <a href="javascript:;" className="font-size-sm text-dark-50 text-hover-primary">{ customer.email}</a>
                            </div>
                          </div>
                        </td>
                        <td>{ Currency.format(payment_plan.currency.symbol, payment_plan.total) }</td>
                        <td>{ payment_plan.type === "one_time" ? null : <RecurringCell/> }</td>
                        <td>{ moment(payment.paid_at).format("DD MMM YYYY") }</td>
                        <td>{ payment.answers.length === 0 ? "No" : "Yes" }</td>
                        <td>
                          <EditCell
                            onEditClicked={() => {
                              this.setState({
                                showPaymentModal: true,
                                selectedPayment: payment,
                                selectedPaymentIndex: index,
                              })
                            }}
                          />
                          <RandomiseCell
                            onRandomiseClicked={() => {
                              payments[index] = Partner.generateRandomDemoPayment(company.settings.currency)
                              this.props.onUpdated(payments)
                            }}
                          />
                          <DeleteCell
                            tooltip="Delete"
                            onDeleteClicked={() => {
                              payments.splice(index, 1)
                              this.props.onUpdated(payments)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })
                }
            </tbody>
        </table>
        {/* end : Table */}

        {/* starts : Add Payment */}
        <div className="row mt-6 mb-0 pt-2">
          <div className="col text-right">
            <a
              className="btn btn-light-primary font-weight-bolder"
              onClick={() => {
                payments.push(Partner.generateRandomDemoPayment(company.settings.currency))
                this.props.onUpdated(payments)
              }}
            >
              <i className="la la-plus"></i>Add Payment
            </a>
          </div>
        </div>
        {/* end : Add Payment */}

        <DemoPaymentModal
          show={showPaymentModal}
          company={company}
          payment={selectedPayment}
          onHide={() => {
            this.setState({
              showPaymentModal: false,
              selectedPayment: null,
              selectedPaymentIndex: null
            })
          }}
          onUpdated={payment => {
            payments[selectedPaymentIndex] = payment
            this.props.onUpdated(payments)
          }}
        />
      </>
    )
  }
}

DemoPayments.defaultProps = {

}
