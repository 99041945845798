import React from "react"
import StatCard from "./components/common/StatCard";
import SubscriptionsTable from "./components/tables/SubscriptionsTable";
import Backend from "../utils/Backend";
import Stats from "../utils/Stats";
import Event from "../utils/Event";

export default class Recurring extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    Backend.getRecurringStats()
      .then(stats => {
        this.setState({
          stats: stats.admin || stats.admin
        })
      })
  }

  _renderStatCards(){
    let{
      stats
    } = this.state

    let noCustomers = stats?.recurring_payments.companies.count;
    let noRecurring = Stats.getAllTimeRecurringCount(stats?.recurring_payments)
    let total = Stats.getAllTimeRecurringAmount(stats?.recurring_payments)

    return (
      <>
        <StatCard
          amount={noCustomers}
          title={'No of Customers'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={noRecurring}
          title={'No of Recurring'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={total}
          title={'Amount All time'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className={'row'}>
          {this._renderStatCards()}
        </div>

        <SubscriptionsTable
          title={'All Recurring Payments'}
        />
      </>
    )
  }

}
