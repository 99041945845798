import React from "react"

import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class ImageUploader extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      image: props.image,
      type: props.type
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onImageChange(e) {
    let {
      type
    } = this.state

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let image = filesArr[0];

    if(image == null){
      return
    }

    this.setState({ loading: true });
    this.props.onUploading()

    Backend.uploadImage(image, type).then(image => {
      this.setState({
        image,
        loading: false
      })
      this.props.onUploaded(image)
    }).catch(error => {
      this.setState({ loading: false })
      alert(error.message)
    })
  }

  render() {

    let {
      image,
      loading
    } = this.state

    let label = image ? image.name : "Choose file"
    return (
      <Div disabled={loading}>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={e => this._onImageChange(e)}
            />
            <label className="custom-file-label">{label}</label>
          </div>
        </div>
      </Div>
    )
  }
}
