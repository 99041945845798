import React from "react"

import Div from "../layouts/Div"

import WebhookModal from "../modal/WebhookModal"
import RevealKeyModal from "../modal/RevealKeyModal"
import ConfirmationModal from "../modal/ConfirmationModal"

import LazyLoadingList from '../common/LazyLoadingList'

import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Event from "../../../utils/Event"
import Notify from "../../../utils/Notify"

export default class ApiSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      secretKey: null,
      loading: true
    }

    this.webhooksList = React.createRef()
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _setup(){
    Backend.getSecretKey()
    .then(secretKey => {
      this.setState({
        secretKey,
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
    })
  }

  _renderWebhook(webhook){
    return (
      <>
        <div className="row">
          <div className="col-9">
            <p className="mt-3">{webhook.url}</p>
          </div>
          <div className="col-3">
            <button
              className="btn btn-sm btn-outline-primary mt-1"
              onClick={() => this.setState({ selectedWebhook: webhook, showWebhookModal: true })}
            >
              Edit
            </button>
            <button
              className="btn btn-sm btn-outline-danger mt-1 ml-2"
              onClick={() => this.setState({ selectedWebhook: webhook, showDeleteWebhookModal: true })}
            >
              Delete
            </button>
          </div>
        </div>
        <hr/>
      </>
    )
  }

  render() {
    let {
      loading,
      secretKey,
      rotateSecretKey,
      selectedWebhook,
      webhooksLoading,
      showWebhookModal,
      showRevealKeyModal,
      showRotateKeyModal,
      showDeleteWebhookModal,
      showRevealConfirmationModal,
      showRotateConfirmationModal,
    } = this.state

    return (
      <>
        <div className="col">
          <div className="form fv-plugins-bootstrap fv-plugins-framework">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <Div
                className="kt-wizard-v2__content"
                data-ktwizard-type="step-content"
                data-ktwizard-state="current"
                disabled={loading}
                spinner={true}
              >
                <h4 className="mb-10 font-weight-bold text-dark">Api</h4>
                <div className="kt-form__section kt-form__section--first">
                  <div className="kt-wizard-v2__form">

                    <div className="form-group row">
                      <label className="col-3 col-form-label">Secret Key</label>
                      <div className="col-6">
                        <input
                          className="form-control form-control-solid"
                          type="text"
                          value={secretKey?.truncated || "*****************"}
                          disabled
                        />
                      </div>
                      <div className="col-3">
                        { !secretKey &&
                          <button
                            className="btn btn-sm btn-outline-danger mt-1"
                            onClick={() => this.setState({ showRevealConfirmationModal: true })}
                          >
                            Reveal
                          </button>
                        }
                        { secretKey &&
                          <button
                            className="btn btn-sm btn-outline-danger mt-1"
                            onClick={() => this.setState({ showRotateConfirmationModal: true })}
                          >
                            Rotate
                          </button>
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </Div>
              <br/>
              <br/>
              <Div
                className="kt-wizard-v2__content"
                data-ktwizard-type="step-content"
                data-ktwizard-state="current"
                disabled={webhooksLoading}
                spinner={true}
              >
                <div className="row">
                  <div className="col-9">
                    <h4 className="mb-10 font-weight-bold text-dark">
                      Webhooks
                    </h4>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-sm btn-outline-primary mt-1"
                      onClick={() => this.setState({ selectedWebhook: null, showWebhookModal: true })}
                    >
                      + Add Webhook
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-9">
                    <h6 className="text-muted">URL</h6>
                  </div>
                  <div className="col-3">
                    <h6 className="text-muted">Actions</h6>
                  </div>
                </div>
                <hr/>
                <LazyLoadingList
                  ref={this.webhooksList}
                  endpoint={window.Api.Webhooks+"?"}
                  renderItem={(webhook, index) => this._renderWebhook(webhook)}
                  renderNoContent={() => (
                      <p className="w-100 text-center text-muted">No Webhooks Found</p>
                    )
                  }
                />
              </Div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          show={showRevealConfirmationModal}
          title={"Reveal Secret Key"}
          body={"This key can only be revealed once to keep your account secure. If you lose it, you must rotate the key."}
          onConfirm={() => this.setState({ showRevealConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: false })}
          confirmTitleText={"Reveal Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRevealConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />
        <ConfirmationModal
          show={showRotateConfirmationModal}
          title={"Rotate Secret Key"}
          body={"Any applications/integrations depending on the current key will no longer work"}
          onConfirm={() => this.setState({ showRotateConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: true })}
          confirmTitleText={"Rotate Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRotateConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />
        <RevealKeyModal
          show={showRevealKeyModal}
          rotate={rotateSecretKey}
          onSecretKeyUpdated={secretKey => this.setState({ secretKey}) }
          onHide={() => {
            this.setState({
              showRevealKeyModal: false,
              rotateSecretKey: false
            })
          }}
        />
        <WebhookModal
          show={showWebhookModal}
          webhook={selectedWebhook}
          onUpdated={() => {
            this.webhooksList.current.refresh()
            this.setState({
              selectedWebhook: null,
              showWebhookModal: false,
            })
          }}
          onHide={() => {
            this.setState({
              selectedWebhook: null,
              showWebhookModal: false,
            })
          }}
        />
        <ConfirmationModal
          show={showDeleteWebhookModal}
          title={"Delete Webhook"}
          body={"Any applications/integrations depending on this webhook may no longer work as expected"}
          confirmTitleText={"Delete Webhook"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onConfirm={() => {
            this.setState({
              webhooksLoading: true,
              showDeleteWebhookModal: false,
            })
            Backend.deleteWebhook(selectedWebhook)
            .then(() => {
              this.webhooksList.current.refresh()
              this.setState({ webhooksLoading: false })
            })
            .catch(error => {
              Notify.error(error.message)
              this.setState({ webhooksLoading: false })
            })
          }}
          onHide={() => {
            this.setState({
              selectedWebhook: null,
              showDeleteWebhookModal: false
            })
          }}
        />
      </>
    )
  }
}
