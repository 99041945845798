import React from "react"

export default class ExpanderCell extends React.Component {

  render(){
    if(!this.props.show()){
      return <div onClick={() => {}}/>
    }

    let className = "rt-expander"
    if(this.props.isExpanded){
      className += " -open"
    }

    return (
      <div
        className="rt-td rt-expandable"
        role="gridcell"
      >
        <div className={className}>•</div>
      </div>
    )
  }
}

ExpanderCell.defaultProps = {

}
