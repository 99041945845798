import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

const DEFAULT_IMAGE = require("../../../assets/media/icons/svg/humans/custom-10.svg")

export default class EngagementCard extends React.Component {

  _renderCTA(){
    if(typeof(this.props.cta) === "function"){
      return this.props.cta()
    }
    else if(this.props.onCtaPressed){
      return (
          <a
            href={"javascript:void(0);"}
            className="btn btn-danger font-weight-bold py-2 px-6"
            onClick={() => this.props.onCtaPressed()}
          >
            { this.props.cta }
          </a>
      )
    }
    return null
  }

  render(){
    return (
      <div className={this.props.containerClassName + ' metr_v702 '}>
        <div className="card card-custom card-stretch gutter-b">
          <div className="card-body d-flex p-0">
            <div className="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
                 style={{
                   backgroundColor: this.props.cardBackgrounColor,
                   backgroundPosition: this.props.cardBackgroundPosition,
                   backgroundSize: this.props.cardBackgroundSize,
                   backgroundImage: this.props.cardBackgroundImage
                 }}
            >
              <div className={this.props.contentContainerClassName}>
                <h2 className="text-white pb-5 font-weight-bolder">{ this.props.title }</h2>
                <p className="text-muted pb-5 font-size-h5">
                  { this.props.renderContent() }
                </p>
                { this._renderCTA() }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EngagementCard.defaultProps = {
  title: "Start Now",
  renderContent: () => (
    <>
    With our responsive themes and mobile and desktop apps, enjoy a seamless experience on any device so will your blog's common visitors
    </>
  ),
  containerClassName: 'col-xl-12',
  cardBackgrounColor: '#1B283F',
  cardBackgroundPosition: 'calc(100% + 0.5rem) bottom',
  cardBackgroundSize: '25% auto',
  cardBackgroundImage: `url(${DEFAULT_IMAGE})`,
  cardBackgroundRepeat: 'no-repeat',
  contentContainerClassName: 'col-6'

}
