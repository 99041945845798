import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import ImageEdit from '../common/ImageEdit';
import ColorPicker from "../common/ColorPicker";
import AsyncSelect from "../common/AsyncSelect";

import DemoCustomer from '../splink/DemoCustomer'
import DemoPaymentPlans from '../splink/DemoPaymentPlans'
import DemoCustomData from '../splink/DemoCustomData'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"

export default class DemoPaymentModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company,
      payment: props.payment,
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      payment,
      company,
      error,
    } = this.state

    if(!payment){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={"sub-modal modal-demo-payment"}
        dialogClassName={"modal-50"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <DemoPaymentPlans
            showOptional={false}
            showCurrency={true}
            priceTitle="Amount"
            company={company}
            paymentPlans={[payment.payment_plan]}
            updatePaymentPlan={(paymentPlan, index) => {
              payment.payment_plan = paymentPlan
              this.props.onUpdated(payment)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Customer
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoCustomer
            customer={payment.customer}
            onUpdated={customer => {
              payment.customer = customer
              this.props.onUpdated(payment)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Custom Data
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoCustomData
            answers={payment.answers}
            onUpdated={answers => {
              payment.answers = answers
              this.props.onUpdated(payment)
            }}
          />



        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this.props.onHide()}
          >
            Ok
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
