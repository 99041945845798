import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Processors from "../../../../utils/Processors"


export default class UpdateConnectProcessorModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: props.company,
      companyProcessor: props.companyProcessor ? JSON.parse(JSON.stringify(props.companyProcessor)) : null,
    }
  }

  _renderContent(){
    let {
      show,
      company,
      companyProcessor,
    } = this.state

    const ConnectProcessorComponent = Processors.getConnectComponent(companyProcessor.processor)
    if(ConnectProcessorComponent){
      return (
        <ConnectProcessorComponent
          company={company}
          data={companyProcessor.data}
          onCancel={processor => this.props.onHide()}
          onUpdated={() => this.props.onUpdated()}
        />
      )
    }

    return null
  }

  render() {
    let {
      show,
      companyProcessor,
    } = this.state

    if(!companyProcessor) return null

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Update ${companyProcessor.processor.name}`}</Modal.Title>
        </Modal.Header>

        { this._renderContent() }

      </Modal>
    )
  }
}
