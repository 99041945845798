import React from "react"

import PackagesTable from "./components/tables/PackagesTable";

import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import Event from "../utils/Event";
import Stats from "../utils/Stats";

export default class Packages extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      stats: null,
      currency: 0
    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <PackagesTable
          title={'Packages'}
        />
      </>
    )
  }

}
