import React from 'react'

import RolesTable from './components/tables/RolesTable'

export default class Roles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <RolesTable title={'Roles'} />
      </>
    )
  }
}
