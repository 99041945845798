import React from 'react'
import Modal from 'react-bootstrap/Modal'
// import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import ImageEdit from "../common/ImageEdit";
// import CountrySelect from "../common/CountrySelect";
import AsyncSelect from "../common/AsyncSelect";
import ColorPicker from "../common/ColorPicker";

export default class ProcessorSettingsModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: JSON.parse(JSON.stringify(props.company)),
      aibConnectData: {},
      showConnectEMerchantPaySettings: props.showConnectEMerchantPaySettings,
      eMerchantPayConnectData: this._getEMerchantPayConnectData(props.company)
    }
  }

  _getAibConnectData(company){
    let live = company.settings.live
    let aibSlug = live ? "aib" : "aib_test"
    let aibConnectData = company.company_processors.find(company_processor => company_processor.processor_slug === aibSlug)
    return aibConnectData || {}
  }

  _getEMerchantPayConnectData(company){
    let live = company.settings.live
    let eMerchantPaySlug = live ? "emerchantpay" : "emerchantpay_test"
    let eMerchantPayProcessor = company.company_processors.find(company_processor => company_processor.processor_slug === eMerchantPaySlug)
    return eMerchantPayProcessor?.data || {
      username: "",
      password: "",
      terminals: {
        eur: {},
        gbp: {},
        usd: {}
      },
      public_key: ""
    }
  }

  _isEmerchantPayFormValid(){
    let { eMerchantPayConnectData } = this.state

    let {
      username,
      password,
      public_key,
      terminals
    } = eMerchantPayConnectData

    let error = null
    if(!username){
      error = "Please provide a valid username"
    }
    else if(!password){
      error = "Please provide a valid password"
    }
    else if(!public_key){
      error = "Please provide a valid Public Key"
    }
    else{
      for(const [key, terminal] of Object.entries(terminals)){
        if(!terminal.no_3d && !terminal.no_cvv){
          continue
        }
        if(terminal.no_3d && !terminal.no_cvv){
          error =`Please provide a valid No CVV ${key.toUpperCase()} Terminal`
          break
        }
        if(!terminal.no_3d && terminal.no_cvv){
          error =`Please provide a valid Sale ${key.toUpperCase()} Terminal`
          break
        }
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _update(){
    let {
      company
    } = this.state

    let companyProcessor = company.company_processors.find(company_processor => company_processor.default === true)

    this.setState({ loading: true })
    Backend.updateDefaultProcessor(companyProcessor)
    .then(data => {
      Notify.success("Updated Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _connectToAib(){
    let {
      company,
      aibConnectData,
    } = this.state

    Backend.connectToAib(company, aibConnectData)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _connectToEMerchantPay(){
    let {
      company,
      eMerchantPayConnectData,
    } = this.state

    if(!this._isEmerchantPayFormValid()){
      return
    }

    Backend.connectToEMerchantPay(company, eMerchantPayConnectData)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderConnectAibSettings(){
    let {
      aibConnectData,
    } = this.state

    return (
      <>
        <div className="form-group row">
          <label className="col-3 col-form-label">Store/Merchant iD</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={aibConnectData.store_id}
                onChange={e => {
                  aibConnectData.store_id = e.target.value
                  this.setState({ aibConnectData })
                }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Api Key</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={aibConnectData.api_key}
                onChange={e => {
                  aibConnectData.api_key = e.target.value
                  this.setState({ aibConnectData })
                }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Api Secret</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={aibConnectData.api_secret}
                onChange={e => {
                  aibConnectData.api_secret = e.target.value
                  this.setState({ aibConnectData })
                }}
            />
          </div>
        </div>
      </>
    )
  }

  _renderConnectEMerchantPaySettings(){
    let {
      eMerchantPayConnectData,
    } = this.state

    let currencyCodes = ["eur", "gbp", "usd"]
    return (
      <>
        <div className="form-group row">
          <label className="col-3 col-form-label">Username</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={eMerchantPayConnectData.username}
                onChange={e => {
                  eMerchantPayConnectData.username = e.target.value
                  this.setState({ eMerchantPayConnectData })
                }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Password</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={eMerchantPayConnectData.password}
                onChange={e => {
                  eMerchantPayConnectData.password = e.target.value
                  this.setState({ eMerchantPayConnectData })
                }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Public Key</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={eMerchantPayConnectData.public_key}
                onChange={e => {
                  eMerchantPayConnectData.public_key = e.target.value
                  this.setState({ eMerchantPayConnectData })
                }}
            />
          </div>
        </div>

        { currencyCodes.map(code => {
            let upperCaseCode = code.toUpperCase()
            let terminal = eMerchantPayConnectData.terminals[code] || {}
            return (
              <>
                <div className="c-separator form-group row mt-4 align-items-center text-center">
                  <div className="col-3">
                    <hr/>
                  </div>
                  <div className="col-6">
                    <span>
                      <strong>
                        {upperCaseCode} Terminal
                      </strong>
                    </span>
                  </div>
                  <div className="col-3">
                    <hr/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">'Sale' {upperCaseCode} Terminal</label>
                  <div className="col-9">
                    <input
                        className="form-control form-control-solid"
                        type="text"
                        value={terminal.no_3d}
                        onChange={e => {
                          terminal.no_3d = e.target.value
                          eMerchantPayConnectData.terminals[code] = terminal
                          this.setState({ eMerchantPayConnectData })
                        }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">'Sale 3D' {upperCaseCode} Terminal</label>
                  <div className="col-9">
                    <input
                        className="form-control form-control-solid"
                        type="text"
                        value={terminal["3d"]}
                        onChange={e => {
                          terminal["3d"] = e.target.value
                          eMerchantPayConnectData.terminals[code] = terminal
                          this.setState({ eMerchantPayConnectData })
                        }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-3 col-form-label">'No CVV' {upperCaseCode} Terminal</label>
                  <div className="col-9">
                    <input
                        className="form-control form-control-solid"
                        type="text"
                        value={terminal.no_cvv}
                        onChange={e => {
                          terminal.no_cvv = e.target.value
                          eMerchantPayConnectData.terminals[code] = terminal
                          this.setState({ eMerchantPayConnectData })
                        }}
                    />
                  </div>
                </div>
              </>
            )
          })
        }
      </>
    )
  }

  _renderContent(){
    let {
      show,
      company,
      loading,
      aibConnectData,
      showConnectAibSettings,
      showConnectEMerchantPaySettings
    } = this.state

    if(showConnectAibSettings){
      return this._renderConnectAibSettings()
    }

    if(showConnectEMerchantPaySettings){
      return this._renderConnectEMerchantPaySettings()
    }

    let selectedCompanyProcessor = company.company_processors.find(companyProcessor => companyProcessor.default === true)
    let platformRate = parseFloat(selectedCompanyProcessor.rate) ? Math.round((selectedCompanyProcessor.rate * 100) * 100) / 100 : ''

    return (
      <>
        <div className="form-group row">
          <label className="col-3 col-form-label">Processor</label>
          <div className="col-9">
            <select
                className="form-control form-control-solid"
                value={selectedCompanyProcessor.id}
                onChange={e => {
                  company.company_processors = company.company_processors.map(companyProcessor => {
                    companyProcessor.default = parseInt(e.target.value) === companyProcessor.id
                    return companyProcessor
                  })

                  this.setState({ company })
                }}
            >
              { company.company_processors.map(companyProcessor => {
                return (
                    <option value={companyProcessor.id}>{General.snakeCaseToTitleCase(companyProcessor.processor_slug)}</option>
                )
              })
              }
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">*{General.snakeCaseToTitleCase(selectedCompanyProcessor.processor_slug)} Rate</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="number"
              placeholder="0.00"
              step={"0.01"}
              value={platformRate}
              onChange={e => {
                selectedCompanyProcessor.rate = (e.target.value / 100).toFixed(4)
                company.company_processors = company.company_processors.map(companyProcessor => {
                  if(companyProcessor.id === selectedCompanyProcessor.id){
                    companyProcessor = selectedCompanyProcessor
                  }
                  return companyProcessor
                })
                this.setState({ company })
              }}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      company,
      loading,
      aibConnectData,
      showConnectAibSettings,
      showConnectEMerchantPaySettings
    } = this.state

    let title = "Processor Settings";

    if(!company) return null

    let showConnectAibButton = company.company_processors.find(companyProcessor => companyProcessor.processor_slug.indexOf("aib") > -1) == null
    let showConnectEMerchantPayButton = company.company_processors.find(companyProcessor => companyProcessor.processor_slug.indexOf("emerchantpay") > -1) == null

    let selectedCompanyProcessor = company.company_processors.find(companyProcessor => companyProcessor.default === true)


    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { this._renderContent() }

        </Modal.Body>

        <Modal.Footer>

          { (!showConnectAibSettings && !showConnectEMerchantPaySettings) &&
            <>
              <button
                  disabled={loading}
                  type="button"
                  className="btn btn-outline-primary ml-3"
                  onClick={() => this.setState({ showConnectAibSettings: true })}
              >
                { showConnectAibButton ? "Connect AIB" : "Update AIB Details" }
              </button>
              <button
                  disabled={loading}
                  type="button"
                  className="btn btn-outline-primary ml-3"
                  onClick={() => this.setState({ showConnectEMerchantPaySettings: true })}
              >
                { showConnectEMerchantPayButton ? "Connect eMerchantPay" : "Update eMerchantPay Details" }
              </button>
            </>
          }

          <div className="mr-auto"/>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          { (selectedCompanyProcessor || showConnectAibSettings || showConnectEMerchantPaySettings) &&
            <button
                type="button"
                className="btn btn-primary font-weight-bold"
                disabled={loading}
                onClick={() => {
                  if(showConnectAibSettings){
                    this._connectToAib()
                  }
                  else if(showConnectEMerchantPaySettings){
                    this._connectToEMerchantPay()
                  }
                  else {
                    this._update()
                  }
                }}
            >
              Save
            </button>
          }

        </Modal.Footer>
      </Modal>
    )
  }
}
