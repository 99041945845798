import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import Notify from "../../../utils/Notify"
import AuthManager from "../../../utils/AuthManager";
import Splink from "../../../utils/Splink";
import Backend from "../../../utils/Backend";

export default class ResendInvoiceModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      invoice: props.invoice,
      subscription: props.subscription,
      email: this._getCurrentEmail(props.subscription)
    }
  }

  _resendReceipt() {
    let { invoice, email } = this.state

    this.setState({ loading: true })
    Backend.resendPaymentReceipt(invoice.payment, email)
    .then(() => {
      this.setState({
        loading: false,
      })
      this.props.onResent()
    })
    .catch((e) => {
      Notify.error(e.message)
      this.setState({ loading: true })
    })
  }

  _getCurrentEmail(subscription){
    return subscription.payment_instrument?.customer_data.email || subscription.initial_payment.customer.email
  }

  _getEmails(){
    let { invoice, subscription} = this.state
    let emails = [this._getCurrentEmail(subscription)]
    if(invoice.payment.payment_instrument){
      emails = [
        ...emails,
        invoice.payment.payment_instrument.customer_data.email
      ]
    }

    let uniqueEmails = [...new Set(emails)];

    let nonBlankUniqueEmails = emails.filter(n => n)

    return nonBlankUniqueEmails
  }

  render() {
    let {
      show,
      loading,
      invoice,
      subscription,
      email
    } = this.state

    let emails = this._getEmails()

    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resend Invoice</Modal.Title>
        </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to resend the receipt for this payment?</p>

            {emails.length > 1 &&
              <>
                <div className="form-group row mx-0">
                  <label>Recipient Email</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      style={{width: 80}}
                      value={email}
                      onChange={e => {
                        email = e.target.value
                        this.setState({ email })
                      }}
                    >
                      { emails.map(email => {
                          return (
                            <option value={`${email}`}>
                              {email}
                            </option>
                          )
                        })
                      }
                    </select>
                    <span className="text-muted">Note: The email for this subscription has been updated since this invoice was paid. Please select the email you would like to resend the receipt to.</span>
                  </div>
                </div>
              </>
            }
          </Modal.Body>
        <Modal.Footer>

          <button
            type="button"
            className={`btn btn-light-primary font-weight-bold`}
            onClick={() => this.props.onHide()}
            disabled={loading}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary font-weight-bold`}
            onClick={() => this._resendReceipt()}
            disabled={loading}
          >
            Resend
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

ResendInvoiceModal.defaultProps = {
}
