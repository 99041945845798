import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from "../common/PhoneInput";

import ImageUploader from "../common/ImageUploader";
import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import AsyncSelect from "../common/AsyncSelect";
import Calculations from "../../../utils/Calculations";
import Currency from "../../../utils/Currency";
import AuthManager from "../../../utils/AuthManager";
import ImageEdit from "../common/ImageEdit";
import Event from "../../../utils/Event"

export default class DemoCustomer extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      customer: props.customer,
    }
  }

  render() {
    let {
      customer,
    } = this.state

    return (
      <>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">First Name</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="first_name"
              placeholder={"First Name"}
              className="form-control form-control-solid"
              value={customer.first_name}
              onChange={e => {
                customer.first_name = e.target.value
                this.setState({ customer, error: null })
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Last Name</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="last_name"
              placeholder={"Last Name"}
              className="form-control form-control-solid"
              value={customer.last_name}
              onChange={e => {
                customer.last_name = e.target.value
                this.setState({ customer, error: null })
              }}
            />
          </div>
        </div>

        {/*}
        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Phone</label>
          <div className="col-lg-9 my-auto">
            <PhoneInput
              onChange={(value, data) => {

              }}
              inputProps={{
                autocomplete: 'off',
                className: "form-control form-control-solid"
              }}
            />
          </div>
        </div>
        */}

        <div className="form-group row">
          <label className="col-lg-3 col-form-label">Email</label>
          <div className="col-lg-9 my-auto">
            <input
              type="text"
              name="email"
              placeholder={"Email"}
              className="form-control form-control-solid"
              value={customer.email}
              onChange={e => {
                customer.email = e.target.value
                this.setState({ customer, error: null })
              }}
            />
          </div>
        </div>

      </>
    )
  }
}

DemoCustomer.defaultProps = {

}
