import React from "react";

import moment from "moment";

import Currency from "../../../utils/Currency";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import Utils from "./Utils";
import AuthManager from "../../../utils/AuthManager";

import ExplainerAlert from "../alert/ExplainerAlert";
import FetchHelper from "../../../utils/FetchHelper";

import PaymentAttemptLogs from "./PaymentAttemptLogs";

export default class PaymentAttemptDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentAttemptId: props.paymentAttemptId,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps, () => this._loadPaymentAttempt());
  }

  componentDidMount() {
    this._loadPaymentAttempt();
  }

  _loadPaymentAttempt() {
    const { paymentAttemptId } = this.state;

    this.setState({ paymentAttempt: null });

    if (!paymentAttemptId) {
      return;
    }

    Backend.getPaymentAttempt(paymentAttemptId)
      .then((paymentAttempt) => {
        this.setState({ paymentAttempt, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _renderFrequency(paymentAttempt) {
    if (!paymentAttempt) {
      return "-";
    }

    let paymentPlan = paymentAttempt.data.payment_plan;

    if (paymentPlan.type === "one_time" && paymentPlan.frequency === null) {
      return "";
    } else if (paymentPlan.type === "recurring" && paymentPlan.frequency) {
      let interval = paymentPlan.frequency.replace("ly", "");
      return `per ${interval}`;
    }

    return "";
  }

  _renderPaymentAttemptDetail(title, value) {
    if (!value) {
      return null;
    }

    return (
      <div className="mt-2">
        <p className="text-dark m-0">
          <b>{title}:</b> {value}
        </p>
      </div>
    );
  }

  _getError() {
    const { paymentAttempt } = this.state;

    if (!paymentAttempt.base_request?.response) {
      return "An Unexpected Error Occured";
    }

    return FetchHelper._getError(paymentAttempt.base_request.response).message;
  }

  _renderAmount() {
    let { paymentAttempt } = this.state;

    let amount =
      paymentAttempt?.data?.payment_plan.total &&
      Currency.format(
        paymentAttempt.data.payment_plan._original.currency.symbol,
        paymentAttempt.data.payment_plan.total
      );

    return <>{amount}</>;
  }

  render() {
    let { paymentAttempt } = this.state;

    if (!paymentAttempt) {
      return null;
    }

    let companyName = paymentAttempt.company.name;

    let customerFullName =
      paymentAttempt.data.customer &&
      `${paymentAttempt.data.customer.first_name} ${paymentAttempt.data.customer.last_name}`;

    let customerEmail = paymentAttempt.data?.customer.email;

    let paymentAttemptId = paymentAttempt?.id;

    let date =
      paymentAttempt.created_at &&
      moment(paymentAttempt?.created_at).format("LLL");

    return (
      <>
        <div
          className="card card-custom bgi-no-repeat gutter-b c-detailed"
          style={{
            backgroundPosition: "right top",
            backgroundSize: "30% auto",
            backgroundImage: "url(assets/media/icons/abstract-1.svg)",
          }}
        >
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <div className="card-title">
              <h3 className="card-label font-size-h1">
                <span className="font-weight-bolder text-dark-75 mr-1">
                  {this._renderAmount()}
                </span>
                <span className="font-weight-light text-dark">
                  {this._renderFrequency(paymentAttempt)}
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body pt-3">
            <span
              className={`label label-inline label-lg ${Utils.getLabelClass(
                paymentAttempt
              )} btn-sm font-weight-bold`}
            >
              {Utils.getStatus(paymentAttempt)}
            </span>

            <div className="my-2">
              {AuthManager.currentUser.user.role === "admin" &&
                this._renderPaymentAttemptDetail("Company", companyName)}

              {this._renderPaymentAttemptDetail(
                "Customer Email",
                customerEmail
              )}

              {this._renderPaymentAttemptDetail(
                "Customer Name",
                customerFullName
              )}

              {this._renderPaymentAttemptDetail("Date", date)}

              {this._renderPaymentAttemptDetail(
                "Payment Attempt ID",
                paymentAttemptId
              )}

              {Utils.getAlert(paymentAttempt) && (
                <div className="mt-5">
                  <ExplainerAlert type="danger" text={this._getError()} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card card-custom c-logs">
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <div className="card-title">
              <h3 className="card-label">Logs</h3>
            </div>
          </div>
          <div className="card-body pt-3">
            <PaymentAttemptLogs requests={paymentAttempt.sub_requests} />
          </div>
        </div>
      </>
    );
  }
}
