import React from 'react'
import Modal from 'react-bootstrap/Modal'

import CountrySelect from "../../common/CountrySelect"

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify"

export default class TokenConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props)
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      data: props.data || {
        sub_tpp: {

        },
        ais: {

        }
      },
    }
  }

  _update(key, value){
      let { data } = this.state

      data[key] = value
      this.setState({ data })
  }

  _isFormValid(){
    let { data } = this.state

    let {
      sub_tpp,
      account_holder_name,
    } = data

    let statement_descriptor = data.ais.statement_descriptor

    let {
      domicile_country,
      name,
      legal_entity_name,
      merchant_jurisdiction,
      company_registration_number,
      primary_use_case,
      iban
    } = sub_tpp

    let error = null

    if(!statement_descriptor){
      error = "Please enter a valid 'Statement Descriptor'"
    }

    if(!error && !sub_tpp.id){
      if(!merchant_jurisdiction){
        error = "Please enter a valid 'Merchant Jurisdiction'"
      }
      else if(!legal_entity_name){
        error = "Please enter a valid 'Legal Entity Name'"
      }
      else if(!company_registration_number){
        error = "Please enter a valid 'Cmpany Registration Number'"
      }
      else if(!primary_use_case){
        error = "Please enter a valid 'Primary Use Case'"
      }
      else if(!iban){
        error = "Please enter a valid 'IBAN'"
      }
    }

    if(!error && sub_tpp.id && !account_holder_name){
      error = "Please enter a valid account holder name"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }


  _save(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    data = { ...data }
    if(data.sub_tpp.id){
      data.action = "edit_details"
    }

    data.statement_descriptor = data.ais.statement_descriptor

    this.setState({ loading: true })
    return Backend.connectToToken(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
          { data.sub_tpp.id &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">Sub TPP ID</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    value={data.sub_tpp.id}
                    disabled={true}
                 />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Account Holder Name</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="Account Holder Name"
                    value={data.account_holder_name}
                    onChange={e => {
                      data.account_holder_name = e.target.value || null
                      this.setState({ data })
                    }}
                 />
                </div>
              </div>
            </>
          }
          <div className="form-group row">
            <label className="col-3 col-form-label">Statement Descriptor</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={data.ais.statement_descriptor}
                placeholder="Statement Descriptor"
                maxLength={4}
                onChange={e => {
                  data.ais.statement_descriptor = e.target.value || null
                  this.setState({ data })
                }}
             />
             <span className="text-muted">4 Characters that identify your business on a customer's bank statement</span>
            </div>
          </div>

          { !data.sub_tpp.id &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">Merchant Jurisdiction</label>
                <div className="col-9">
                  <CountrySelect
                    value={data.sub_tpp.domicile_country}
                    className="form-control-solid"
                    isDisabled={data.sub_tpp.id != null}
                    onSelected={country => {
                      data.sub_tpp.domicile_country = country.value
                      data.sub_tpp.merchant_jurisdiction = country.value
                      this.setState({ data })
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Legal Entity Name</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="Legal Entity Name"
                    value={data.sub_tpp.legal_entity_name}
                    onChange={e => {
                      data.sub_tpp.legal_entity_name = e.target.value || null
                      this.setState({ data })
                    }}
                 />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Company Registered Number</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="Company Registered Number"
                    value={data.sub_tpp.company_registration_number}
                    onChange={e => {
                      data.sub_tpp.company_registration_number = e.target.value || null
                      this.setState({ data })
                    }}
                 />
                 <span className="text-muted">If sole trader, enter 000 or N/A</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Primary Use Case</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="Primary Use Case"
                    value={data.sub_tpp.primary_use_case}
                    onChange={e => {
                      data.sub_tpp.primary_use_case = e.target.value || null
                      this.setState({ data })
                    }}
                 />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">IBAN</label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="IBAN"
                    value={data.sub_tpp.iban}
                    onChange={e => {
                      data.sub_tpp.iban = e.target.value || null
                      this.setState({ data })
                    }}
                 />
                 <span className="text-muted">IBAN will be validated, it must be a valid IBAN and cannot be empty</span>
                </div>
              </div>
            </>
          }
        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._save()}
            >
              Save
            </button>


        </Modal.Footer>
      </>
    )
  }

}
