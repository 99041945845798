import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify"

export default class BoipaConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props)
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      data: props.data || {
        terminals: {}
      },
    }
  }

  _updateTerminal(terminalKey, valueKey, value){
      let { data } = this.state

      let terminalData = data.terminals[terminalKey] || {}
      terminalData[valueKey] = value

      if(!terminalData.merch_id && !terminalData.brand_id && !terminalData.password){
        terminalData = null
        delete data.terminals[terminalKey]
      }
      else{
        data.terminals[terminalKey] = terminalData
      }

      this.setState({ data })
  }

  _isFormValid(){
    let { data } = this.state

    let {
      eur,
      gbp,
    } = data.terminals

    let error = this._isTerminalValid(data.terminals.default, "Default")
    if(!error && eur){
      error = this._isTerminalValid(eur, "Euro")
    }
    if(!error && gbp){
      error = this._isTerminalValid(gbp, "GBP")
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _isTerminalValid(terminalData, title){
    if(!terminalData){
      return `Please provide values for your ${title} terminal`
    }

    let {
      merch_id,
      brand_id,
      password,
    } = terminalData

    let error = null
    if(!merch_id){
      error = `Please provide a MID for your ${title} terminal`
    }
    else if(!password){
      error = `Please provide a Password for your ${title} terminal`
    }
    else if(!brand_id){
      error = `Please provide a Brand ID for your ${title} terminal`
    }

    return error
  }


  _connect(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectToBoipa(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderTerminal(key, title){
    let { data } = this.state

    let terminalData = data.terminals[key] || {}
    return (
      <>
        <h4>{title}</h4>
        <div className="form-group row">
          <label className="col-3 col-form-label">Merchant iD</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={terminalData.merch_id}
                onChange={e => {
                  this._updateTerminal(key, "merch_id", e.target.value)
                }}
             />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Password</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={terminalData.password}
                onChange={e => {
                  this._updateTerminal(key, "password", e.target.value)
                }}
             />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Brand ID</label>
          <div className="col-9">
            <input
                className="form-control form-control-solid"
                type="text"
                value={terminalData.brand_id}
                onChange={e => {
                  this._updateTerminal(key, "brand_id", e.target.value)
                }}
             />
          </div>
        </div>
      </>
    )
  }


  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
          { this._renderTerminal("default", "Default MID Details") }
          { this._renderTerminal("eur", "Euro MID Details") }
          { this._renderTerminal("gbp", "GBP MID Details") }
        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }

}
