export default class Utils {
  static getLabelClass(paymentAttempt) {
    return Utils._getValues(paymentAttempt).labelClass;
  }

  static getStatus(paymentAttempt) {
    return Utils._getValues(paymentAttempt).status;
  }

  static getAlert(paymentAttempt) {
    return Utils._getValues(paymentAttempt).showAlert;
  }

  static _getValues(paymentAttempt) {
    let labelClass = "";
    let status = "";
    let showAlert = false;

    if (paymentAttempt?.status === "complete") {
      labelClass = "label-light-success";
      status = "Completed";
    } else if (paymentAttempt?.status === "view") {
      labelClass = "label-light-info";
      status = "Viewed";
    } else if (paymentAttempt?.status === "3ds_challenge") {
      labelClass = "label-info";
      status = "3DS Challenge";
    } else if (paymentAttempt?.status === "charge_failed") {
      labelClass = "label-danger";
      status = "Charge Failed";
      showAlert = true;
    } else if (paymentAttempt?.status === "form_validation_failed") {
      labelClass = "label-danger";
      status = "Form Error";
      showAlert = true;
    } else if (paymentAttempt?.status === "3ds_verified") {
      labelClass = "label-light-info";
      status = "3DS Verified";
    }

    return { labelClass, status, showAlert };
  }
}
