import React from "react"

import BaseTable from "./BaseTable"

import moment from "moment"

import ConfirmationModal from "../modal/ConfirmationModal";
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import InvoicesTable from "./InvoicesTable";
import CustomerCell from "./cells/CustomerCell";
import Currency from "../../../utils/Currency";
import PaidCell from "./cells/PaidCell";
import ViewCell from "./cells/ViewCell";
import CheckCell from "./cells/CheckCell";
import DeleteCell from "./cells/DeleteCell";
import NudgeCell from "./cells/NudgeCell";
import PillCell from "./cells/PillCell";
import General from "../../../utils/General";
import SendCell from "./cells/SendCell";
import Splink from "../../../utils/Splink";

const STATUS_FILTERS = {
  name: {
    api: "status",
    display: "Addons"
  },
  values: [{
    label: "All Add-Ons",
    value: null
  },
    {
      label: "Take",
      value: "take"
    },
    {
      label: "Request",
      value: "request"
    },
    {
      label: "Shop",
      value: "shop"
    }]
}

const FILTERS = [
  STATUS_FILTERS
]

export default class SubscriptionsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Company',
        id: 'initial_payment.company.name',
        accessor: 'initial_payment.company.name',
        width: 160
      },
      {
        Header: 'Customer',
        id: 'initial_payment.customer.email',
        Cell: rowInfo => {
          let customer = rowInfo.original.initial_payment.customer
          return (
            <CustomerCell
              customer={customer}
            />
          )
        },
        width: 160
      },
      {
        Header: 'Amount',
        id: 'payment_plan.total',
        width: 100,
        Cell: (rowInfo) => {
          let subscription = rowInfo.original
          let paymentPlan = subscription.payment_plan || subscription.initial_payment.payment_plan

          return Currency.format(
            paymentPlan.currency.symbol,
            paymentPlan.total
          )
        },
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let subscription = rowInfo.original
          let className = subscription.status === 'active' ? 'label-light-success' : 'label-light-danger'
          if(subscription.status === 'complete'){
            className = 'label-light-primary'
          }
          return (
            <PillCell
              text={General.toTitleCase(subscription.status)}
              className={className}
            />
          )
        }
      },
      {
        Header: 'Type',
        id: 'initial_payment.payment_plan',
        sortable: false,
        Cell: rowInfo => {
          let subscription = rowInfo.original
          let text = subscription.initial_payment.payment_plan.product ? 'Shop' : subscription.initial_payment.payment_plan.payment_request ? 'Request' : 'Take'
          let className = subscription.initial_payment.payment_plan.product ? 'label-light-info' : subscription.initial_payment.payment_plan.payment_request ? 'label-light-danger' : 'label-light-primary'
          return (
            <PillCell
              text={text}
              className={className}
            />
          )
        }
      },
      {
        Header: 'Frequency',
        id: 'initial_payment.payment_plan.frequency',
        width: 110,
        accessor: subscription => General.toTitleCase(subscription.initial_payment.payment_plan.frequency)
      },
      {
        Header: 'Installments',
        id: 'installments_paid',
        width: 180,
        accessor: 'installments_paid',
        Cell: (rowInfo) => {
          let subscription = rowInfo.original

          let paymentPlan = subscription.payment_plan || subscription.initial_payment.payment_plan
          let installmentsNo = paymentPlan.installments
          if(installmentsNo){
            return `${subscription.installments_paid} paid out of ${installmentsNo}`
          }
          return "Continuous"
        }
      },
      {
        Header: 'Paid',
        id: 'initial_payment.paid_at',
        accessor: subscription => moment(subscription.initial_payment.paid_at).format('DD MMM YYYY') || '-'
      },
      {
        Header: 'Next Payment',
        id: 'next_invoice_date_at',
        accessor: subscription => subscription.next_invoice_date_at ? moment(subscription.next_invoice_date_at).format('DD MMM YYYY') : '-'
      }
    ]
  }

  _cancelSubscription(){
    let {
      subscription
    } = this.state

    Backend.cancelSubscription(subscription).then(() => {
      Notify.success('Subscription has been canceled successfully')
      this.setState({
        subscription: null,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        subscription: null,
        showConfirmationModal: false
      })
    })
  }

  _renderToolBar(){

    return (
      <>

      </>
    )
  }

  _renderPayments(subscription){
    return(
      <InvoicesTable
        subscription={subscription}
        title={`${subscription.customer?.email || ''}`}
        showExport={false}
      />
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={'Are you sure you want to cancel this subscription?'}
        onConfirm={() => this._cancelSubscription()}
        onHide={() => {
          this.setState({
            subscription: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  render() {
    let {

    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Subscriptions}`}
          noDataMessage={"No recurring payment found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={FILTERS}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          SubComponent={row => this._renderPayments(row.original)}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderConfirmationModal() }
      </div>
    )
  }
}
