import React from 'react'
import Modal from 'react-bootstrap/Modal'
// import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import AuthManager from "../../../utils/AuthManager"
import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import ImageEdit from "../common/ImageEdit";
// import CountrySelect from "../common/CountrySelect";
import AsyncSelect from "../common/AsyncSelect";
import ColorPicker from "../common/ColorPicker";

export default class UpdateCompanyModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentDidMount() {
    this._getCompany()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps), () => {
      this._getCompany()
    })
  }

  _getState(props){
    return {
      ...props,
      company: props.company,
      phone: '+353'
    }
  }

  _getCompany(){
    let {
      company
    } = this.state

    if(!company) return

    Backend.getCompany(company).then(companyDetail => {
      this.setState({
        company: companyDetail,
        companyDetail
      })
    })
  }

  _checkSlug(){
    let {
      company
    } = this.state

    this.setState({ isLoadingSlug: true })
    Backend.slugify(company.slug)
    .then(response => {
      if(response.slug != company.slug){
        this.setState({ errorSlug: true, isLoadingSlug: false })
      }
      else{
        this.setState({ errorSlug: false, isLoadingSlug: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoadingSlug: false })
    })
  }

  _handleChange(e) {
    let company = this.state.company
    company[e.target.name] = e.target.value
    this.setState({ company, error: null })
  }

  _handlePhoneUpdate(value, data) {
    let {
      company
    } = this.state

    company.members[0].user.phone_country_code = `+${data.dialCode}`
    company.members[0].user.phone_number = value.slice(data.dialCode.length)
    this.setState({
      phone: value,
      company
    })
  }

  _isFormValid(){
    let {
      company
    } = this.state

    let settings = company.settings

    let error = null

    if(company.name == null || company.name.length === 0){
      error = "Please enter a company name"
    }
    else if(settings.sms_from && settings.sms_from.length > 11){
      error = "Custom SMS must be less than 12 characters"
    }
    else if(settings.sms_from && settings.sms_from.match(/[^a-zA-Z0-9 :]/g)){
      error = "Custom SMS must only have alphanumeric characters"
    }

    if(error){
      Notify.error(error)
      return false
    }
    return true
  }

  _handleUpdateCompany(){
    if(!this._isFormValid()){
      return
    }
    this._updateCompany()
  }

  _updateCompany(){
    let {
      company
    } = this.state

    this.setState({ loading: true })
    Backend.updateCompany(company)
    .then(company => {
      Notify.success("Company Updated Successfully")
      this.setState({ loading: false })
      this.props.onAdded(company)
    })
    .catch(error => {
      this.setState({ loading: false })
      let linkName = window.General.Branding.LinkName
      error.message = error.message?.replace("splink", linkName).replace("Slug", linkName).replace("slug", linkName)
      Notify.error(error.message)
    })
  }

  _renderContent(){

    let {
      show,
      company,
      loading,
      companyDetail,
      errorSlug,
    } = this.state

    if(!companyDetail){
      return <p className="text-center">Loading...</p>
    }

    let platformRate = parseFloat(company.settings.platform_rate) ? Math.round((company.settings.platform_rate * 100) * 100) / 100 : ''
    let clientCut = parseFloat(company.settings.rate) ? Math.round((company.settings.rate * 100) * 100) / 100 : ''
    let partnerCut = parseFloat(company.settings.association_rate) ? Math.round((company.settings.association_rate * 100) * 100) / 100 : ''

    let platformFixedCharge = company.settings.platform_flat_charge ? company.settings.platform_flat_charge / 100 : ""
    let partnerFixedCharge = company.settings.association_flat_charge ? company.settings.association_flat_charge / 100 : ""

    let slugClassName = ""

    if(errorSlug){
      slugClassName += "input-group-append-bg-dark-error"
    }

    return (
      <>
        <div className="form-group row">
          <label className="col-3 col-form-label">*Company Name</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="text"
              value={company.name}
              name={"name"}
              onChange={e => this._handleChange(e)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">*{window.General.Branding.LinkName}</label>
          <div className="col-9">
            <div className="input-group">
              <input
                className="form-control form-control-solid"
                type="text"
                placeholder="business"
                value={company.slug}
                name={"slug"}
                onChange={e => {
                  company.slug = e.target.value
                  this.setState({ company, errorSlug: null })
                }}
                onBlur={e => {
                  this._checkSlug()
                }}
              />
              <div className={`input-group-append ${slugClassName}`}>
                <span className="input-group-text" style={{border:'none'}}>.{window.General.Branding.Domain}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Approved?</label>
          <div className="col-9">
            <span className="switch switch-icon">
              <label>
                <input
                  type="checkbox"
                  checked={company.settings.validated}
                  onChange={e => {
                    company.settings.validated = e.target.checked
                    this.setState({company})
                  }}
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <div className="form-group row background">
          <label className="col-3 col-form-label">*Background Image</label>
          <div className="col-9">
            <ImageEdit
              image={company.settings.background}
              hidePreview={false}
              deletable={true}
              imagePicker={true}
              type={"banner"}
              onUpdated={image => {
                this.setState({loading: false})
                company.settings.background = image
                this.setState({company, error: null})
              }}
              onDeleteClicked={() => {
                company.settings.background = null
                this.setState({company, error: null})
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">*Main Color</label>
          <div className="col-9">
            <ColorPicker
              color={company.settings.primary_color}
              onColorChange={color => {
                company.settings.primary_color = color.hex
                this.setState({company})
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Portal Color</label>
          <div className="col-9">
            <ColorPicker
              color={company.settings.secondary_color}
              onColorChange={color => {
                company.settings.secondary_color = JSON.stringify(color.rgb)
                this.setState({company})
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">*Description</label>
          <div className="col-9">
            <div className="select-wrapper">
              <select
                className="form-control form-control-solid"
                value={company.settings.collect_note}
                onChange={e => {
                  company.settings.collect_note = e.target.value
                  this.setState({company})
                }}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
                <option value="description">Text</option>
              </select>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Email</label>
          <div className="col-9">
            <span className="switch switch-icon">
              <label>
                <input
                  type="checkbox"
                  onChange={e => {
                    company.settings.collect_email = e.target.value
                    this.setState({company})
                  }}
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Payment Type</label>
          <div className="col-9">
            <div
              className="radio-inline"
              onChange={e => {
                company.settings.payment_plan.type = e.target.value
                if(e.target.value === "optional"){
                  company.settings.payment_plan.vat = null
                  company.settings.payment_plan.subtotal = null
                  company.settings.payment_plan.total = null
                  company.settings.payment_plan.frequency = null
                  company.settings.payment_plan.installments = null
                  company.settings.payment_plan.open = true
                }
                this.setState({ company })
              }}
            >
              <label className="radio">
                <input
                  type="radio"
                  name="paymentType"
                  value={"one_time"}
                  checked={company.settings.payment_plan.type === "one_time"}
                />
                One Time Payment
                <span></span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="paymentType"
                  value={"recurring"}
                  checked={company.settings.payment_plan.type === "recurring"}
                />
                Recurring / Installment Plan
                <span></span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="paymentType"
                  value={"optional"}
                  checked={company.settings.payment_plan.type === "optional"}
                />
                  Optional
                <span></span>
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">*Currency</label>
          <div className="col-9">
            <AsyncSelect
              endpoint={window.Api.Currencies}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              params={{ }}
              value={General.getSingleAsyncOption(company.settings.payment_plan.currency)}
              onSelected={currency => {
                company.settings.payment_plan.currency = currency
                this.setState({company})
              }}
              getOptions={currencies => General.getAsyncOptions(currencies)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">Reverse Fees</label>
          <div className="col-9">
            <div
              className="radio-inline"
              onChange={e => {
                company.settings.payment_plan.reverse_fee = e.target.value
                this.setState({ company })
              }}
            >
              <label className="radio">
                <input
                  type="radio"
                  name="reverseSetting"
                  value={"yes"}
                  checked={company.settings.payment_plan.reverse_fee === "yes"}
                />
                Yes
                <span></span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="reverseSetting"
                  value={"no"}
                  checked={company.settings.payment_plan.reverse_fee === "no"}
                />
                No
                <span></span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="reverseSetting"
                  value={"optional"}
                  checked={company.settings.payment_plan.reverse_fee === "optional"}
                />
                Optional
                <span></span>
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-3 col-form-label">*Custom SMS</label>
          <div className="col-9">
            <input
              className="form-control form-control-solid"
              type="text"
              placeholder=""
              value={company.settings.sms_from}
              onChange={e => {
                company.settings.sms_from = e.target.value
                this.setState({ company })
              }}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      company,
      loading,
      companyDetail,
      errorSlug
    } = this.state

    let title = "Update Company";
    let buttonTitle = 'Update';

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { this._renderContent() }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button type="button" className="btn btn-primary font-weight-bold" id="add-success" disabled={loading}
                  onClick={() => this._handleUpdateCompany()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
