import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Processors from "../../../../utils/Processors"

import UnconnectedProcessors from "./UnconnectedProcessors"

export default class ConnectProcessorModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: JSON.parse(JSON.stringify(props.company)),
    }
  }

  _renderContent(){
    let {
      show,
      company,
      loading,
      selectedProcessor,
      showConnectAibSettings
    } = this.state

    if(!selectedProcessor){
      return (
        <UnconnectedProcessors
          company={company}
          onSelected={processor => this.setState({ selectedProcessor: processor})}
          onHide={() => this.props.onHide()}
        />
      )
    }

    const ConnectProcessorComponent = Processors.getConnectComponent(selectedProcessor)
    if(ConnectProcessorComponent){
      return (
        <ConnectProcessorComponent
          company={company}
          onCancel={processor => this.setState({ selectedProcessor: null})}
          onUpdated={() => this.props.onUpdated()}
        />
      )
    }

    return null
  }

  render() {
    let {
      show,
      company,
      loading,
    } = this.state

    if(!company) return null

    return (
      <Modal
        show={show}
        onHide={() => {
          this.setState({ selectedProcessor: null})
          this.props.onHide()
        }}
        className={""}
        centered
        size="lg"
        dialogClassName={'c-modal connect-processor'}
      >

        <Modal.Header closeButton>
          <Modal.Title>Connect Processor</Modal.Title>
        </Modal.Header>

        { this._renderContent() }

      </Modal>
    )
  }
}
