import React from 'react'
import Modal from 'react-bootstrap/Modal'
// import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import ImageEdit from "../common/ImageEdit";
// import CountrySelect from "../common/CountrySelect";
import AsyncSelect from "../common/AsyncSelect";
import ColorPicker from "../common/ColorPicker";

export default class UpdateCompanyModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentDidMount() {
    this._getData()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps), () => {
      this._getData()
    })
  }

  _getState(props){
    return {
      ...props,
      companyProcessor: props.companyProcessor,
    }
  }

  _getData(){
    let {
      companyProcessor
    } = this.state

    if(!companyProcessor) return

    this.setState({ loading: true })
    Backend.getStripeData(companyProcessor)
    .then(data => {
      data.payout_schedule_interval = data.payout_schedule_interval || "daily"
      data.payout_schedule_delay_days = data.payout_schedule_delay_days || 0

      companyProcessor.data = {
        ...data,
        ...companyProcessor.data
      }

      this.setState({
        companyProcessor,
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let companyProcessor = this.state.companyProcessor
    companyProcessor.data[e.target.name] = e.target.value
    this.setState({ companyProcessor, error: null })
  }

  _update(){
    let {
      data,
      companyProcessor
    } = this.state

    this.setState({ loading: true })
    Backend.updateStripeData(companyProcessor)
    .then(data => {
      Notify.success("Updated Successfully")
      this.setState({ loading: false })
      this.props.onHide()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      show,
      companyProcessor,
      loading,
    } = this.state

    let title = "Update Stripe Settings";
    let buttonTitle = 'Update';

    if(!companyProcessor) return null

    let data = companyProcessor.data

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Stripe Settings</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-3 col-form-label">*Payouts Schedule</label>
            <div className="col-9">
              <select
                className="form-control form-control-solid"
                value={data.payout_schedule_interval || "daily"}
                onChange={e => {
                  let interval = e.target.value
                  companyProcessor.data.payout_schedule_interval = interval

                  if(interval === "manual"){
                    companyProcessor.data.payout_schedule_delay_days = null
                  }
                  this.setState({ companyProcessor })
                }}
              >
                <option value="manual">Never</option>
                <option value="daily">Daily</option>
              </select>
              <span className="validation-error-message">Setting the schedule to 'Never' prevents the account from receiving payouts</span>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">*Delay Days</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                value={data.payout_schedule_delay_days}
                disabled={data.payout_schedule_interval === "manual"}
                onChange={e => {
                  companyProcessor.data.payout_schedule_delay_days = parseInt(e.target.value)
                  this.setState({ companyProcessor })
                }}
              />
              <span className="validation-error-message">The number of days charges for the account will be held before being paid out.</span>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button type="button" className="btn btn-primary font-weight-bold" id="add-success" disabled={loading}
                  onClick={() => this._update()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
