import React from "react"

import BaseTable from "./BaseTable"

import moment from "moment"

import ConfirmationModal from "../modal/ConfirmationModal";
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import InvoicesTable from "./InvoicesTable";
import CustomerCell from "./cells/CustomerCell";
import Currency from "../../../utils/Currency";
import PaidCell from "./cells/PaidCell";
import ViewCell from "./cells/ViewCell";
import CheckCell from "./cells/CheckCell";
import DeleteCell from "./cells/DeleteCell";
import NudgeCell from "./cells/NudgeCell";
import PillCell from "./cells/PillCell";
import General from "../../../utils/General";
import SendCell from "./cells/SendCell";
import Splink from "../../../utils/Splink";
import RecurringCell from "./cells/RecurringCell";

export default class ProductsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Company',
        id: 'company.name',
        accessor: 'company.name',
        width: 160
      },
      {
        Header: 'Product',
        id: 'name',
        accessor: 'name',
        width: 160
      },
      {
        Header: 'Sales',
        id: 'sales_no',
        accessor: 'sales_no',
        width: 160
      },
      {
        Header: 'Price',
        id: 'payment_plans[0].total',
        sortable: false,
        Cell: rowInfo => {
          let product = rowInfo.original
          return Currency.format(product.payment_plans[0].currency.symbol, product.payment_plans[0].total)
        },
        width: 160
      },
      {
        Header: 'Recurring',
        id: 'payment_plans[0].type',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let product = rowInfo.original
          if(product.payment_plans[0].type === "recurring"){
            return (
              <RecurringCell/>
            )
          }
          return null
        },
        width: 160
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 250,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let product = rowInfo.original
          let url = Splink.getProductUrl(product.company, product)
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              <ViewCell
                tooltip={"View"}
                onViewClicked={() => {
                  window.open(url, '_blank')
                }}
              />
            </span>
          )
        }
      }
    ]
  }

  _cancelSubscription(){
    let {
      subscription
    } = this.state

    Backend.cancelSubscription(subscription).then(() => {
      Notify.success('Subscription has been canceled successfully')
      this.setState({
        subscription: null,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        subscription: null,
        showConfirmationModal: false
      })
    })
  }

  _renderToolBar(){

    return (
      <>

      </>
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={'Are you sure you want to cancel this subscription?'}
        onConfirm={() => this._cancelSubscription()}
        onHide={() => {
          this.setState({
            subscription: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  render() {
    let {

    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Products}`}
          noDataMessage={"No product found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderConfirmationModal() }
      </div>
    )
  }
}
