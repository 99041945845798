import FetchHelper from "./FetchHelper";
import AuthManager from "./AuthManager";
import Mode from "./Mode";

export default class Backend {
  static addRole(role) {
    return FetchHelper.post(window.Api.Roles, role);
  }

  static deleteRole(role) {
    return FetchHelper.delete(`${window.Api.Roles}/${role.id}`);
  }

  static updateRole(role) {
    return FetchHelper.patch(`${window.Api.Roles}/${role.id}`, role);
  }

  static updateCompanyPackage(company, data) {
    return FetchHelper.patch(
      `${window.Api.CompanySubscription}/${company.id}`,
      data
    );
  }

  static slugify(name) {
    return FetchHelper.post(
      `${window.Api.Splinks}/slugify`,
      { name },
      false,
      false
    );
  }

  static updateAccount(admin) {
    let data = JSON.parse(JSON.stringify(admin));
    delete data.user.email;
    return FetchHelper.patch(
      `${window.Api.Admins}/${AuthManager.currentUser.user.id}`,
      data
    );
  }

  static addCompany(company) {
    let data = JSON.parse(JSON.stringify(company));
    return FetchHelper.post(window.Api.Companies, data);
  }

  static getCompany(company) {
    return FetchHelper.get(`${window.Api.Companies}/${company.id}`);
  }

  static getCompanyById(companyId) {
    return FetchHelper.get(`${window.Api.Companies}/${companyId}`);
  }

  static updateCompany(company) {
    let data = JSON.parse(JSON.stringify(company));
    delete data.settings.company;
    data.description = data.description !== "" ? data.description : null;
    data.contact_name = data.contact_name ? data.contact_name : null;
    data.business_info = data.business_info ? data.business_info : null;
    data.settings.sms_from = data.settings.sms_from
      ? data.settings.sms_from
      : null;
    data.settings.logo = data.settings.logo ? data.settings.logo.id : null;
    data.settings.logo_banner = data.settings.logo_banner
      ? data.settings.logo_banner.id
      : null;

    data.settings.background = data.settings.background
      ? data.settings.background.id
      : null;
    data.settings.payment_plan.currency = data.settings.payment_plan.currency
      ? data.settings.payment_plan.currency.id
      : null;
    data.settings.payment_plan.frequency =
      data.settings.payment_plan.type === "one_time"
        ? null
        : data.settings.payment_plan.frequency;
    data.settings.payment_plan.installments =
      data.settings.payment_plan.type === "one_time"
        ? null
        : data.settings.payment_plan.installments;
    data.settings.payment_success_email_subject = data.settings
      .payment_success_email_subject
      ? data.settings.payment_success_email_subject
      : null;
    data.settings.payment_success_email_body = data.settings
      .payment_success_email_body
      ? data.settings.payment_success_email_body
      : null;

    if (data.settings.address) {
      delete data.settings.address.latitude;
      delete data.settings.address.longitude;
    }

    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data);
  }

  static verifyCompany(company) {
    company.settings.live = true;

    let data = {
      settings: {
        live: true,
      },
    };

    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data);
  }

  static uploadImage(image, type = "favicon") {
    let data = new FormData();

    data.append("file", image, image.name);
    data.append("type", type);

    return FetchHelper.post(window.Api.Images, data, true);
  }

  static getSignUpStats(dateFrom, dateTo) {
    return Backend.getStats("sign_ups", dateFrom, dateTo);
  }

  static getDemoAccountsStats(dateFrom, dateTo) {
    return Backend.getStats("demo_accounts", dateFrom, dateTo);
  }

  static getAssociationStats(dateFrom, dateTo) {
    return Backend.getStats("associations", dateFrom, dateTo);
  }

  static getPaymentRequestStats(dateFrom, dateTo) {
    return Backend.getStats("payment_requests", dateFrom, dateTo);
  }

  static getPaymentStats(dateFrom, dateTo) {
    return Backend.getStats("processed", dateFrom, dateTo);
  }

  static getRecurringStats(dateFrom, dateTo) {
    return Backend.getStats("recurring", dateFrom, dateTo);
  }

  static getPaymentBreakdownStats(dateFrom, dateTo) {
    return Backend.getStats("payment_breakdown", dateFrom, dateTo);
  }

  static getMonthlyPaymentStats(dateFrom, dateTo) {
    return Backend.getStats("monthly_transaction", dateFrom, dateTo);
  }

  static getProcesseedStats(dateFrom, dateTo) {
    return Backend.getStats("processed", dateFrom, dateTo);
  }

  static getShopStats(dateFrom, dateTo) {
    return Backend.getStats("shop", dateFrom, dateTo);
  }

  static getChannelsStats(dateFrom, dateTo) {
    return Backend.getStats("payment_links", dateFrom, dateTo);
  }

  static getStats(mode, dateFrom = "", dateTo = "") {
    let url = `${window.Api.Stats}?&min_date=${dateFrom}&max_date=${dateTo}&${mode}=true`;
    if (
      AuthManager.currentUser.user.role === "admin" &&
      !window.General.DefaultAssociation
    ) {
      url += "&admin=true";
    } else {
      url += "&association=true";
    }
    return FetchHelper.get(url);
  }

  static deletePayment(payment) {
    return FetchHelper.delete(`${window.Api.Payments}/${payment.id}`);
  }

  static cancelSubscription(subscription) {
    return FetchHelper.delete(
      `${window.Api.Subscriptions}/${subscription.initial_payment.id}`
    );
  }

  static resendPaymentReceipt(payment, email){
    let data = {payment: payment.id}
    if(email){
      data.customer_email = email
    }
    return FetchHelper.post(`${window.Api.Receipts}`, data)
  }

  static deleteMember(member) {
    return FetchHelper.delete(`${window.Api.Admins}/${member.user.id}`);
  }

  static resendPaymentRequest(paymentRequest) {
    let data = { payment_request: paymentRequest.id };
    return FetchHelper.post(`${window.Api.Reminders}`, data);
  }

  static deletePaymentRequest(paymentRequest) {
    return FetchHelper.delete(
      `${window.Api.PaymentRequests}/${paymentRequest.id}`
    );
  }

  static verifyPaymentRequest(paymentRequest) {
    let data = { paid: true };
    return FetchHelper.patch(
      `${window.Api.PaymentRequests}/${paymentRequest.id}`,
      data
    );
  }

  static addImage(data) {
    data.type = "photo";
    return FetchHelper.post(window.Api.Images, data);
  }

  static getAssociation(id) {
    return FetchHelper.get(`${window.Api.Associations}/${id}`);
  }

  static addAssociation(association) {
    let data = Backend.parseAssociation(association);
    data._raw = association;
    return FetchHelper.post(window.Api.Associations, data);
  }

  static updateAssociation(association) {
    let data = Backend.parseAssociation(association);
    return FetchHelper.patch(
      `${window.Api.Associations}/${association.id}`,
      data
    );
  }

  static deleteAssociation(association) {
    return FetchHelper.delete(`${window.Api.Associations}/${association.id}`);
  }

  static parseAssociation(association) {
    let data = JSON.parse(JSON.stringify(association));
    data.favicon = data.favicon?.id;
    data.logo_small = data.logo_small?.id;
    data.logo_banner = data.logo_banner?.id;
    data.currency = data.currency?.id;
    data.default_live_processor = data.default_live_processor?.id || null;
    data.default_demo_processor = data.default_demo_processor?.id || null;

    console.log("ddddd", association);
    let urlKeys = ["terms_url", "support_url", "not_found_url", "add_ons_url"];
    for (var i = 0; i < urlKeys.length; i++) {
      let key = urlKeys[i];

      if (data[key] && data[key].indexOf("https://") === -1) {
        data[key] = "https://" + data[key];
      } else if (!data[key]) {
        data[key] = null;
      }
    }

    let gtmKeys = ["client_gtm_id", "payment_gtm_id"];
    for (var i = 0; i < gtmKeys.length; i++) {
      let key = gtmKeys[i];

      if (!data[key]) {
        data[key] = null;
      }
    }

    data.companies = data.companies?.map((company) => {
      company.demo_data.payments = company.demo_data.payments.map((payment) => {
        payment.payment_plan.currency = payment.payment_plan.currency.id;
        return payment;
      });

      company.demo_data.payment_requests =
        company.demo_data.payment_requests.map((paymentRequest) => {
          paymentRequest.payment_plans = Backend.parsePaymentPlansData(
            paymentRequest.payment_plans
          );
          return paymentRequest;
        });

      company.demo_data.payment_links = company.demo_data.payment_links.map(
        (paymentLink) => {
          paymentLink.background_image = paymentLink.background_image?.id || null

          paymentLink.payment_plans = Backend.parsePaymentPlansData(
            paymentLink.payment_plans
          );

          paymentLink.payments = paymentLink.payments.map((payment) => {
            payment.payment_plan = Backend.parsePaymentPlanData(
              payment.payment_plan
            );
            return payment;
          });
          return paymentLink;
        }
      );

      company.demo_data.products = company.demo_data.products.map((product) => {
        product.featured_image = product.featured_image?.id || null

        product.payment_plans = Backend.parsePaymentPlansData(
          product.payment_plans
        );

        product.payments = product.payments.map((payment) => {
          payment.payment_plan = Backend.parsePaymentPlanData(
            payment.payment_plan
          );
          return payment;
        });

        return product;
      });

      return company;
    });

    return data;
  }

  static parseDemoPaymentsData(payments) {
    let data = JSON.parse(JSON.stringify(payments));

    return payments.map((payment) => {
      payment.payment_plan = Backend.parsePaymentPlanData(payment.payment_plan);
      return payment;
    });
  }

  static parsePaymentPlansData(paymentPlans) {
    let data = JSON.parse(JSON.stringify(paymentPlans));

    paymentPlans = paymentPlans.map((paymentPlan) => {
      return Backend.parsePaymentPlanData(paymentPlan);
    });

    return paymentPlans;
  }

  static parsePaymentPlanData(paymentPlan) {
    let data = JSON.parse(JSON.stringify(paymentPlan));

    data.currency = data.currency.id;
    data.installments = data.installments === "null" ? null : data.installments;

    return data;
  }

  static getPaymentAttempt(id) {
    return FetchHelper.get(`${window.Api.PaymentAttempts}/${id}`);
  }

  static addMember(member) {
    let data = JSON.parse(JSON.stringify(member));
    return FetchHelper.post(window.Api.Admins, data);
  }

  static addAssociate(associate) {
    let data = JSON.parse(JSON.stringify(associate));
    return FetchHelper.post(window.Api.Associates, data);
  }

  static updateMember(member) {
    let data = JSON.parse(JSON.stringify(member));
    return FetchHelper.patch(`${window.Api.Admins}/${member.user.id}`, data);
  }

  static updateAssociate(associate) {
    let data = JSON.parse(JSON.stringify(associate));
    return FetchHelper.patch(
      `${window.Api.Associates}/${associate.user.id}`,
      data
    );
  }

  static updateCompanyMember(member) {
    let data = JSON.parse(JSON.stringify(member));
    data.user.address = data.user.adress?.id || null;
    return FetchHelper.patch(
      `${window.Api.CompanyMembers}/${member.user.id}`,
      data
    );
  }

  static _parsePackage(packageObject) {
    packageObject.currency = packageObject.currency
      ? packageObject.currency.id
      : null;
    packageObject.background = packageObject.background
      ? packageObject.background.id
      : null;
    packageObject.foreground = packageObject.foreground
      ? packageObject.foreground.id
      : null;
    packageObject.association = packageObject.association
      ? packageObject.association.id
      : null;

    packageObject.description = packageObject.description || null;

    packageObject.benefits = packageObject.benefits.map((benefit) => {
      benefit.subtitle = benefit.subtitle || null;
      return benefit;
    });

    return packageObject;
  }

  static createPackage(packageObject) {
    let data = JSON.parse(JSON.stringify(packageObject));
    data = Backend._parsePackage(data);
    return FetchHelper.post(window.Api.Packages, data);
  }

  static updatePackage(packageObject) {
    let data = JSON.parse(JSON.stringify(packageObject));
    data = Backend._parsePackage(data);
    return FetchHelper.patch(
      `${window.Api.Packages}/${packageObject.id}`,
      data
    );
  }

  static deletePackage(packageObject) {
    return FetchHelper.delete(`${window.Api.Packages}/${packageObject.id}`);
  }

  static getStripeData(companyProcessor) {
    return FetchHelper.get(
      `${window.Api.Connect}/${companyProcessor.processor_slug}?company_id=${companyProcessor.company}`
    );
  }

  static updateStripeData(companyProcessor) {
    let data = this._parseStripeConnect(companyProcessor.data);
    return FetchHelper.post(
      `${window.Api.Connect}/${companyProcessor.processor_slug}?company_id=${companyProcessor.company}`,
      data
    );
  }

  static _parseStripeConnect(data) {
    data = JSON.parse(JSON.stringify(data));

    data.representative = this._getPersonData(data.representative);
    if (data.business_type === "individual") {
      delete data.representative.id;
    }

    for (var i = 0; i < data.persons; i++) {
      data.persons[i] = this._getPersonData(data.persons[i]);
    }

    return data;
  }

  static _getPersonData(data) {
    let person = { ...data };
    let { verification } = person;

    if (!verification) {
      return data;
    } else {
      delete person.verification;
      return person;
    }

    if (verification.document) {
      if (!verification.document.front_updated) {
        delete person.verification.document.front;
      }
      if (!verification.document.back_updated) {
        delete person.verification.document.back;
      }
    }
    if (verification.additional_document) {
      if (!verification.additional_document.front_updated) {
        delete person.verification.additional_document.front;
      }
      if (!verification.additional_document.back_updated) {
        delete person.verification.additional_document.back;
      }
    }
    delete verification.document.front_updated;
    delete verification.document.back_updated;
    delete verification.additional_document.front_updated;
    delete verification.additional_document.back_updated;

    return person;
  }

  static getBranding() {
    let url = window.location.hostname;
    return FetchHelper.get(`${window.Api.Branding}?website_url=${url}`, false);
  }

  static deleteDomain(domain) {
    return FetchHelper.delete(`${window.Api.Domains}/${domain.id}`);
  }

  static getDNS(domain) {
    return FetchHelper.get(`${window.Api.Domains}/${domain.id}/dns`);
  }

  static transferDomain(name) {
    let data = {
      name,
    };
    return FetchHelper.post(window.Api.Domains, data);
  }

  static activationCheck(domain) {
    return FetchHelper.post(
      `${window.Api.Domains}/${domain.id}/activation-check`,
      {}
    );
  }

  static updateDNS(domain, data) {
    data = JSON.parse(JSON.stringify(data));
    if (data.priority) {
      data.priority = parseInt(data.priority);
    }
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/dns`, data);
  }

  static deleteRecord(domain, data) {
    return FetchHelper.delete(`${window.Api.Domains}/${domain.id}/dns`, data);
  }

  static addGmailRecords(domain) {
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/gmail`);
  }

  static updateDefaultProcessor(companyProcessor) {
    return FetchHelper.patch(
      `${window.Api.CompanyProcessors}/${companyProcessor.id}`,
      { default: true, rate: companyProcessor.rate }
    );
  }

  static connectToAib(company, aibConnectData) {
    let slug = company.settings.live ? "aib" : "aib_test";
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      aibConnectData
    );
  }

  static connectToBoipa(company, boipaConnectData) {
    let slug = company.settings.live ? "boipa" : "boipa_test";
    return FetchHelper.post(
        `${window.Api.Connect}/${slug}?company_id=${company.id}`,
        boipaConnectData
    );
  }

  static connectToEMerchantPay(company, eMerchantPayConnectData) {
    let slug = company.settings.live ? "emerchantpay" : "emerchantpay_test";

    eMerchantPayConnectData = JSON.parse(
      JSON.stringify(eMerchantPayConnectData)
    );

    let terminals = {};
    for (const [key, terminal] of Object.entries(
      eMerchantPayConnectData.terminals
    )) {
      if (terminal.no_3d || terminal["3d"] || terminal.no_cvv) {
        terminals[key] = terminal;
      }
    }
    eMerchantPayConnectData.terminals = terminals;
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      eMerchantPayConnectData
    );
  }

  static connectToCardConnect(company, data) {
    let slug = company.settings.live ? "card_connect" : "card_connect_test";
    data.apple_pay_merchant_id = data.apple_pay_merchant_id || null;
    data.apple_pay_domain_verification =
      data.apple_pay_domain_verification || null;
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      data
    );
  }

  static connectToApaylo(company, data) {
    let slug = company.settings.live ? "apaylo" : "apaylo_test";
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      data
    );
  }

  static connectToTSYS(company, data) {
    let slug = company.settings.live ? "tsys" : "tsys_test";
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      data
    );
  }

  static connectToToken(company, data) {
    let slug = company.settings.live ? "token" : "token_test";
    return FetchHelper.post(
      `${window.Api.Connect}/${slug}?company_id=${company.id}`,
      data
    );
  }

  static getIncompleteAssociationTasks() {
    return FetchHelper.get(
      `${window.Api.AsyncTasks}?type=create_association&complete=false&paginated=false`
    );
  }

  static getAsyncTask(id) {
    return FetchHelper.get(`${window.Api.AsyncTasks}/${id}`);
  }

  static deleteAsyncTask(asyncTask) {
    return FetchHelper.delete(`${window.Api.AsyncTasks}/${asyncTask.id}`);
  }

  static getSecretKey() {
    return FetchHelper.get(`${window.Api.SecretKey}`);
  }

  static createSecretKey() {
    return FetchHelper.post(`${window.Api.SecretKey}`);
  }

  static rotateSecretKey() {
    return FetchHelper.patch(`${window.Api.SecretKey}`);
  }

  static createWebhook(data) {
    return FetchHelper.post(`${window.Api.Webhooks}`, data);
  }

  static updateWebhook(webhook) {
    return FetchHelper.patch(`${window.Api.Webhooks}/${webhook.id}`, webhook);
  }

  static deleteWebhook(webhook) {
    return FetchHelper.delete(`${window.Api.Webhooks}/${webhook.id}`);
  }

  static getPaymentMethods(company, type){
    return FetchHelper.get(`${window.Api.PaymentMethods}?company_id=${company.id}&type=${type}`)
    .then(response => response.results)
  }

  static updatePaymentMethod(paymentMethod){
    return FetchHelper.patch(`${window.Api.PaymentMethods}/${paymentMethod.id}`, paymentMethod);
  }

  static getProcessors(){
    return FetchHelper.get(`${window.Api.Processors}`)
    .then(response => response.results)
  }

  static getPackage(id){
    return FetchHelper.get(`${window.Api.Packages}/${id}`)
  }
}
