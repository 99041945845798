import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

import AuthManager from '../../../utils/AuthManager'

export default class DomainEngagementCard extends React.Component {

  render(){

    let domain = window.General.Branding.Domain

    return (
      <EngagementCard
        { ...this.props }
        title="What Domain Name?"
        contentContainerClassName="col-12"
        renderContent={() => {
          return (
            <>
              <p>You have 2 options to choose from.</p>
              <p>01 - Let your clients use our free domain - myclient.{domain}
              <br/>02 - If you have a domain, your clients can use that - myclient.mydomain.com
              </p>
            </>
          )
        }}
      />
    )
  }
}
