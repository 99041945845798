import React from "react";

import { ChromePicker } from 'react-color';

const SIZE = 50
export default class SwatchColorPicker extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      color: props.color,
      displaySwatchColorPicker: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      color
    } = this.state

    let displayColor = color

    if(displayColor && displayColor.charAt(0) !== '#'){
      displayColor = JSON.parse(displayColor)
      displayColor = `rgb(${displayColor.r}, ${displayColor.g}, ${displayColor.b}, ${displayColor.a})`
    }

    return (
      <div className="react-color v-swatch">

        <div
          className={ `color-box  ${this.props.colorBoxClassName}`}
          style={{
            ...this.props.colorBoxStyle
          }}
        >
          <a
            href="javascript:void(0)"
            onClick={() => {
              this.setState({ displaySwatchColorPicker: true })
            }}
          >
            <div
              style={{
                backgroundColor: this.state.color
              }}
              className="color"
            >
              &nbsp;
            </div>
            <div className="text">
              { displayColor }
            </div>
          </a>

          { this._renderSwatchColorPickerModal()}
        </div>


      </div>
    )
    
  }

  _renderSwatchColorPickerModal(){
    let {
      color,
      displaySwatchColorPicker
    } = this.state

    if(!displaySwatchColorPicker){
      return null
    }

    return (
      <div className="pop">
        <div 
          className="mask"
          onClick={() => {
             this.setState({ displaySwatchColorPicker: false })
          } }
        />
        <ChromePicker
          color={color || "#FFF"}
          onChange={updatedColor => {
            this.props.onColorChange(updatedColor)
          }}
        />
      </div>
    )
  }

}

const styles = {
  transparent: {
    backgroundImage: 'linear-gradient(45deg, #e5e5e5 25%, transparent 25%), linear-gradient(135deg, #e5e5e5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e5e5e5 75%), linear-gradient(135deg, transparent 75%, #e5e5e5 75%)',
    backgroundSize: '10px 10px',
    backgroundPosition: '0 0, 5px 0, 5px -5px, 0px 5px',
    backgroundColor: '#f5f5f5'
  }
}

SwatchColorPicker.defaultProps = {
  colorBoxClassName: "",
  colorBoxStyle: {}
}