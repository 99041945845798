import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import ImageEdit from "../common/ImageEdit";
// import CountrySelect from "../common/CountrySelect";
import AsyncSelect from "../common/AsyncSelect";
import ColorPicker from "../common/ColorPicker";

export default class AddOnModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }

  }

  componentDidMount() {
    this._getobject()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps), () => {
      this._getobject()
    })
  }

  _getState(props){
    return {
      ...props,
      object: props.company || props.packageObject,
    }
  }

  _getobject(){
    let {
      object
    } = this.state

    if(!object.company_processors) return

    Backend.getCompany(object).then(objectDetail => {
      this.setState({
        object: objectDetail,
        objectDetail
      })
    })
  }

  _handleUpdateObject(){
    if(this.props.company){
      this._updateCompany()
    }else{
      this._updatePackage()
    }
  }

  _updateCompany(){
    let {
      object
    } = this.state

    this.setState({ loading: true })
    Backend.updateCompany(object)
    .then(object => {
      Notify.success("Add-ons Updated Successfully")
      this.setState({ loading: false })
      this.props.onAdded(object)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updatePackage(){
    let {
      object
    } = this.state

    this.setState({ loading: true })
    Backend.updatePackage(object)
      .then(object => {
        Notify.success("Package Updated Successfully")
        this.setState({ loading: false })
        this.props.onAdded(object)
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      show,
      loading,
      object,
      objectDetail,
    } = this.state

    let title = "Update Add-ons";
    let buttonTitle = 'Update';

    if(!object.add_ons) return null
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={""}
        dialogClassName={"modal-dialog modal-dialog-centered modal-lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="row">
            { ADD_ONS.map(addOn => {
                let { label, key, type, disabled } = addOn
                return (
                  <div className="col-6">
                    <div className={`form-group row ${disabled ? "disabled" : ""}`}>
                      <label className="col-8 col-form-label">{label}</label>
                      { (type === "checkbox") ?
                        (
                          <div className="col-4">
                            <span className="switch switch-icon">
                              <label>
                                <input
                                  type={type}
                                  checked={object.add_ons[key] && !disabled}
                                  disabled={disabled}
                                  onChange={e => {
                                    object.add_ons[key] = e.target.checked
                                    this.setState({object})
                                  }}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        )
                        :
                        (
                          <div className="col-3">
                            <input
                              type="number"
                              className="form-control form-control-solid"
                              value={object.add_ons[key]}
                              onChange={e => {
                                object.add_ons[key] = e.target.value
                                this.setState({object})
                              }}
                            />
                          </div>
                        )
                    }
                    </div>
                  </div>
                )
              })
            }
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button type="button" className="btn btn-primary font-weight-bold" id="add-success" disabled={loading}
                  onClick={() => this._handleUpdateObject()}
          >
            <span className="svg-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000" fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}


const ADD_ONS = [
  {
    label: "Payment Dashboard",
    key: "payment_dashboard",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Reverse Fees",
    key: "reverse_fee",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Requests",
    key: "payment_requests",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom URL",
    key: "custom_url",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Take Payment Link",
    key: "payment_link",
    type: "checkbox",
    disabled: false
  },
  {
    label: "IBAN/SEPA Payments",
    key: "sepa",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Channels",
    key: "payment_links",
    type: "checkbox",
    disabled: false
  },
  {
    label: "API Access",
    key: "api",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Attempts",
    key: "payment_attempts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Notifications",
    key: "payment_notifications",
    type: "checkbox",
    disabled: false
  },
  {
    label: "CRM Integrations",
    key: "crm",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Recurring Payments",
    key: "recurring_payments",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Payment Methods",
    key: "digital_wallet",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Reminders",
    key: "reminder_schedule",
    type: "checkbox",
    disabled: true
  },
  {
    label: "NFC",
    key: "nfc",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Mobile App",
    key: "mobile_app",
    type: "checkbox",
    disabled: false
  },

  {
    label: "Payment Redirect",
    key: "payment_redirect",
    type: "checkbox",
    disabled: false
  },
  {
    label: "e-Commerce Checkout Links",
    key: "shop",
    type: "checkbox",
    disabled: false
  },
  {
    label: "e-Commerce Checkout Layout",
    key: "shop_layout",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Email Reply",
    key: "custom_emails",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Website Pay Widget",
    key: "website_pay_widget",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Additional Page Design Templates",
    key: "splink_layouts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Bulk Payment Requests",
    key: "bulk_payment_requests",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Attach Invoice To PR",
    key: "payment_request_attachment",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Custom Data Capture",
    key: "questions",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Nudge",
    key: "payment_request_nudge",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Fundraising Progress Bar",
    key: "payment_progress_bar",
    type: "checkbox",
    disabled: true
  },
  {
    label: "Export",
    key: "export",
    type: "checkbox",
    disabled: false
  },
  {
    label: "QR Codes",
    key: "qr_codes",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Cash Discounting",
    key: "discounts",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Easy Mode",
    key: "easy_mode",
    type: "checkbox",
    disabled: false
  },
  {
    label: "BIN Lookups",
    key: "bin_lookups",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Team Members",
    key: "team_members",
    type: "checkbox",
    disabled: false
  },
  {
    label: "Additional Team Members",
    key: "additional_team_members",
    type: "number",
    disabled: false
  },
  {
    label: "Team Roles",
    key: "roles",
    type: "checkbox",
    disabled: false
  },
  {
    label: "SMS Limit",
    key: "sms_limit",
    type: "number",
    disabled: false
  },
]
