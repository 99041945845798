import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from '../common/PhoneInput'
import PasswordInput from '../common/PasswordInput'
import AsyncSelect from "../common/AsyncSelect"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Notify from '../../../utils/Notify'
import AuthManager from '../../../utils/AuthManager'
import Phone from '../../../utils/Phone'

export default class AssociateModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      member: props.member || { user: {} },
      phone: props.member?.user.phone_country_code
        ? `${props.member.user.phone_country_code}${props.member.user.phone_number}`
        : Phone.getDefaultCountryCode(),
    }
  }

  _handlePhoneUpdate(value, data) {
    let { member } = this.state

    member.user.phone_country_code = `+${data.dialCode}`
    member.user.phone_number = value.slice(data.dialCode.length)
    this.setState({
      phone: value,
      member,
    })
  }

  _isFormValid() {
    let { id, first_name, last_name, email } = this.state.member.user

    let error = null

    if (first_name == null || first_name.length === 0) {
      error = 'Please enter a first name'
    } else if (last_name == null || last_name.length === 0) {
      error = 'Please enter a last name'
    } else if (!this.state.member.role) {
      error = 'Please select a role'
    } else if (email == null || email.length === 0) {
      error = 'Please enter an email'
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddMember() {
    if (!this._isFormValid()) {
      return
    }
    if (this.state.member.user.id) {
      this._updateMember()
    } else {
      this._addMember()
    }
  }

  _addMember() {
    let { member } = this.state

    let data = General.clone(member)
    this.setState({ loading: true })
    Backend.addAssociate(member)
    .then((member) => {
      Notify.success('Member Added Successfully')
      this.setState({ loading: false })
      this.props.onAdded(member)
    })
    .catch((error) => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _updateMember() {
    let { member } = this.state

    this.setState({ loading: true })
    Backend.updateAssociate(member)
    .then((member) => {
      Notify.success('Member Updated Successfully')
      this.setState({ loading: false })
      this.props.onAdded(member)
    })
    .catch((error) => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let { show, loading, company, phone, error, member } = this.state

    let title = member.user.id ? 'Update Member' : 'Add Member'
    let buttonTitle = member.user.id ? 'Update Member' : 'Add Member'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error && (
            <div className="form-group">
              <span className="error-message">{error}</span>
            </div>
          )}

          <div className="form-group">
            <input
              type="text"
              name="first_name"
              placeholder={'First Name'}
              className="form-control form-control-solid"
              value={member.user.first_name}
              onChange={(e) => {
                member['user'][e.target.name] = e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="last_name"
              placeholder={'Last Name'}
              className="form-control form-control-solid"
              value={member.user.last_name}
              onChange={(e) => {
                member['user'][e.target.name] = e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          <div className="form-group">
            <PhoneInput
              className="form-control form-control-solid"
              value={phone}
              onChange={(value, data) => this._handlePhoneUpdate(value, data)}
              inputProps={{ autocomplete: 'off' }}
            />
          </div>

          <div className="form-group">
            <div className="select-wrapper">
              <select
                className="form-control form-control-solid"
                name="role"
                value={member.role}
                onChange={(e) => {
                  member[e.target.name] = e.target.value
                  this.setState({ member, error: null })
                }}
              >
                <option value="">Select A Role</option>
                <option value="admin">Admin</option>
                <option value="staff">Staff</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              name="email"
              placeholder={'Email'}
              className="form-control form-control-solid"
              value={member.user.email}
              onChange={(e) => {
                member['user'][e.target.name] = e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          { !member.user.id &&
            <PasswordInput
              label={false}
              containerClassName="form-group"
              className="form-control form-control-solid form-control-lg"
              value={member.user.password}
              onChange={(value, name) => {
                member['user'][name] = value
                this.setState({ company, error: null })
              }}
              autoComplete="off"
              showValidationCheck
            />
          }
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddMember()}
          >
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                  ></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
