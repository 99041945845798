import React, { Component } from "react";

import General from "../utils/General";

import DateRange from "./components/common/DateRange";

import PaymentAttemptsCards from "./components/payment-attempts/PaymentAttemptsList";
import PaymentAttemptDetail from "./components/payment-attempts/PaymentAttemptDetail";

import AsyncSelect from './components/common/AsyncSelect'

const PAYMENT_ATTEMPT_STATUS_ALL = {
  label: "All",
  value:
    "complete,3ds_challenge,3ds_verified,form_validation_failed,charge_failed",
};

const PAYMENT_ATTEMPT_STATUS_INCOMPLETE = {
  label: "Incomplete",
  value: "3ds_challenge,3ds_verified",
};

const PAYMENT_ATTEMPT_STATUS_FAILED = {
  label: "Failed",
  value: "form_validation_failed,charge_failed",
};

const PAYMENT_ATTEMPT_STATUS_COMPLETED = {
  label: "Succeeded",
  value: "complete",
};

const PAYMENT_ATTEMPT_STATUSES = [
  PAYMENT_ATTEMPT_STATUS_ALL,
  PAYMENT_ATTEMPT_STATUS_INCOMPLETE,
  PAYMENT_ATTEMPT_STATUS_FAILED,
  PAYMENT_ATTEMPT_STATUS_COMPLETED,
];

export default class PaymentAttempts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: null,
      dateTo: null,
      searchTerm: "",
      selectedPaymentAttempt: null,
      paymentAttemptStatus: PAYMENT_ATTEMPT_STATUS_ALL,
    };
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
        selectedPaymentAttempt: null
      });
    },
    1000,
    false
  );

  _handleStatusChange(event) {
    let { paymentAttemptStatus } = this.state;

    switch (event.target.value) {
      case PAYMENT_ATTEMPT_STATUS_ALL.value:
        paymentAttemptStatus = PAYMENT_ATTEMPT_STATUS_ALL;
        break;
      case PAYMENT_ATTEMPT_STATUS_INCOMPLETE.value:
        paymentAttemptStatus = PAYMENT_ATTEMPT_STATUS_INCOMPLETE;
        break;
      case PAYMENT_ATTEMPT_STATUS_FAILED.value:
        paymentAttemptStatus = PAYMENT_ATTEMPT_STATUS_FAILED;
        break;
      case PAYMENT_ATTEMPT_STATUS_COMPLETED.value:
        paymentAttemptStatus = PAYMENT_ATTEMPT_STATUS_COMPLETED;
        break;
    }
    this.setState({
      paymentAttemptStatus,
      paymentAttempt: null
    });
  }

  render() {
    const {
      searchTerm,
      dateFrom,
      dateTo,
      company,
      paymentAttemptStatus,
      selectedPaymentAttempt,
    } = this.state;

    return (
      <div className="page payment-attempts">
        <div className="row">
          <div className="col-md-7 c-table-attempts">
            <DateRange
              width="full"
              dateFrom={dateFrom}
              dateTo={dateTo}
              onFromDateUpdated={(dateFrom) => {
                this.setState({
                  dateFrom,
                  selectedPaymentAttempt: null
                });
              }}
              onToDateUpdated={(dateTo) => {
                this.setState({
                  dateTo,
                  selectedPaymentAttempt: null
                });
              }}
            />
            <div className="card card-custom">
              <div className="card-header flex-wrap border-0 pt-6 pb-0">
                <div className="card-title">
                  <h3 className="card-label">Payment Attempts</h3>
                </div>
              </div>
              <div className="card-body">
                <div className="row mb-7">
                  <div className="col-md-12">
                    <div className="dataTables_filter">
                      <div className="row">
                        <div className="col">
                          <div className="input-icon">
                            <input
                              type="text"
                              id="kt_datatable_search_query"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) =>
                                this._updateSearchTerm(e.target.value)
                              }
                            />
                            <span>
                              <i className="flaticon2-search-1 text-muted" />
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">
                              Company:
                            </label>
                            <div className="w-100">
                              <AsyncSelect
                                endpoint={window.Api.Companies}
                                className={'custom-async-select__container'}
                                classNamePrefix={'custom-async-select'}
                                params={{ }}
                                isClearable={true}
                                value={General.getSingleAsyncOption(company)}
                                onSelected={company => {
                                  this.setState({ company, selectedPaymentAttempt: null })
                                }}
                                getOptions={companies => General.getAsyncOptions(companies)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">
                              Status:
                            </label>
                            <div className="select-wrapper w-100">
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  this._handleStatusChange(e);
                                }}
                              >
                                {PAYMENT_ATTEMPT_STATUSES.map(
                                  (paymentAttemptStatus) => {
                                    return (
                                      <option
                                        label={paymentAttemptStatus.label}
                                        value={paymentAttemptStatus.value || ""}
                                      >
                                        {paymentAttemptStatus.label}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ReactTable">
                  <div className="rt-table" role="grid">
                    <div
                      className="rt-thead -header"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
                        paddingTop: 10,
                        paddingBottom: 10,
                        textAlign: "left",
                        minWidth: 530,
                      }}
                    >
                      <div className="rt-tr" role="row">
                        <div
                          className="rt-th"
                          role="columnheader"
                          tabIndex={-1}
                          style={{
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                        >
                          <div className="rt-resizable-header-content">
                            Status
                          </div>
                          <div className="rt-resizer" />
                        </div>
                        <div
                          className="rt-th"
                          role="columnheader"
                          tabIndex={-1}
                          style={{
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                        >
                          <div className="rt-resizable-header-content">
                            Customer
                          </div>
                          <div className="rt-resizer" />
                        </div>
                        <div
                          className="rt-th"
                          role="columnheader"
                          tabIndex={-1}
                          style={{
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                        >
                          <div className="rt-resizable-header-content">
                            Amount
                          </div>
                          <div className="rt-resizer" />
                        </div>
                        <div
                          className="rt-th"
                          role="columnheader"
                          tabIndex={-1}
                          style={{
                            textAlign: "left",
                            paddingLeft: 10,
                          }}
                        >
                          <div className="rt-resizable-header-content">
                            Type
                          </div>
                          <div className="rt-resizer" />
                        </div>
                        <div
                          className="rt-th"
                          role="columnheader"
                          tabIndex={-1}
                          style={{
                            textAlign: "left",
                            paddingLeft: 5,
                          }}
                        >
                          <div className="rt-resizable-header-content">
                            Date
                          </div>
                          <div className="rt-resizer" />
                        </div>
                      </div>
                      <div className="separator separator-solid my-0" />
                    </div>

                    <div
                      className="rt-tbody"
                      style={{
                        minWidth: 530,
                      }}
                    >
                      <PaymentAttemptsCards
                        searchTerm={searchTerm}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        company={company}
                        paymentAttemptStatus={paymentAttemptStatus}
                        selectedPaymentAttempt={selectedPaymentAttempt}
                        onClick={(paymentAttempt) =>
                          this.setState({
                            selectedPaymentAttempt: paymentAttempt,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 c-details">
            <PaymentAttemptDetail
              paymentAttemptId={selectedPaymentAttempt?.id}
            />
          </div>
        </div>
      </div>
    );
  }
}
