import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify"

import Select from "../../common/CustomSelect";


const FIELDS = [
  {
    key: "mid",
    label: "Merchant ID"
  },
  {
    key: "username",
    label: "Username"
  },
  {
    key: "password",
    label: "Password"
  },
  {
    key: "developer_id",
    label: "Developer ID"
  },
  {
    key: "tsep_device_id",
    label: "TSEP Device ID"
  },
  {
    key: "multipass_device_id",
    label: "MultiPASS Device ID"
  },
]

const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  },
]

export default class TSYSConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      company: props.company,
      data: props.data ||  {
        mid: "",
        username: "",
        password: "",
        developer_id: "",
        tsep_device_id: "",
        multipass_device_id: "",
        billing_address_required: false
      },
    }
  }

  _isFormValid(){
    let { data } = this.state

    let error = null

    for(var i=0; i < FIELDS.length; i++){
      let field = FIELDS[i]
      if(!data[field.key]){
        error = `Please provide a valid ${field.label}`
        break
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _connect(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectToTSYS(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
            { FIELDS.map(field => {
                return (
                  <div className="form-group row">

                    <label className="col-3 col-form-label">{field.label}</label>
                    <div className="col-9">
                      <input
                          className="form-control form-control-solid"
                          type="text"
                          value={data[field.key]}
                          onChange={e => {
                            data[field.key] = e.target.value
                            this.setState({ data })
                          }}
                      />
                    </div>
                  </div>
                )
              })
            }

            <div className="form-group row">
              <label className="col-3 col-form-label">Billing Address Required</label>
              <div className="col-9">
                <Select
                  value={YES_NO_OPTIONS.find(option => option.value == data.billing_address_required)}
                  className="form-control h-auto border-0 form-control-solid c-selectbox"
                  name="billing_address_required"
                  placeholder="Please Select A Value"
                  onChange={option => {
                    data.billing_address_required = option.value
                    this.setState({ data })
                  }}
                  options={YES_NO_OPTIONS}
                />
              </div>
            </div>

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }
}
