import React from "react"

import BaseTable from "./BaseTable"

import moment from "moment"

import {Link} from "react-router-dom"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AddCompanyModal from "../modal/AddCompanyModal";
import Currency from "../../../utils/Currency";
import PaidCell from "./cells/PaidCell";
import ViewCell from "./cells/ViewCell";
import CheckCell from "./cells/CheckCell";
import EditCell from "./cells/EditCell";
import NudgeCell from "./cells/NudgeCell";
import DeleteCell from "./cells/DeleteCell";
import CustomerCell from "./cells/CustomerCell";
import Splink from "../../../utils/Splink";
import DownloadCell from "./cells/DownloadCell";
import ConfirmationModal from "../modal/ConfirmationModal";
import Notify from "../../../utils/Notify";
import SendCell from "./cells/SendCell";

const TYPE_FILTERS = {
  name: {
    api: "type",
    display: "type"
  },
  values: [{
    label: "All Payments",
    value: null
  },
  {
    label: "Recurring",
    value: "recurring"
  },
  {
    label: "One-Off",
    value: "one_off"
  }]
}

const PAID_FILTERS = {
  name: {
    api: 'paid',
    display: 'requests',
  },
  values: [{
    label: "All Requests",
    value: null
  },
  {
    label: "Paid",
    value: "true"
  },
  {
    label: "Unpaid",
    value: "false"
  }]
}

const FILTERS = [
  TYPE_FILTERS,
  PAID_FILTERS
]

export default class PaymentRequestsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._refresh())
  }

  _refresh(){
    this.table.current.refresh()
  }

  _getColumns() {
    return [
      {
        Header: 'Company',
        id: 'company.name',
        accessor: 'company.name',
        width: 160
      },
      {
        Header: 'Customer',
        id: 'customer.email',
        Cell: rowInfo => {
          let customer = rowInfo.original.customer
          return (
            <CustomerCell
              customer={customer}
            />
          )
        },
        width: 160
      },
      {
        Header: 'Amount',
        id: 'payment_plans[0].total',
        sortable: false,
        Cell: rowInfo => {
          let paymentRequest = rowInfo.original
          return Currency.format(paymentRequest.payment_plans[0].currency.symbol, paymentRequest.payment_plans[0].total)
        }
      },
      {
        Header: 'Status',
        id: 'paid',
        Cell: rowInfo => {
          let paymentRequest = rowInfo.original
          return (
            <PaidCell
              paid={paymentRequest.paid}
            />
          )
        }
      },
      {
        Header: 'Views',
        id: 'views_no',
        accessor:  'views_no'
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: paymentRequest => moment(paymentRequest.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Due',
        id: 'due_at',
        accessor: paymentRequest => moment(paymentRequest.due_at).format('DD MMM YYYY')
      },
      {
        Header: 'Paid',
        id: 'paid_at',
        accessor: paymentRequest => paymentRequest.paid_at ? moment(paymentRequest.paid_at).format('DD MMM YYYY') : '-'
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 250,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let paymentRequest = rowInfo.original
          let url = Splink.getPaymentRequestUrl(paymentRequest.company, paymentRequest)
          if(this.props.latestResults) {
            return null
          }else{
            return (
              <span style={{overflow: 'visible', position: 'relative', width: 150}}>
                <ViewCell
                  tooltip={"View"}
                  onViewClicked={() => {
                    window.open(url, '_blank')
                  }}
                />
                {/*{*/}
                {/*  paymentRequest.paid &&*/}
                {/*  <SendCell*/}
                {/*    tooltip={"Resend Receipt"}*/}
                {/*    onSendClicked={() => {*/}
                {/*      this.setState({*/}
                {/*        paymentToResend: paymentRequest.payment,*/}
                {/*        showConfirmationModal: true*/}
                {/*      })*/}
                {/*    }}*/}
                {/*  />*/}
                {/*}*/}
              </span>
            )
          }
        }
      }
    ]
  }

  _resendPaymentReceipt(){
    Backend.resendPaymentReceipt(this.state.paymentToResend).then(() => {
      Notify.success("Receipt has been resent successfully.")
      this.setState({
        paymentToResend: null,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        paymentToResend: null,
        showConfirmationModal: false
      })
    })
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    let message = "Are you sure you want to resend this receipt?"

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          this._resendPaymentReceipt()
        }}
        onHide={() => {
          this.setState({
            paymentToResend: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  _renderToolBar(){
    if(this.props.latestResults){
      return (
        <Link to="/requests" className="btn btn-light-primary font-weight-bolder">
          <span className="svg-icon svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
               viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                  fill="#000000"></path>
              </g>
            </svg>
          </span>
          View All Requests
        </Link>
      )
    }
  }

  _renderDescription(paymentRequest){
    return (
      <>
        {
          paymentRequest.description &&
          <div style={{backgroundColor: '#fff', paddingTop: 15, paddingLeft: 15}}>
            <p>
              <b>Description:</b> {paymentRequest.description}
            </p>
          </div>
        }
      </>
    )
  }

  render() {
    let {

    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.PaymentRequests}?min_date=${this.state.dateFrom?.format('YYYY-MM-DD') || ''}&max_date=${this.state.dateTo?.format('YYYY-MM-DD') || ''}`}
          noDataMessage={"No payment requests found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={!this.props.latestResults && FILTERS}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          SubComponent={row => this._renderDescription(row.original)}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderConfirmationModal() }
      </div>
    )
  }
}

PaymentRequestsTable.defaultProps = {
  latestResults: false
}
