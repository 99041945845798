import React from 'react'
import General from "../../../../utils/General";

export default class BankConnectedCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }
  
  render() {
    let {
      company
    } = this.state

    let connected = company.company_processors.length > 0
    let className = connected ? 'label-light-success' : 'label-light-danger'
    let title = connected ? 'Yes' : 'No'

    return (
      <span style={{width: 110}}>
        <span className={`label label-lg font-weight-bold w-100 ${className} label-inline`}>
          {General.toTitleCase(title)}
        </span>
      </span>
    )
  }
}
