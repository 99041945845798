import React from 'react'
import Modal from 'react-bootstrap/Modal'

import LazyLoadingList from '../../../components/common/LazyLoadingList';

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import Processors from "../../../../utils/Processors"

import ImageEdit from "../../common/ImageEdit";
import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

export default class ProcessorSettingsModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: props.company,
    }
  }

  _renderProcessor(processor, index){

    if(processor.slug.indexOf("fake") > -1){
      return null
    }
    return (
      <div className="col-md-4">

        <a
          href='javascript:void(0)'
          onClick={() => this.props.onSelected(processor)}
          className="item font-size-h4 text-dark font-weight-bold"
        >
          <span
            className='img'
            style={{backgroundImage: `url(${processor.logo_small?.thumbnail})`}}
           />
           {processor.name}
        </a>

      </div>
    )
  }

  render() {
    let {
      show,
      company,
    } = this.state

    return (
      <>

        <Modal.Body
          className="unconnected-processors"
        >

          <div className='container'>
            <div className="row items">
              <LazyLoadingList
                ref={this.list}
                endpoint={`${window.Api.Processors}?company_id=${company.id}&connected=false&live=${company.settings.live}`}
                renderItem={(processor, index) => this._renderProcessor(processor, index)}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

        </Modal.Footer>

      </>
    )
  }
}
