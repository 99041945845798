import React from 'react'


export default class DisplayCDC extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      answers: props.answers
    }
  }

  render() {
    let {
      answers,
    } = this.state

    return (
      answers.map(answer => {
        let questionAnswer = answer.question.type === "text" ? answer.value : answer.bool ? 'Yes' : 'No'
        return (
          <>
            <p className={"mb-3"}><b dangerouslySetInnerHTML={{ __html: answer.question.text + ":"}}/> {questionAnswer}</p>
          </>
        )
      })
    )
  }
}
