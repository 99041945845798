import React from "react"
import DateRange from "./components/common/DateRange";
import StatCard from "./components/common/StatCard";
import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import Stats from "../utils/Stats";
import Event from "../utils/Event";
import CompaniesTable from "./components/tables/CompaniesTable";

export default class DemoAccounts extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    Backend.getDemoAccountsStats()
      .then(stats => {
        this.setState({
          stats: stats.admin
        })
      })
  }

  _renderStatCards(){
    let {
      stats
    } = this.state

    if(!stats) return null

    let week = stats.demo_sign_ups?.week || 0
    let month = stats.demo_sign_ups?.month || 0
    let valueProcessed = Currency.format(window.General.DefaultCurrency.symbol, Stats.getTotalPaymentsValue(stats))

    return (
      <>
        <StatCard
          amount={week}
          title={'Demo Accounts - Last 7 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={month}
          title={'Demo Accounts - Last 30 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={valueProcessed}
          title={'Total Value Processed'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className={'row'}>
          {this._renderStatCards()}
        </div>

        <CompaniesTable
          title={'Demo Accounts'}
          live={false}
        />
      </>
    )
  }

}