import React from "react";

import Modal from "react-bootstrap/Modal";

import Notify from "../../../../utils/Notify";
import AuthManager from "../../../../utils/AuthManager";

export default class RegenerateBackupCodesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      verificationMethod: props.verificationMethod,
    };
  }

  componentDidMount() {
    if (this.state.show) {
      this._requestCode(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    let requestCode = nextProps.show && !this.state.show;
    this.setState(nextProps, () => {
      if (requestCode) {
        this._requestCode();
      }
    });
  }

  _isFormValid() {
    let { code } = this.state;

    let error = null;

    if (!code) {
      error = "Please enter a valid code.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }
    return true;
  }

  _regenerateBackupCodes() {
    const { code, backupCodes } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    AuthManager.regenerateBackupCodes(code)
      .then((backupCodes) => {
        this.setState({ backupCodes, isLoading: false });
        this.props.onRegenerated(backupCodes);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _requestCode(showAlert = true) {
    const { verificationMethod } = this.state;

    this.setState({ isLoading: true });

    AuthManager.requestCode(verificationMethod.name)
      .then(() => {
        this.setState({ isLoading: false });
        if (showAlert) {
          Notify.success("Code resent successfully");
        }
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { show, isLoading, code, verificationMethod } = this.state;

    let method = verificationMethod.name;

    let subtitle =
      method == "app"
        ? "Enter a code from your Authenticator app to confirm"
        : "Enter the code sent to your phone to confirm";

    return (
      <>
        <Modal show={show} onHide={() => this.props.onHide()}>
          <Modal.Header closeButton>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-dark">
              Your current Backup Codes will no longer work.
              <br />
              <br />
              {subtitle}
            </div>
            <div className="text-dark-75 font-weight-bold mt-8">
              Enter 6-digit code
            </div>
            <div className="form-group row">
              <div className="col">
                <input
                  className="form-control form-control-solid"
                  type="text"
                  name="code"
                  value={code}
                  onChange={(e) => {
                    code = e.target.value;
                    this.setState({ code });
                  }}
                />
                {method !== "app" && (
                  <span
                    className="text-muted ml-1"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Didn't receive a code ?
                    <a
                      href="javascript:;"
                      tabIndex="4"
                      className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                      onClick={() => this._requestCode()}
                    >
                      Resend Code
                    </a>
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger font-weight-bold px-9 py-3"
              disabled={isLoading}
              onClick={() => this._regenerateBackupCodes()}
            >
              Regenerate
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

RegenerateBackupCodesModal.defaultProps = {};
