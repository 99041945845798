import React from "react"

import { Link, withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import moment from "moment"

import Splink from '../../../utils/Splink'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from '../../../utils/Currency'
import AuthManager from '../../../utils/AuthManager'

import AddCompanyModal from "../modal/AddCompanyModal";
import UpdateCompanyModal from "../modal/UpdateCompanyModal";
import StripeSettingsModal from "../modal/StripeSettingsModal";
import UpdateCompanyPackageModal from "../modal/UpdateCompanyPackageModal";
import ProcessorSettingsModal from "../modal/ProcessorSettingsModal";

import ViewCell from "./cells/ViewCell";
import CheckCell from "./cells/CheckCell";
import EditCell from "./cells/EditCell";
import EditPackageCell from "./cells/EditPackageCell";
import NudgeCell from "./cells/NudgeCell";
import CustomerCell from "./cells/CustomerCell";
import BankConnectedCell from "./cells/BankConnectedCell";
import PlusCell from "./cells/PlusCell";
import AddOnModal from "../modal/AddOnModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import Notify from "../../../utils/Notify";

const TYPE_FILTERS = {
  name: {
    api: "type",
    display: "type"
  },
  values: [{
    label: "All",
    value: null
  },
  {
    label: "Freemium",
    value: "freemium"
  },
  {
    label: "Pro",
    value: "pro"
  }]
}

const BANK_FILTERS = {
  name: {
    api: "bank",
    display: "bank"
  },
  values: [{
    label: "All",
    value: null
  },
  {
    label: "Yes",
    value: "true"
  },
  {
    label: "No",
    value: "false"
  }]
}

const FILTERS = [
  TYPE_FILTERS,
  BANK_FILTERS
]

class CompaniesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Company',
        id: 'name',
        accessor:  company => company.name
      },
    ]

    if(AuthManager.isPlatformAdmin()){
      columns.push({
        Header: 'Partner',
        id: 'association.name',
        sortable: true,
        exportable: true,
        accessor:  company => company.association?.name || "-"
      })
    }

    return [
      ...columns,
      {
        Header: 'User',
        id: 'user',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let user = rowInfo.original.members[0]?.user || null
          return (
            <CustomerCell
              customer={user}
            />
          )
        },
        width: 160
      },
      {
        Header: 'Last Logged In',
        id: 'last_logged_in',
        sortable: false,
        exportable: false,
        accessor: company => {
          let user = company.members[0]?.user || null
          if(user?.last_seen){
            return moment(user.last_seen).format('DD MMM YYYY')
          }
          return "-"
        }
      },
      {
        Header: 'Package',
        accessor:  "subscription.package.name"
      },
      {
        Header: 'Fee',
        id: 'settings.platform_rate',
        sortable: false,
        accessor:  company => {
          let rate = AuthManager.isPlatformAdmin() ? company.settings.platform_rate : company.settings.association_rate
          return +parseFloat(rate * 100).toFixed(2) +"%"
        },
        width: 50
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        width: 220,
        Cell: rowInfo => {
          let company = rowInfo.original
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              <ViewCell
                onViewClicked={() => {
                  let url = Splink.getSplinkUrl(company)
                  window.open(url)
                }}
              />
              {
                !company.settings.live &&
                <CheckCell
                  tooltip={'Go Live'}
                  onCheckClicked={() => {
                    this.setState({
                      company,
                      showConfirmationModal: true
                    })
                  }}
                />
              }
              <EditCell
                onEditClicked={() => {
                  this.setState({
                    showUpdateCompanyModal: true,
                    company: company
                  })
                }}
              />
              <PlusCell
                onPlusClicked={() => {
                  this.setState({
                    showCompanyAddonModal: true,
                    company: company
                  })
                }}
              />
              <EditPackageCell
                onEditClicked={() => {
                  this.setState({
                    showUpdatePackageModal: true,
                    company: company
                  })
                }}
              />
            </span>
          )
        }
      }
    ]
  }

  _verifyCompany(){
    let {
      company
    } = this.state

    Backend.verifyCompany(company)
    .then(company => {
      Notify.success("Company Has Been Verified")
      this.table.current.refresh()
      this.setState({
        loading: false,
        company: null,
        showConfirmationModal: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false,
        company: null,
        showConfirmationModal: false
      })
      Notify.error(error.message)
    })
  }

  _renderToolBar(){
    if(this.props.latestResults){
      return (
        <Link to="/sign-ups" className="btn btn-light-primary font-weight-bolder ml-4">
          <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
               viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                  fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                <path
                  d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                  fill="#000000" fillRule="nonzero"></path>
              </g>
            </svg>
          </span>
          View All Signups
        </Link>
      )
    }

    if(!this.props.live){
      return (
        <>
          <a className="btn btn-primary font-weight-bolder"
             onClick={() => {
               this.setState({
                 showCompanyModal: true
               })
             }}
          >
          <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                 height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"></rect>
                <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                      x="4" y="11" width="16" height="2" rx="1"></rect>
              </g>
            </svg>
          </span>
            Add Company
          </a>
        </>
      )
    }
  }

  _renderAddCompanyModal(){
    let {
      live,
      company,
      showCompanyModal,
    } = this.state

    if(!showCompanyModal) return null

    return (
      <AddCompanyModal
        show={showCompanyModal}
        live={live}
        company={company}
        onAdded={() => {
          this.setState({
            showCompanyModal: false,
            company: null
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            showCompanyModal: false,
            company: null
          })
        }}
      />
    )
  }

  _renderUpdateCompanyModal(){
    let {
      company,
      showUpdateCompanyModal,
    } = this.state

    if(!showUpdateCompanyModal) return null

    return (
      <UpdateCompanyModal
        show={showUpdateCompanyModal}
        company={company}
        onAdded={() => {
          this.setState({
            showUpdateCompanyModal: false,
            company: null
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            showUpdateCompanyModal: false,
            company: null
          })
        }}
      />
    )
  }

  _renderCompanyAddonModal(){
    let {
      company,
      showCompanyAddonModal,
    } = this.state

    if(!showCompanyAddonModal) return null

    return (
      <AddOnModal
        show={showCompanyAddonModal}
        company={company}
        onAdded={() => {
          this.setState({
            showCompanyAddonModal: false,
            company: null
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            showCompanyAddonModal: false,
            company: null
          })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let {
      showConfirmationModal
    } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure? This action can't be redone."}
        onConfirm={() => {
          this._verifyCompany()
        }}
        onHide={() => {
          this.setState({
            company: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  _renderStripeSettingsModal(){
    let {
      companyProcessor,
      showStripeSettings
    } = this.state

    if(!showStripeSettings) return null

    return (
      <StripeSettingsModal
        show={showStripeSettings}
        companyProcessor={companyProcessor}
        onHide={() => {
          this.setState({
            companyProcessor: null,
            showStripeSettings: false
          })
        }}
      />
    )
  }

  _renderProcessorSettingsModal(){
    let {
      company,
      showProcessorSettings,
      showConnectEMerchantPaySettings
    } = this.state

    if(!showProcessorSettings) return null

    return (
        <ProcessorSettingsModal
            show={showProcessorSettings}
            company={company}
            showConnectEMerchantPaySettings={showConnectEMerchantPaySettings}
            onUpdated={() => {
              this.setState({
                company: null,
                showProcessorSettings: false
              })
              this.table.current.refresh()
            }}
            onHide={() => {
              this.setState({
                company: null,
                showProcessorSettings: false,
                showConnectEMerchantPaySettings: false
              })
            }}
        />
    )
  }

  _renderUpdatePackageModal(){
    let {
      company,
      showUpdatePackageModal,
    } = this.state

    if(!showUpdatePackageModal) return null

    return (
      <UpdateCompanyPackageModal
        show={showUpdatePackageModal}
        company={company}
        onUpdated={() => {
          this.setState({
            showUpdatePackageModal: false,
            company: null
          })
          this.table.current.refresh()
        }}
        onHide={() => {
          this.setState({
            showUpdatePackageModal: false,
            company: null
          })
        }}
      />
    )
  }

  _renderDescription(company){

    let companyProcessor = company.company_processors.find(companyProcessor => companyProcessor.default)

    if(!companyProcessor || !companyProcessor.data){
      return (
        <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
          <b>This company has not been connected to a processor</b>
            <br/><br/>
            <a href="javascript:void"
               onClick={() => {
                 this.props.history.push(`companies/${company.id}/processors`)
               }}
            >
              Manage Processors
            </a>
        </p>
      )
    }

    let stripeBaseUrl = company.settings.live ? "https://dashboard.stripe.com" : "https://dashboard.stripe.com/test"
    let stripeConnectUrl = `${stripeBaseUrl}/connect/accounts`
    let stripeSubscriptionsUrl = `${stripeBaseUrl}/subscriptions`

    let processed = []
    let commissions = []
    for(var i = 0; i < company.financial_stats.length; i++){
      let stat = company.financial_stats[i]
      processed.push(Currency.format(stat.currency.symbol, stat.total))
      commissions.push(Currency.format(stat.currency.symbol, stat.commission))
    }

    let processedString = "-"
    let commissionsString = "-"
    if(processed.length > 0){
      processedString = processed.join(", ")
      commissionsString = commissions.join(", ")
    }

    let user = company.members[0]?.user || null
    let phoneNumber = ""
    if(user && user.phone_country_code && user.phone_number){
      phoneNumber = `${user.phone_country_code} ${user.phone_number}`
    }

    let website = company.website_url

    return (
      <div style={{backgroundColor: '#fff', paddingTop: 15, paddingLeft: 15}}>
        {
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>ID: </b>
            {company.id}<br/>
          </p>
        }
        { company.sector &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Sector: </b>
            {company.sector.name}<br/>
          </p>
        }
        { phoneNumber &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Phone Number: </b>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a><br/>
          </p>
        }
        { website &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Website: </b>
            <a href={website} target="_blank">{website}</a><br/>
          </p>
        }
        { company.source &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Source: </b>
            {company.source}<br/>
          </p>
        }
        {
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Processed: </b>
            {processedString}<br/>
          </p>
        }
        {
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Commission: </b>
            {commissionsString}<br/>
          </p>
        }
        { companyProcessor &&
          <>
            { companyProcessor.data.verification_status &&
              <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                <b>Verification Status: </b>
                {General.snakeCaseToTitleCase(companyProcessor.data.verification_status)}<br/>
              </p>
            }

            <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
              <b>Processor: </b>
              <a href="javascript:void"
                 onClick={() => {
                   this.props.history.push(`companies/${company.id}/processors`)
                 }}
              >
              {General.snakeCaseToTitleCase(companyProcessor.processor_slug)}
              </a><br/>
            </p>

            {companyProcessor.processor_slug.indexOf("stripe") > -1 &&
              <>
                {companyProcessor.data.charges_enabled != null &&
                <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                  <b>Payments Enabled: </b>
                  {companyProcessor.data.charges_enabled ? "Yes" : "No"}<br/>
                </p>
                }

                {companyProcessor.data.payouts_enabled != null &&
                <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                  <b>Payouts Enabled: </b>
                  {companyProcessor.data.payouts_enabled ? "Yes" : "No"}<br/>
                </p>
                }
                { companyProcessor.processor_slug.indexOf("standard") === -1 &&
                  <>
                    {companyProcessor.bank_connected != null &&
                    <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                      <b>Bank Connected: </b>
                      {companyProcessor.bank_connected ? "Yes" : "No"}<br/>
                    </p>
                    }
                    <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                      <b>Payout Schedule: </b>
                      <a href="javascript:void"
                         onClick={() => {
                           companyProcessor.data.currency = company.settings.payment_plan.currency.code
                           this.setState({companyProcessor, showStripeSettings: true})
                         }}
                      >
                        {companyProcessor.data.payout_schedule_interval === "manual" ? "Never (Disabled)" : General.snakeCaseToTitleCase(companyProcessor.data.payout_schedule_interval)}
                      </a><br/>
                    </p>
                  </>
                }
                {companyProcessor.data.account_id != null &&
                <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
                  <b>Account Id: </b>
                  <a target="_blank"
                     href={`${stripeConnectUrl}/${companyProcessor.data.account_id}`}>{companyProcessor.data.account_id}</a><br/>
                </p>
                }
              </>
            }
          </>
        }
        { company.subscription.stripe_subscription_id &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Subscription Id: </b>
            <a target="_blank" href={`${stripeSubscriptionsUrl}/${company.subscription.stripe_subscription_id}`}>{company.subscription.stripe_subscription_id}</a><br/>
          </p>
        }
        <a href="javascript:void"
           onClick={() => {
             this.props.history.push(`companies/${company.id}/processors`)
           }}
        >
          Manage Processors
        </a>
      </div>
    )
  }

  render() {
    let {
      live,
    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Companies}?live=${live}`}
          noDataMessage={"No company found"}
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={!this.props.latestResults && FILTERS}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          SubComponent={row => this._renderDescription(row.original)}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderAddCompanyModal() }
        { this._renderUpdateCompanyModal() }
        { this._renderCompanyAddonModal() }
        { this._renderConfirmationModal() }
        { this._renderStripeSettingsModal() }
        { this._renderProcessorSettingsModal() }
        { this._renderUpdatePackageModal() }
      </div>
    )
  }
}

CompaniesTable.defaultProps = {
  latestResults: false
}

export default withRouter(CompaniesTable);
