import React from "react";

import moment from "moment";

export default class DateCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { date } = this.state;

    let formattedDate = date ? moment(date).format("DD MMM YYYY") : "-";

    let time = date ? moment(date).format("H:mm") : "-";

    return (
      <div className="d-flex align-items-center">
        <div>
          <div
            className="text-dark-75 font-weight-bold line-height-sm"
          >
            {time}
          </div>
          <div className="font-size-sm text-dark-50 text-hover-primary my-1">
            {formattedDate}
          </div>
        </div>
      </div>
    );
  }
}
