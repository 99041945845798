import React from "react";

export default class CustomerCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: props.customer,
      className: props.className
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { customer, className } = this.state;

    let name = `${customer?.first_name || ""} ${customer?.last_name || ""}`;
    let email = customer?.email || "";

    

    return (
      <div className="d-flex align-items-center">
        <div className={className}>
          <div className="text-dark-75 font-weight-bold line-height-sm">
            {name}
          </div>
          <a
            href="javascript:;"
            className="font-size-sm text-dark-50 text-hover-primary"
          >
            {email}
          </a>
        </div>
      </div>
    );
  }
}

CustomerCell.defaultProps = {
  className: 'ml-2'
}
