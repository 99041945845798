import React from 'react'
import Modal from 'react-bootstrap/Modal'

import ImageUploader from "../common/ImageUploader";
import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"
import AsyncSelect from "../common/AsyncSelect";
import Calculations from "../../../utils/Calculations";
import Currency from "../../../utils/Currency";
import AuthManager from "../../../utils/AuthManager";
import ImageEdit from "../common/ImageEdit";
import Event from "../../../utils/Event"

import { TAX } from '../../../constants/Variables';

export default class PaymentPlans extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      company: props.company,
      paymentPlans: props.paymentPlans ? props.paymentPlans : {
        payment_plans: [
          {
            type: 'one_time',
            open: false,
            vat: 0,
            reverse_fee: "no"
          }
        ]},
    }
  }

  _handleChange(e) {
    let paymentPlans = this.state.paymentPlans
    paymentPlans[e.target.name] = e.target.value
    this.setState({ paymentPlans, error: null })
  }

  render() {
    let {
      company,
      paymentPlans
    } = this.state

    let showReverseFee = false
    return paymentPlans.map((paymentPlan, index) => {
      if(index !== 0) return null
      let currency = paymentPlan.currency ? General.getSingleAsyncOption(paymentPlan.currency) : null
      let price = paymentPlan.subtotal ? (paymentPlan.subtotal / 100) : ''
      let percentage = paymentPlan.vat_percentage ? Math.round((paymentPlan.vat_percentage * 100) * 100) / 100 || '' : Math.round((Calculations.getPercentage(paymentPlan.total, paymentPlan.subtotal)) * 100) / 100 || ''
      let vatEnabled = percentage > 0 || paymentPlan.enableVat
      let installments = paymentPlan.type === "recurring" && paymentPlan.installments == null ? "null" : paymentPlan.installments
      return(
        <>
          {
            this.props.showCurrency &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Currency*</label>
              <div className="col-9">
                <AsyncSelect
                  endpoint={window.Api.Currencies}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  params={{ }}
                  value={currency}
                  onSelected={currency => {
                    paymentPlan.currency = currency
                    this.props.updatePaymentPlan(paymentPlan, index)
                  }}
                  getOptions={currencies => General.getAsyncOptions(currencies)}
                />
              </div>
            </div>
          }
          {
            !paymentPlan.open &&
            <div className="form-group row">
              <label className="col-3 col-form-label">{this.props.priceTitle}*</label>
              <div className="col-9">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text border-0">{paymentPlan.currency.symbol}</span>
                  </div>
                  <input
                    className="form-control form-control-solid"
                    type="number"
                    value={price}
                    placeholder="0.00"
                    name="subtotal"
                    step={"0.01"}
                    onChange={(e) => {
                      paymentPlan.subtotal = Math.round(e.target.value * 100)
                      if(paymentPlan.vat_percentage){
                        paymentPlan.total = Math.round(parseFloat(Calculations.totalIncludingVat(e.target.value * 100, paymentPlan.vat_percentage, false)))
                      }else{
                        paymentPlan.total = Math.round(e.target.value * 100)
                      }

                      paymentPlan.vat = 0
                      this.setState({ error: null })
                      this.props.updatePaymentPlan(paymentPlan, index)
                    }}
                  />
                </div>
                <div className="row no-gutters">
                  <div className="col-auto mt-3">
                    <span className="switch switch-outline switch-icon switch-primary">
                      <label>
                        <input
                          type="checkbox"
                          id="vatSelect"
                          checked={paymentPlan.enableVat || vatEnabled}
                          onChange={e => {
                            let show = e.target.checked
                            if(show){
                              paymentPlan.enableVat = show
                              paymentPlan.vat_percentage = company.settings.vat_rate
                              paymentPlan.vat = Math.round(parseFloat(Calculations.totalVat(paymentPlan.subtotal, company.settings.vat_rate, false)))
                              paymentPlan.total = Math.round(parseFloat(Calculations.totalIncludingVat(paymentPlan.subtotal, company.settings.vat_rate, false)))
                            }else{
                              paymentPlan.enableVat = show
                              paymentPlan.vat_percentage = null
                              paymentPlan.vat = 0
                              paymentPlan.total = paymentPlan.subtotal
                            }
                            this.props.updatePaymentPlan(paymentPlan, index)
                          }}
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                  <label htmlFor="vatSelect" className="col col-form-label align-left pl-3 mt-3">Enable {TAX}</label>
                </div>
              </div>
              {
                vatEnabled &&
                <>
                  <div className="col-3"></div>
                  <div className="col-9">
                    <div className="row" id="vatOptions">
                      <div className="col-6">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" style={{border: 'none'}}>%</span>
                          </div>
                          <input
                            className="form-control form-control-solid"
                            type="number"
                            min={0}
                            placeholder="0"
                            name={"vat"}
                            value={percentage}
                            onChange={(e) => {
                              let vatPercentage = parseFloat(e.target.value / 100).toFixed(4)
                              paymentPlan.vat_percentage = vatPercentage
                              paymentPlan.vat = Math.round(parseFloat(Calculations.totalVat(paymentPlan.subtotal, vatPercentage, false)))
                              paymentPlan.total = Math.round(parseFloat(Calculations.totalIncludingVat(paymentPlan.subtotal, vatPercentage, false)))
                              this.props.updatePaymentPlan(paymentPlan, index)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">€</span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Total"
                            value={Currency.format('', paymentPlan.total)}
                            disabled=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          }

          <div className="form-group row">
            <label className="col-3 col-form-label">Payment Type</label>
            <div className="col-9">
              <div className="radio-inline mt-2"
                   onChange={e => {
                     if(e.target.value === "one_time"){
                       paymentPlan.type = "one_time"
                       paymentPlan.frequency = null
                       paymentPlan.installments = null
                     }else if(e.target.value === "recurring"){
                       paymentPlan.type = "recurring"
                       paymentPlan.frequency = "monthly"
                       paymentPlan.installments = "null"
                     }else{
                       paymentPlan.type = "optional"
                       paymentPlan.frequency = null
                       paymentPlan.installments = null
                     }
                     this.props.updatePaymentPlan(paymentPlan, index)
                   }}
              >
                <label className="radio">
                  <input
                    type="radio"
                    name="paymentType"
                    value={"one_time"}
                    checked={paymentPlan.type === "one_time"}
                  />
                  One Time Payment <span></span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="paymentType"
                    value={"recurring"}
                    checked={paymentPlan.type === "recurring"}
                  />
                  Recurring / Installment Plan <span></span>
                </label>
                {
                  this.props.showOptional &&
                  <label className="radio">
                    <input
                      type="radio"
                      name="paymentType"
                      value={"optional"}
                      checked={paymentPlan.type === "optional"}
                    />
                    Optional <span></span>
                  </label>
                }
              </div>
            </div>
          </div>

          {
            showReverseFee &&
            <div className="form-group row">
              <label className="col-3 col-form-label">Reverse Fee</label>
              <div className="col-9">
                <div className="radio-inline"
                     onChange={e => {
                       paymentPlan.reverse_fee  = e.target.value
                       this.props.updatePaymentPlan(paymentPlan, index)
                     }}
                >
                  <label className="radio">
                    <input
                      type="radio"
                      name="reverseFee"
                      value={"yes"}
                      checked={paymentPlan.reverse_fee === "yes"}
                    />
                    Yes <span></span>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="reverseFee"
                      value={"no"}
                      checked={paymentPlan.reverse_fee === "no"}
                    />
                    No <span></span>
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="reverseFee"
                      value={"optional"}
                      checked={paymentPlan.reverse_fee === "optional"}
                    />
                    Optional <span></span>
                  </label>
                </div>
              </div>
            </div>
          }

          {
            paymentPlan.type === "recurring" &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">Period</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      name="frequency"
                      className="form-control"
                      value={paymentPlan.frequency}
                      onChange={e => {
                        paymentPlan.frequency = e.target.value
                        this.props.updatePaymentPlan(paymentPlan, index)
                      }}
                    >
                      <option value="weekly">Weekly</option>
                      <option value="monthly" selected="">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Installments</label>
                <div className="col-9">
                  <div className="select-wrapper">
                    <select
                      name="installments_period"
                      className="form-control"
                      value={installments}
                      onChange={e => {
                        paymentPlan.installments = e.target.value
                        if(paymentPlan.installments === "null"){
                          paymentPlan.installments = null
                        }
                        this.props.updatePaymentPlan(paymentPlan, index)
                      }}
                    >
                      <option value="null" selected="">Continuous</option>
                      <option value="2">2 Installments</option>
                      <option value="3">3 Installments</option>
                      <option value="4">4 Installments</option>
                      <option value="5">5 Installments</option>
                      <option value="6">6 Installments</option>
                      <option value="7">7 Installments</option>
                      <option value="8">8 Installments</option>
                      <option value="9">9 Installments</option>
                      <option value="10">10 Installments</option>
                      <option value="11">11 Installments</option>
                      <option value="12">12 Installments</option>
                      <option value="13">13 Installments</option>
                      <option value="14">14 Installments</option>
                      <option value="15">15 Installments</option>
                      <option value="16">16 Installments</option>
                      <option value="17">17 Installments</option>
                      <option value="18">18 Installments</option>
                      <option value="19">19 Installments</option>
                      <option value="20">20 Installments</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          }
        </>
      )
    })
  }
}

PaymentPlans.defaultProps = {
  showCurrency: true,
  showOptional: true,
  showReverseFee: false,
  priceTitle: "Price"
}
