import React from "react"
import StatCard from "./components/common/StatCard";
import ChannelsTable from "./components/tables/ChannelsTable";
import Backend from "../utils/Backend";
import Stats from "../utils/Stats";
import Event from "../utils/Event";
import moment from "moment";
import Currency from "../utils/Currency";

export default class Channels extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    let dateTo = moment()
    let dateFrom = moment().add(-1, "years")

    Backend.getChannelsStats(dateFrom.toISOString(), dateTo.toISOString())
    .then(stats => {
      let data = stats.admin || stats.association
      this.setState({
        stats: Stats.getChannelsStats(data.payment_links),
      })
    })
  }

  _renderStatCards(){
    let {
      stats
    } = this.state

    if(!stats) return null

    return (
      <>
        <StatCard
          amount={stats.customers}
          title={'No. Of Customers'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={stats.paymentLinksCount}
          title={'No. Of Payment Links'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={stats.totalProcessed}
          title={'Amount All Time'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className={'row'}>
          {this._renderStatCards()}
        </div>
        <ChannelsTable
          title={'All Channels'}
        />
      </>
    )
  }

}
