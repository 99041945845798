import React from "react"
import DateRange from "./components/common/DateRange";
import StatCard from "./components/common/StatCard";
import CompaniesTable from "./components/tables/CompaniesTable";

import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import Event from "../utils/Event";
import Stats from "../utils/Stats";

export default class Signups extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      stats: null,
      currency: 0
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    Backend.getSignUpStats()
    .then(stats => {

      let data = stats.admin || stats.association
      this.setState({
        total: Currency.format(window.General.DefaultCurrency.symbol, Stats.getTotalPaymentsValue(data)),
        week: data.sign_ups.week || 0,
        month: data.sign_ups.month || 0
      })
    })
  }

  _renderStatCards(){
    let {
      total,
      week,
      month
    } = this.state

    if(!total) return null

    return (
      <>
        <StatCard
          amount={week}
          title={'Signups - Last 7 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={month}
          title={'Signups - Last 30 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={total}
          title={'Total Value Processed'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className={'row'}>
          {this._renderStatCards()}
        </div>

        <CompaniesTable
          title={'All Signups'}
          live={true}
        />
      </>
    )
  }

}
