
export default class General {
  static uuid(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  static debounce(func, wait, immediate) {
  	var timeout;
  	return function() {
  		var context = this, args = arguments;
  		var later = function() {
  			timeout = null;
  			if (!immediate) func.apply(context, args);
  		};
  		var callNow = immediate && !timeout;
  		clearTimeout(timeout);
  		timeout = setTimeout(later, wait);
  		if (callNow) func.apply(context, args);
  	};
  };

  static snakeCaseToTitleCase(str){
    let words = str.replace(/_/g, ' ')
    return General.toTitleCase(words)
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getInitials(str){
    var initials = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials
  }

  static getRandomColorState(){
    let colors = ["info", "success", "warning", "danger", "dark", "primary", "brand"]
    let color = colors[Math.floor(Math.random()*colors.length)];
    return color
  }

  static formatTimeToAmPm(hours){
    let AmOrPm = hours * 1 >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    return `${hours}${AmOrPm}`
  }

  static getSingleAsyncOption(value){
    if (!value || typeof value !== 'object') return ''
    return {
      value: value.id,
      label: `${value.name}`,
      data: value
    }
  }

  static getAsyncOptions(values){
    return values.map(value => ({
      value: value.id,
      label: `${value.name}`,
      data: value
    }))
  }

  static clone(data) {
    return data ? JSON.parse(JSON.stringify(data)) : null;
  }
}
