import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from '../common/PhoneInput'
import PasswordInput from '../common/PasswordInput'

import ImageEdit from '../common/ImageEdit'
import ColorPicker from '../common/ColorPicker'
import AsyncSelect from '../common/AsyncSelect'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Notify from '../../../utils/Notify'
import Phone from '../../../utils/Phone'

export default class AssociationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    let association = props.association || {
      rate: 0,
      link_name: 'splink',
      terms_url: '',
      primary_color: '#3699ff',
      text_on_primary_color: '#ffffff',
      take_color: '#3699ff',
      request_color: '#f64e60',
      shop_color: '#1BC5BD',
      recurring_color: '#3699ff',
      associates: [
        {
          user: {},
        },
      ],
    }
    association.terms_url = this._removeHTTP(association.terms_url)
    return {
      ...props,
      association,
    }
  }

  _isFormValid() {
    let { association } = this.state

    let error = null

    if (!association.name) {
      error = 'Please enter a partner name'
    }

    if (!error && association.associates) {
      association.associates.map((member) => {
        let { first_name, last_name, email } = member.user

        if (!first_name) {
          error = 'Please enter a first name'
        } else if (!last_name) {
          error = 'Please enter a last name'
        } else if (!email) {
          error = 'Please enter an email'
        }
      })
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _removeHTTP(url) {
    return url?.replace('http://', '').replace('https://', '')
  }

  _handleAddAssociation() {
    let { association } = this.state

    if (!this._isFormValid()) {
      return
    }
    if (association.id) {
      this._updateAssociation()
    } else {
      this._addAssociation()
    }
  }

  _handlePhoneUpdate(value, data, index) {
    let { association } = this.state

    association.associates[index].user.phone_country_code = `+${data.dialCode}`
    association.associates[index].user.phone_number = value.slice(
      data.dialCode.length
    )
    this.setState({
      association,
    })
  }

  _addAssociation() {
    let { association } = this.state

    this.setState({ loading: true })
    Backend.addAssociation(association)
      .then((association) => {
        Notify.success('Partner Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(association)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _updateAssociation() {
    let { association } = this.state

    this.setState({ loading: true })
    Backend.updateAssociation(association)
      .then((association) => {
        Notify.success('Partner Updated Successfully')
        this.setState({ loading: false })
        this.props.onAdded(association)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderAssociates() {
    let { association } = this.state

    if (association.id) {
      return null
    }

    return association.associates.map((associate, index) => {
      return (
        <>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">First Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="first_name"
                placeholder={'First Name'}
                className="form-control form-control-solid"
                value={associate.user.first_name}
                onChange={(e) => {
                  association.associates[index].user.first_name = e.target.value
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Last Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="last_name"
                placeholder={'Last Name'}
                className="form-control form-control-solid"
                value={associate.user.last_name}
                onChange={(e) => {
                  association.associates[index].user.last_name = e.target.value
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Phone</label>
            <div className="col-lg-9 my-auto">
              <PhoneInput
                onChange={(value, data) =>
                  this._handlePhoneUpdate(value, data, index)
                }
                inputProps={{
                  autocomplete: 'off',
                  className: 'form-control form-control-solid',
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Email</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="email"
                placeholder={'Email'}
                className="form-control form-control-solid"
                value={associate.user.email}
                onChange={(e) => {
                  association.associates[index].user.email = e.target.value
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <PasswordInput
            containerClassName="form-group row"
            inputContainerClassName="col-lg-9 my-auto"
            labelClassName="col-lg-3 col-form-label"
            className="form-control form-control-solid"
            value={associate.user.password}
            onChange={(value) => {
              association.associates[index].user.password = value
              this.setState({ association, error: null })
            }}
            autoComplete="off"
            showValidationCheck
          />
        </>
      )
    })
  }

  render() {
    let { show, loading, association, error } = this.state

    let title = association.id ? 'Edit Partner' : 'Add Partner'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Partner Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="name"
                placeholder={'Partner Name'}
                className="form-control form-control-solid"
                value={association.name}
                onChange={(e) => {
                  association.name = e.target.value
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Payment Link Name</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                name="link_name"
                placeholder={'Payment Link Name'}
                className="form-control form-control-solid"
                value={association.link_name}
                onChange={(e) => {
                  association.link_name = e.target.value
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <div className="form-group row mt-5">
            <label className="col-lg-3 col-form-label">Terms Url:</label>
            <div className="col-lg-9 my-auto">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">https://</span>
                </div>
                <input
                  type="text"
                  name="terms"
                  className="form-control form-control-solid"
                  value={association.terms_url}
                  onChange={(e) => {
                    association.terms_url = e.target.value
                    this.setState({ association })
                  }}
                />
              </div>
            </div>
          </div>

          {this._renderAssociates()}

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr />
            </div>
            <div className="col-6">
              <span>
                <strong>Default Processors</strong>
              </span>
            </div>
            <div className="col-3">
              <hr />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">
              Default Live Processor
            </label>
            <div className="col-9">
              <AsyncSelect
                endpoint={window.Api.Processors}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                filter="live=true"
                value={
                  association.default_live_processor
                    ? General.getSingleAsyncOption(
                        association.default_live_processor
                      )
                    : null
                }
                onSelected={(processor) => {
                  association.default_live_processor = processor
                  this.setState({ association })
                }}
                isClearable={true}
                getOptions={(currencies) => General.getAsyncOptions(currencies)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">
              Default Demo Processor
            </label>
            <div className="col-9">
              <AsyncSelect
                endpoint={window.Api.Processors}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                filter="live=false"
                value={
                  association.default_demo_processor
                    ? General.getSingleAsyncOption(
                        association.default_demo_processor
                      )
                    : null
                }
                onSelected={(processor) => {
                  association.default_demo_processor = processor
                  this.setState({ association })
                }}
                isClearable={true}
                getOptions={(currencies) => General.getAsyncOptions(currencies)}
              />
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr />
            </div>
            <div className="col-6">
              <span>
                <strong>Financials</strong>
              </span>
            </div>
            <div className="col-3">
              <hr />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Commission</label>
            <div className="col-lg-9 my-auto">
              <input
                type="text"
                placeholder={'Commission (%)'}
                className="form-control form-control-solid"
                defaultValue={association.rate * 100}
                onChange={(e) => {
                  association.rate = (e.target.value / 100).toFixed(2)
                  this.setState({ association, error: null })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Currency</label>
            <div className="col-9">
              <AsyncSelect
                endpoint={window.Api.Currencies}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                params={{}}
                value={
                  association.currency
                    ? General.getSingleAsyncOption(association.currency)
                    : null
                }
                onSelected={(currency) => {
                  association.currency = currency
                  this.setState({ association })
                }}
                getOptions={(currencies) => General.getAsyncOptions(currencies)}
              />
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr />
            </div>
            <div className="col-6">
              <span>
                <strong>Branding</strong>
              </span>
            </div>
            <div className="col-3">
              <hr />
            </div>
          </div>

          <div className="form-group mt-5">
            <label>Logo Light</label>
            <div className="mt-3">
              <ImageEdit
                image={association.logo_banner}
                type="photo"
                hidePreview={false}
                aspectRatio={1.5}
                onUpdated={(image) => {
                  association.logo_banner = image
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group mt-5">
            <label>Logo Dark</label>
            <div className="mt-3">
              <ImageEdit
                image={association.logo_small}
                type="photo"
                hidePreview={false}
                aspectRatio={1.5}
                onUpdated={(image) => {
                  association.logo_small = image
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Favicon</label>
            <div className="mt-3">
              <ImageEdit
                image={association.favicon}
                type="photo"
                cropImage={true}
                hidePreview={false}
                aspectRatio={1}
                onUpdated={(image) => {
                  association.favicon = image
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Brand Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.primary_color}
                onColorChange={(color) => {
                  association.primary_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">
              Text Colour When On Brand Colour
            </label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.text_on_primary_color}
                onColorChange={(color) => {
                  association.text_on_primary_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Take Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.take_color}
                onColorChange={(color) => {
                  association.take_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Requests Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.request_color}
                onColorChange={(color) => {
                  association.request_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Shop Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.shop_color}
                onColorChange={(color) => {
                  association.shop_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Recurring Colour</label>
            <div className="col-9 mb-4">
              <ColorPicker
                color={association.recurring_color}
                onColorChange={(color) => {
                  association.recurring_color = color.hex
                  this.setState({ association })
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddAssociation()}
          >
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                  ></path>
                </g>
              </svg>
            </span>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
