import React from 'react'

import Alert from './Alert'


export default class ExplainerAlert extends React.Component {
  render(){
    return (
      <Alert
        type={this.props.type}
        text={this.props.text}
        closable={false}
      />
    )
  }
}

ExplainerAlert.defaultProps = {
  type: "info"
}
