import React from "react";

import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

import PasswordInput from "./components/common/PasswordInput";

const LOGO = require("../assets/media/logos/logo-light.png");

const LOGIN_VISUAL = require("../assets/media/icons/svg/illustrations/login-visual-1.svg");

const MODE_SIGNUP = "signup";
const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password";
const MODE_RESET_PASSWORD = "reset_password";
const MODE_MFA = "mfa";

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: false,
      mode: MODE_SIGNIN,
      code: "",
      response: null,
    };

    this.newPassword = React.createRef();
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode == MODE_MFA && !code) {
      this.setState({ error: "Please enter a valid code" });
      return false;
    }

    if (mode == MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: "Please enter a valid code" });
        return false;
      }
      if (password.length < 6) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      }
      if (password != confirmPassword) {
        this.setState({ error: "Passwords do not match" });
        return false;
      } else if (!this.newPassword.current.isValid()) {
        this.setState({
          error:
            "Your new password is not strong enough, please enter a stronger password",
        });
        return false;
      }
    }

    return true;
  }

  _handleLogInClicked(e) {
    e.preventDefault();

    let { email, password } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    AuthManager.login(email, password)
    .then(() => {
      let url = "/";
      window.location = url;
    })
    .catch((response) => {
      if (response?.message?.ephemeral_token) {
        this.setState({
          isLoading: false,
          mode: MODE_MFA,
          mfaMethod: response.message.method,
          ephemeralToken: response.message.ephemeral_token,
          lastFourPhoneDigits: response.message.phone_number_last_4_digit
        });
      } else {
        this.setState({
          isLoading: false,
          error: response.message,
        });
      }
    });
  }

  _handleVerifyLogInClicked(e) {
    e.preventDefault();

    let { ephemeralToken, code } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });
    AuthManager.verifyLogin(ephemeralToken, code)
    .then(() => {
      let url = "/";
      window.location = url;
    })
    .catch((response) => {
      this.setState({
        isLoading: false,
        error: response.message,
      });
    });
  }

  _resendMFACode() {
    const { mfaMethod, ephemeralToken, email } = this.state;

    this.setState({ isLoading: true });

    AuthManager.resendCode(mfaMethod, email, ephemeralToken)
    .then(() => {
      this.setState({ isLoading: false });
    })
    .catch((error) => {
      Notify.error(error.message);
      this.setState({
        isLoading: false,
      });
    });
  }

  _renderMFASubtitle(mfaMethod) {
    let subtitle = "To verify it's you, ";

    if (mfaMethod === "sms_twilio") {
      let lastFourPhoneDigits = this.state.lastFourPhoneDigits
      subtitle += `we've sent a 6-digit code to your phone with a number ending in ${lastFourPhoneDigits}.`;
    } else if (mfaMethod === "sms_api") {
      subtitle += `we've sent a 6-digit code to your mobile.`;
    } else {
      subtitle += "enter a code generated by your authenticator app.";
    }
    return subtitle;
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, confirmPassword } = this.state;

    this.setState({ isLoading: true });
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirmPassword: "",
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, code } = this.state;

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirmPassword: "",
        });
        Notify.success("Password reset successfully!");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _isSignUpFormValid() {
    let {
      first_name,
      last_name,
      email,
      agree,
      password,
      conf_password,
      company_name,
      slug,
    } = this.state;

    let error = null;
    if (company_name == null || company_name.length == 0) {
      error = "Please enter your company name";
    } else if (slug == null || slug.length == 0) {
      error = "Please enter a slug";
    } else if (first_name == null || first_name.length == 0) {
      error = "Please enter a valid first name";
    } else if (last_name == null || last_name.length == 0) {
      error = "Please enter a valid last name";
    } else if (email == null || email.length == 0) {
      error = "Please enter a valid email";
    } else if (password == null || password.length == 0) {
      error = "Please enter a valid password";
    } else if (conf_password == null || conf_password.length == 0) {
      error = "Please enter your password again";
    } else if (password !== conf_password) {
      error = "Please ensure your passwords match";
    } else if (agree == null || !agree) {
      error = "You must agree to the terms and conditions";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _signUpPressed(e) {
    e.preventDefault();

    if (!this._isSignUpFormValid()) {
      return;
    }

    let { slug, first_name, last_name, company_name, email, password } =
      this.state;

    this.setState({ isLoading: true });

    let data = {
      name: company_name,
      slug,
      members: [
        {
          user: {
            first_name,
            last_name,
            email,
            password,
          },
        },
      ],
      add_ons: {},
      settings: {},
    };

    AuthManager.register(data)
      .then(() => {
        this.setState({
          mode: MODE_SIGNUP,
          signUpStep: 2,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        Notify.error(error.message);
      });
  }

  _isKYCFormValid() {
    let { errorSlug, currency, country_short } = this.state;

    let error = null;
    if (errorSlug) {
      error = "This website url is not available, please try a different one";
    } else if (!country_short || country_short === "") {
      error = "Please select a country";
    } else if (!currency || currency === "") {
      error = "Please select a currency";
    }

    if (error) {
      Notify.error(error);
      return false;
    }
    return true;
  }

  _createAccount() {
    if (!this._isKYCFormValid()) {
      return;
    }

    let {
      first_name,
      last_name,
      currency,
      company_name,
      slug,
      country_short,
      director,
      executive,
      owner,
    } = this.state;

    let data = {
      country_short: country_short,
      currency: currency,
      persons: [
        {
          first_name,
          last_name,
          relationship: {
            director,
            executive,
            owner,
          },
        },
      ],
      representative: {
        relationship: {
          director,
          executive,
          owner,
        },
      },
    };

    this.setState({ isLoading: true });
    Backend.createAccount(data)
      .then((hash) => {
        let url = "";
        window.location = url;
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Notify.error(error.message);
      });
  }

  _checkSlug() {
    let { slug } = this.state;

    this.setState({ isLoadingSlug: true });
    Backend.slugify(slug)
      .then((response) => {
        if (response.slug != slug) {
          this.setState({ errorSlug: true, isLoadingSlug: false });
        } else {
          this.setState({ errorSlug: false, isLoadingSlug: false });
        }
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoadingSlug: false });
      });
  }

  _renderError() {
    let { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    );
  }

  _renderMFA() {
    const { mode, code, isLoading, response, mfaMethod } = this.state;

    return (
      <div
        className="login-form login-forgot"
        style={{
          display: mode === MODE_MFA ? "block" : "none",
        }}
      >
        <form
          className="form"
          noValidate="novalidate"
          id="kt_login_forgot_form"
        >
          <div className="pb-5 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Verify Your Identity
            </h3>
            <p className="text-muted font-weight-bold font-size-h6">
              {this._renderMFASubtitle(mfaMethod)}
            </p>
          </div>

          {this._renderError()}

          <div className="form-group">
            <span className="text-dark-75 ml-1">Code</span>
            <input
              className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
              type="text"
              name="code"
              autoComplete="off"
              value={code}
              placeholder="Code (or Backup Code)"
              onChange={(e) =>
                this.setState({ code: e.target.value, error: null })
              }
            />
            <div>
              {mfaMethod !== "app" && (
                <>
                  <span className="text-muted ml-1">
                    Didn't receive a code ?
                    <a
                      href="javascript:;"
                      tabIndex="4"
                      className="text-primary font-weight-bold text-hover-primary pt-5 ml-1"
                      style={{ textDecoration: "underline" }}
                      onClick={() => this._resendMFACode()}
                    >
                      Resend Code
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">
            <button
              className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  code: "",
                  mode: MODE_SIGNIN,
                  error: null,
                });
              }}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              onClick={(e) => {
                this._handleVerifyLogInClicked(e);
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading... " : "Verify"}
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    let { email, password, confirmPassword, isLoading, mode, code } =
      this.state;

    return (
      <div className="metr_v702 d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/* begin : Login Aside */}
          <div
            className="login-aside d-flex flex-column flex-row-auto"
            style={{ backgroundColor: "#2d97fb" }}
          >
            <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 pl-25 pr-25 text-center">
              <a href="#" className="mb-10">
                <img
                  src={window.General.Branding.Banner}
                  alt="Logo"
                  className="max-h-70px max-w-80per"
                />
              </a>
              <h3
                className="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-10"
                style={{ color: "#FFFFFF" }}
              >
                Take payment in a<br /> variety of different ways
              </h3>
            </div>
            <div
              className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
              style={{ backgroundImage: `url(${LOGIN_VISUAL})` }}
            ></div>
          </div>
          {/* end : Login Aside */}

          {/* <!--begin::Content--> */}
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div className="d-flex flex-column-fluid flex-center">
              {/* <!--begin::Signin--> */}
              <div
                className="login-form login-signin"
                style={{
                  display: mode === MODE_SIGNIN ? "block" : "none",
                }}
              >
                <form className="form" noValidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Welcome to {window.General.Branding.Name}
                    </h3>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Email
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value.trim(), error: null })
                      }
                      autoFocus
                      tabIndex="1"
                    />
                  </div>
                  <div className="form-group">
                    <PasswordInput
                      label={() => {
                        return (
                          <div className="d-flex justify-content-between mt-n5">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                              Password
                            </label>
                            <a
                              href="javascript:;"
                              tabIndex="4"
                              className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                              onClick={() =>
                                this.setState({
                                  email: "",
                                  mode: MODE_FORGOT_PASSWORD,
                                  error: null,
                                })
                              }
                            >
                              Forgot Password ?
                            </a>
                          </div>
                        );
                      }}
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      onChange={(password) => {
                        this.setState({ password, error: null });
                      }}
                      tabIndex="2"
                    />
                  </div>

                  <div className="pb-lg-0 pb-5 pull-right">
                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => {
                        const {response} = this.state

                        const ephemeralToken = response?.message?.ephemeral_token

                        this._handleLogInClicked(e);
                        ephemeralToken &&
                          this.setState({
                            mode: MODE_MFA,
                            error: null,
                          });
                      }}
                      disabled={isLoading}
                      tabIndex="3"
                    >
                      {isLoading ? "Loading... " : "Sign In"}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Signin-->

              <!--begin::MFA--> */}
              {this._renderMFA()}
              {/* <!--end::MFA--> */}

              {/*<!--begin::Forgot--> */}
              <div
                className="login-form login-forgot"
                style={{
                  display: mode === MODE_FORGOT_PASSWORD ? "block" : "none",
                }}
              >
                <form
                  className="form"
                  noValidate="novalidate"
                  id="kt_login_forgot_form"
                >
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Forgotten Password ?
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value.trim(), error: null })
                      }
                    />
                  </div>

                  <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          email: "",
                          password: "",
                          mode: MODE_SIGNIN,
                          error: null,
                        });
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleRequestResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading... " : "Request"}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::Forgot--> */}

              {/* <!--begin::reset--> */}
              <div
                className="login-form login-reset"
                style={{
                  display: mode === MODE_RESET_PASSWORD ? "block" : "none",
                }}
              >
                <form className="form" noValidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Reset Password
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      A verification code was sent to your email, please enter
                      it below along with your new password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Code
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Code"
                      name="code"
                      autoComplete="off"
                      value={code}
                      onChange={(e) =>
                        this.setState({ code: e.target.value, error: null })
                      }
                    />
                  </div>

                  <PasswordInput
                    ref={this.newPassword}
                    onChange={(password) =>
                      this.setState({ password, error: null })
                    }
                    onConfirmChange={(confirmPassword) =>
                      this.setState({
                        confirmPassword,
                        error: null,
                      })
                    }
                    labelClassName="font-size-h6 font-weight-bolder text-dark"
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    autoComplete="off"
                    showValidationCheck
                  />

                  <div className="form-group d-flex flex-wrap pb-lg-0 pull-right">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          email: "",
                          password: "",
                          mode: MODE_SIGNIN,
                          error: null,
                        });
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading... " : "Update Password"}
                    </button>
                  </div>
                </form>
              </div>
              {/* <!--end::reset--> */}
            </div>
            {/* <!--end::Content body--> */}
          </div>
          {/* end::Content */}
        </div>
      </div>
    );
  }
}
