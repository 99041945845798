import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import ImageEdit from "../../common/ImageEdit";

import UnconnectedProcessors from "./UnconnectedProcessors"
import StripeConnectProcessor from "./StripeConnectProcessor"
import AibConnectProcessor from "./AibConnectProcessor"

import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

import Select from "../../common/CustomSelect";

const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  },
]

export default class ApayloProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      company: props.company,
      data: props.data ||  {
        key: "",
        shared_secret: "",
      },
    }
  }

  _isFormValid(){
    let { data } = this.state

    let {
      key,
      shared_secret,
    } = data

    let error = null
    if(!key){
      error = "Please provide a valid key"
    }
    else if(!shared_secret){
      error = "Please provide a valid shared secret"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _connect(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectToApaylo(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-3 col-form-label">Api Key</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.key}
                  onChange={e => {
                    data.key = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Shared Secret</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.shared_secret}
                  onChange={e => {
                    data.shared_secret = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }
}
