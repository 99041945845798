import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import ImageEdit from "../../common/ImageEdit";

import UnconnectedProcessors from "./UnconnectedProcessors"

import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

export default class StripeConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      data: props.data || {

      },
    }
  }


  render() {
    let {

    } = this.state

    return (
      <>
        <Modal.Body>

          <div>
            Clients must connect to the Stripe processor themselves
          </div>

        </Modal.Body>

        <Modal.Footer>

            <button
                type="button"
                className="btn btn-outline-primary ml-3"
                onClick={() => this.props.onCancel()}
            >
              Ok
            </button>

        </Modal.Footer>

      </>
    )
  }
}
