import React from "react"

import ImageEdit from "../common/ImageEdit"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"

export default class DomainSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
    }
  }

  render() {
    let {
      website,
    } = this.state

    return (
      <div className="offset-xxl-2 col-xxl-8">
        <div className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <h4 className="mb-10 font-weight-bold text-dark">{window.General.Branding.Name} Domain Settings</h4>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">{window.General.Branding.Name} URL</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      myclient
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    value={ `.${window.General.WebsiteDomain}` }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
