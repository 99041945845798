import React from 'react'
import Modal from 'react-bootstrap/Modal'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import ImageEdit from '../common/ImageEdit';
import ColorPicker from "../common/ColorPicker";
import AsyncSelect from "../common/AsyncSelect";

import DemoCustomer from '../splink/DemoCustomer'
import DemoPaymentPlans from '../splink/DemoPaymentPlans'
import DemoCustomData from '../splink/DemoCustomData'
import DemoQuestions from '../splink/DemoQuestions'
import DemoPayments from '../splink/DemoPayments'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"

export default class DemoProductModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company,
      product: props.product,
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      product,
      company,
      error,
    } = this.state

    if(!product){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={"sub-modal"}
        dialogClassName={"modal-50"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group row">
            <label className="col-3 col-form-label">Item Name*</label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type="text"
                value={product.name}
                placeholder="Name"
                name={"name"}
                onChange={e => {
                  product.name = e.target.value
                  this.props.onUpdated(product)
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Image</label>
            <div className="col-9">
              <ImageEdit
                image={product.featured_image}
                hidePreview={false}
                cropImage={true}
                onUpdated={image => {
                  product.featured_image = image
                  this.props.onUpdated(product)
                }}
              />
            </div>
          </div>

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Payment Details
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>


          <DemoPaymentPlans
            showOptional={false}
            showCurrency={true}
            priceTitle="Amount"
            company={company}
            paymentPlans={product.payment_plans}
            updatePaymentPlan={(paymentPlan, index) => {
              product.payment_plans[index] = paymentPlan
              this.props.onUpdated(product)
            }}
          />


          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Questions
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoQuestions
            company={company}
            questions={product.questions}
            onUpdated={questions => {
              product.questions = questions
              this.props.onUpdated(product)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Payments
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoPayments
            company={company}
            payments={product.payments}
            onUpdated={payments => {
              product.payments = payments
              this.props.onUpdated(product)
            }}
          />



        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this.props.onHide()}
          >
            Ok
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
