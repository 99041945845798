import React from 'react'
import { withRouter } from "react-router-dom";

import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import Alert from './Alert'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

class AssociationAlert extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      asyncTasks: []
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getIncompleteAssociationTasks()
    .then(asyncTasks => {
      this.setState({ asyncTasks, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  render(){
    let {
      asyncTasks
    } = this.state

    if(!asyncTasks || asyncTasks.length == 0){
      return null
    }

    return asyncTasks.map(asyncTask => {
      let text = `Partner, ${asyncTask.data.name}, is being created for ${asyncTask.initiator.first_name}. An email will be sent to ${asyncTask.initiator.email} once complete`
      let onClick = () => {}
      let cta = null
      let type = "primary"

      if(asyncTask.status === "failed"){
        text = `Partner, ${asyncTask.data.name}, failed to create. ${asyncTask.initiator.email} has been alerted`
        cta = "Retry"
        type = "warning"

        onClick = () => this.props.history.push("/partners/add?async_task_id="+asyncTask.id)
      }

      return (
        <Alert
          text={text}
          cta={cta}
          type={type}
          closable={false}
          onClick={onClick}
        />
      )
    })
  }
}

export default withRouter(AssociationAlert);
