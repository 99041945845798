import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LeftMenu from "./components/LeftMenu";
import MobileHeader from "./components/MobileHeader";
import Footer from "./components/Footer";
import Header from "./components/Header";

import AuthManager from "../utils/AuthManager";
import Dashboard from "./Dashboard";
import Signups from "./Signups";
import Payments from "./Payments";
import PaymentAttempts from "./PaymentAttempts";
import Requests from "./Requests";
import Recurring from "./Recurring";
import DemoAccounts from "./DemoAccounts";
import CompanyMembers from "./CompanyMembers";
import Associations from "./Associations";
import Association from "./Association";
import TeamMembers from "./TeamMembers";
import Settings from "./Settings";
import Products from "./Products";
import Channels from "./Channels";
import Packages from "./Packages";
import Package from "./Package";
import DomainSettings from "./DomainSettings";
import CompanyProcessors from "./CompanyProcessors";
import Roles from "./Roles";

import Event from "../utils/Event";
import Mode from "../utils/Mode";
import AsyncStorage from "../utils/AsyncStorage";

import MFAAlert from "./components/alert/MFAAlert";
import AssociationAlert from "./components/alert/AssociationAlert";

const KEY_DEMO_DATA = "demoData";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      isItPaymentAttemptsPage: false,
    };
    this._setupEvents();
  }

  _setupEvents() {
    Event.on("DEMO_TOGGLE", () => {
      this.setState({ refresh: true }, () => {
        this.setState({ refresh: false });
      });
    });
  }

  _renderRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/sign-ups" component={Signups} />
        <Route exact path="/payments" component={Payments} />
        <Route
          exact
          path="/settings/payment-attempts"
          component={PaymentAttempts}
        />
        <Route exact path="/requests" component={Requests} />
        <Route exact path="/recurring" component={Recurring} />
        <Route exact path="/demo-accounts" component={DemoAccounts} />
        <Route exact path="/company-members" component={CompanyMembers} />
        <Route exact path="/shop" component={Products} />
        <Route exact path="/channels" component={Channels} />
        <Route exact path="/roles" component={Roles} />
        <Route exact path="/partners" component={Associations} />
        <Route exact path="/partners/add" component={Association} />
        <Route exact path="/partners/:association_id" component={Association} />
        <Route
          exact
          path="/companies/:company_id/processors"
          component={CompanyProcessors}
        />
        <Route exact path="/packages" component={Packages} />
        <Route exact path="/packages/add" component={Package} />
        <Route exact path="/packages/:package_id" component={Package} />
        <Route exact path="/team-members" component={TeamMembers} />
        <Route path="/domain" component={DomainSettings} />
        <Route path="/settings" component={Settings} />
        <Redirect to="/404" />
      </Switch>
    );
  }

  render() {
    let { isItPaymentAttemptsPage } = this.state;

    if (window.location.pathname.indexOf("payment-attempts") > -1) {
      isItPaymentAttemptsPage = true;
    }

    return (
      <>
        <MobileHeader />

        <div
          className="d-flex flex-column flex-root"
          style={{ minHeight: "100%" }}
        >
          <div className="d-flex flex-row flex-column-fluid page">
            <LeftMenu />
            <div
              key={this.state.refresh}
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div
                className={`content d-flex flex-column flex-column-fluid ${
                  isItPaymentAttemptsPage ? " py-0 " : ""
                }`}
                id="kt_content"
              >
                <div className={"d-flex flex-column-fluid"}>
                  <div className={"container-fluid"}>
                    <MFAAlert />

                    {this._renderRoutes()}
                  </div>
                </div>
              </div>
              <Footer hideFooter={isItPaymentAttemptsPage} />
            </div>
          </div>
          {/*  begin::Quick Panel  */}
          {/*  end::Quick Panel  */}
          <div id="kt_scrolltop" className="scrolltop">
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    x="11"
                    y="10"
                    width="2"
                    height="10"
                    rx="1"
                  ></rect>
                  <path
                    d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </>
    );
  }
}
