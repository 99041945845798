import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import Mode from './Mode'

import StripeConnectProcessor from "../pages/components/modal/processor/StripeConnectProcessor"
import AibConnectProcessor from "../pages/components/modal/processor/AibConnectProcessor"
import EMerchantPayConnectProcessor from "../pages/components/modal/processor/EMerchantPayConnectProcessor"
import CardConnectProcessor from "../pages/components/modal/processor/CardConnectProcessor"
import ApayloProcessor from "../pages/components/modal/processor/ApayloProcessor"
import TSYSConnectProcessor from "../pages/components/modal/processor/TSYSConnectProcessor"
import BoipaConnectProcessor from "../pages/components/modal/processor/BoipaConnectProcessor"
import TokenConnectProcessor from "../pages/components/modal/processor/TokenConnectProcessor"

const STRIPE_LOGO = require("../assets/media/logos/processors/stripe.png")
const EMERCHANTPAY_LOGO = require("../assets/media/logos/processors/emerchantpay.jpeg")
const AIB_LOGO = require("../assets/media/logos/processors/aib.png")
const CARD_CONNECT_LOGO = require("../assets/media/logos/processors/card_connect.png")
const APAYLO_LOGO = require("../assets/media/logos/processors/apaylo.jpeg")
const TSYS_LOGO = require("../assets/media/logos/processors/tsys.png")
const BOIPA_LOGO = require("../assets/media/logos/processors/boipa.jpeg")
const TOKEN_LOGO = require("../assets/media/logos/processors/token.png")

const CONNECT_PROCESSORS = [
  {
    key: "stripe",
    component: StripeConnectProcessor
  },
  {
    key: "aib",
    component: AibConnectProcessor
  },
  {
    key: "emerchant",
    component: EMerchantPayConnectProcessor
  },
  {
    key: "card_connect",
    component: CardConnectProcessor
  },
  {
    key: "apaylo",
    component: ApayloProcessor
  },
  {
    key: "tsys",
    component: TSYSConnectProcessor
  },
  {
    key: "boipa",
    component: BoipaConnectProcessor
  },
  {
    key: "token",
    component: TokenConnectProcessor
  }
]

export default class Processors {

  static getImage(processor){
    if(processor.slug.indexOf("stripe") > -1){
      return STRIPE_LOGO
    }
    else if(processor.slug.indexOf("emerchant") > -1){
      return EMERCHANTPAY_LOGO
    }
    else if(processor.slug.indexOf("aib") > -1){
      return AIB_LOGO
    }
    else if(processor.slug.indexOf("card_connect") > -1){
      return CARD_CONNECT_LOGO
    }
    else if(processor.slug.indexOf("apaylo") > -1){
      return APAYLO_LOGO
    }
    else if(processor.slug.indexOf("tsys") > -1){
      return TSYS_LOGO
    }
    else if(processor.slug.indexOf("boipa") > -1){
      return BOIPA_LOGO
    }
    else if(processor.slug.indexOf("token") > -1){
      return TOKEN_LOGO
    }
    else if(processor.slug.indexOf("fake") > -1){
      return
    }

    return null
  }

  static getConnectComponent(processor){
    for (var i = 0; i < CONNECT_PROCESSORS.length; i++) {
      const connectProcessor = CONNECT_PROCESSORS[i]
      if(processor.slug.indexOf(connectProcessor.key) > -1){
        return connectProcessor.component
      }
    }

    return null
  }

}
