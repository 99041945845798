import React from "react"

import BaseTable from "./BaseTable"

import moment from "moment"

import {Link} from "react-router-dom"

import AddCompanyModal from "../modal/AddCompanyModal";
import ConfirmationModal from "../modal/ConfirmationModal";

import DeleteCell from "./cells/DeleteCell";
import RecurringCell from "./cells/RecurringCell";
import PillCell from "./cells/PillCell";
import CustomerCell from "./cells/CustomerCell";
import ExpanderCell from "./cells/ExpanderCell";
import DownloadCell from "./cells/DownloadCell";
import SendCell from "./cells/SendCell";

import DisplayCDC from "../splink/DisplayCDC";

import Notify from "../../../utils/Notify";
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Currency from "../../../utils/Currency";
import AuthManager from '../../../utils/AuthManager'


const TYPE_FILTERS = {
  name: {
    api: "recurring",
    display: "recurring"
  },
  values: [{
    label: "All Payments",
    value: null
  },
    {
      label: "Recurring",
      value: "true"
    },
    {
      label: "One-Off",
      value: "false"
    }]
}

const STATUS_FILTERS = {
  name: {
    api: "type",
    display: "type"
  },
  values: [{
    label: "All Add-Ons",
    value: null
  },
    {
      label: "Take",
      value: "take"
    },
    {
      label: "Request",
      value: "payment_request"
    },
    {
      label: "Shop",
      value: "shop"
    }]
}

const FILTERS = [
  TYPE_FILTERS,
  STATUS_FILTERS
]

export default class PaymentsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._refresh())
  }

  _refresh(){
    this.table.current.refresh()
  }

  _getColumns() {
    let columns = [
      {
        expander: true,
        Expander: ({ isExpanded, ...props }) => (
          <ExpanderCell
            isExpanded={isExpanded}
            show={() => {
              let payment = props.original
              let customer = payment.customer
              let showExpander = customer.shipping_address || customer.note || payment.answers.length > 0
              return showExpander || true // always show expander due to charge id
            }}
          />
        )
      },
      {
        Header: 'Company',
        id: 'company.name',
        accessor: 'company.name',
        width: 160
      }
    ]

    if(AuthManager.isPlatformAdmin()){
      columns.push({
        Header: 'Partner',
        id: 'company.association.name',
        sortable: true,
        exportable: true,
        accessor:  payment => payment.company.association?.name || "-"
      })
    }


    columns = [
      ...columns,
      {
        Header: 'Name',
        id: 'customer.first_name',
        Cell: rowInfo => {
          let payment = rowInfo.original
          return (
            <CustomerCell
              customer={payment.customer}
            />
          )
        },
        width: 160
      },
      {
        Header: 'Amount',
        id: 'payment_plan.total',
        accessor:  payment => Currency.format(payment.payment_plan.currency.symbol, payment.payment_plan.total)
      },
      {
        Header: 'Type',
        id: 'type',
        exportable: false,
        Cell: rowInfo => {
          let payment = rowInfo.original
          let text = payment.payment_plan.product ? 'Shop' : payment.payment_plan.payment_request ? 'Request' : 'Take'
          let className = payment.payment_plan.product ? 'label-light-info' : payment.payment_plan.payment_request ? 'label-light-danger' : 'label-light-primary'
          return (
            <PillCell
              text={text}
              className={className}
            />
          )
        }
      },
      {
        Header: 'Recurring',
        id: 'subscription',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let payment = rowInfo.original
          if(payment.subscription || payment.processor_payment_data.subscription_id){
            return <RecurringCell/>
          }
          return null
        }
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: (rowInfo) => {
          let payment = rowInfo.original
          let className = 'label-light-success'
          let text = General.snakeCaseToTitleCase(payment.status)
          if(payment.status == "pending"){
            className = 'label-light-info'
          }
          if(payment.refunded_at){
            text = "Refunded"
            className = 'label-light-warning'
          }
          return <PillCell text={text} className={className} />
        },
      },
      {
        Header: 'Date Paid',
        id: 'paid_at',
        accessor: payment => moment(payment.paid_at).format('DD MMM YYYY') || '-'
      },
      {
        Header: 'Refund Date',
        id: 'deleted_at',
        accessor: payment => payment.deleted_at ? moment(payment.deleted_at).format('DD MMM YYYY') : '-'
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 150,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let payment = rowInfo.original
          return (
            <span style={{overflow: 'visible', position: 'relative', width: 150}}>
              <SendCell
                tooltip={"Resend Receipt"}
                onSendClicked={() => {
                  this.setState({
                    paymentToResend: payment,
                    showConfirmationModal: true
                  })
                }}
              />
            </span>
          )
        }
      }
    ]

    return columns
  }

  _resendReceipt(){
    let {
      paymentToResend
    } = this.state

    Backend.resendPaymentReceipt(paymentToResend).then(() => {
      Notify.success("Receipt has been resent successfully.")
      this.setState({
        paymentToResend: null,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        paymentToResend: null,
        showConfirmationModal: false
      })
    })
  }

  _renderToolBar(){
    if(this.props.latestResults){
      return (
        <Link to="/payments" className="btn btn-light-primary font-weight-bolder ml-4">
          <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
               viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <rect fill="#000000" opacity="0.3" x="2" y="5" width="20" height="14" rx="2"></rect>
                <rect fill="#000000" x="2" y="8" width="20" height="3"></rect>
                <rect fill="#000000" opacity="0.3" x="16" y="14" width="4" height="2" rx="1"></rect>
              </g>
            </svg>
          </span>
          View All Payments
        </Link>
      )
    }

    return (
      <>

      </>
    )
  }

  _renderConfirmationModal(){
    let {
      paymentToResend,
      showConfirmationModal
    } = this.state

    let message = ""
    if(paymentToResend){
      message = "Are you sure you want to resend the receipt for this payment?"
    }

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={message}
        onConfirm={() => {
          if(paymentToResend){
            this._resendReceipt()
          }
        }}
        onHide={() => {
          this.setState({
            payment: null,
            showConfirmationModal: false
          })
        }}
      />
    )
  }

  _renderDescription(payment){
    return (
      <div style={{backgroundColor: '#fff', paddingTop: 15, paddingLeft: 15}}>
        {
          payment.processor_payment_data &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>ID: </b>
            {payment.processor_payment_data.charge_id ||
             payment.processor_payment_data.transaction_id ||
             payment.processor_payment_data.ipg_transaction_id ||
             payment.processor_payment_data.retref ||
             payment.processor_payment_data.transaction_number ||
             payment.processor_payment_data.AchResponse?.transactionID ||
             payment.processor_payment_data.SaleResponse?.transactionID ||
             payment.processor_payment_data.merchantTxId ||
             payment.processor_payment_data.id
           }
            <br/>
          </p>
        }
        {payment.status != "completed" && (
          <p className={'mb-3'} style={{ whiteSpace: 'pre-wrap' }}>
            <b>Processor Payment Status: </b>
            {payment.processor_payment_status}
            <br />
          </p>
        )}
        {
          payment.customer.shipping_address &&
          <p className={"mb-3"}>
            <b>Customer Address: </b>
            {`
            ${payment.customer.shipping_address.line_1},
            ${payment.customer.shipping_address.line_2},
            ${payment.customer.shipping_address.city},
            ${payment.customer.shipping_address.state},
            ${payment.customer.shipping_address.country},
            `}
          </p>
        }
        {
          payment.customer.note &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Customer Note: </b>
            {payment.customer.note}<br/>
          </p>
        }
        {
          payment.payment_plan.payment_link &&
          <p className={"mb-3"} style={{whiteSpace: "pre-wrap"}}>
            <b>Channel: </b>
            {payment.payment_plan.payment_link.title}<br/>
          </p>
        }
        {payment.products.length == 0 &&
          payment.answers.length > 0 && (
            <>
              <DisplayCDC answers={payment.answers} />
            </>
          )}
        <br />
        {payment.products.length > 0 && <h5>Products</h5>}
        {payment.products.map((product, index) => {
          let name = product.name
          let answers = product.answers

          let price = Currency.format(
            payment.payment_plan.currency.symbol,
            product.product_total * product.quantity
          )

          if(product.quantity > 1){
            name = `${product.quantity}x ${product.name}`
            price += ` (${Currency.format(
              payment.payment_plan.currency.symbol,
              product.product_total
            )} each)`
          }

          return (
            <>
              <hr />
              <p>
                <b>Product: </b>
                { name }
              </p>
              <p>
                <b>Price: </b>
                { price }
              </p>
              <DisplayCDC answers={answers} />
            </>
          )
        })}
      </div>
    )
  }

  render() {
    let {

    } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Payments}?min_date=${this.state.dateFrom?.format('YYYY-MM-DD') || ''}&max_date=${this.state.dateTo?.format('YYYY-MM-DD') || ''}`}
          noDataMessage={"No payment found"}
          deletedObjectLabel="Refunded"
          title={this.props.title}
          columns={columns}
          showExport={false}
          filters={!this.props.latestResults && FILTERS}
          defaultSorted={[
            {
              id: "paid_at",
              desc: true
            }
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          SubComponent={row => this._renderDescription(row.original)}
          renderToolbar={() => this._renderToolBar()}
        />
        { this._renderConfirmationModal() }
      </div>
    )
  }
}

PaymentsTable.defaultProps = {
  latestResults: false
}
