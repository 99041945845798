import React, { Component } from "react";

import Div from "../layouts/Div";

import VerificationMethod from "./VerificationMethod";

import BackupCodesModal from "./modal/BackupCodesModal";
import RegenerateBackupCodesModal from "./modal/RegenerateBackupCodesModal";
import ActivateMFAMethodModal from "./modal/ActivateMFAMethodModal";
import DeactivateMFAMethodModal from "./modal/DeactivateMFAMethodModal";
import DefaultMFAMethodModal from "./modal/DefaultMFAMethodModal";

import AuthManager from "../../../utils/AuthManager";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class MultiFactorAuthentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMethods: null,
      isLoading: true,
      activeVerificationMethods: [],
      inactiveVerificationMethods: [],
    };
  }

  componentDidMount() {
    this._setActiveMethods();
  }

  _setActiveMethods() {
    this.setState({ isLoading: true });

    AuthManager.getActiveMethods()
      .then((activeMethods) => {
        let activeVerificationMethods = VERIFICATION_METHODS.filter(
          (verificationMethod) => {
            let activeMethod = activeMethods.find(
              (activeMethod) => activeMethod.name === verificationMethod.method
            );

            return activeMethod != null;
          }
        );

        let inactiveVerificationMethods = VERIFICATION_METHODS.filter(
          (verificationMethod) => {
            let activeMethod = activeMethods.find(
              (activeMethod) => activeMethod.name === verificationMethod.method
            );

            if (
              verificationMethod.method === "sms_api" &&
              AuthManager.currentUser.user.role != "company_member"
            ) {
              return false;
            }

            return activeMethod == null;
          }
        );

        this.setState({
          activeMethods,
          activeVerificationMethods,
          inactiveVerificationMethods,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _activateMethod(verificationMethod) {
    let selectedVerificationMethod = General.clone(verificationMethod);
    this.setState({ isLoading: true, selectedVerificationMethod });

    AuthManager.activateMethod(verificationMethod.method)
      .then((methodDetails) => {
        selectedVerificationMethod.details = methodDetails.details
        this.setState({
          methodDetails,
          isLoading: false,
          showActivateMFAMethodModal: true,
        });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _deactivateMethod(verificationMethod) {
    this.setState({
      showDeactivateMFAMethodModal: true,
      selectedVerificationMethod: verificationMethod,
    });
  }

  _setDefaultMethod(verificationMethod) {
    this.setState({
      showDefaultMFAModal: true,
      selectedVerificationMethod: verificationMethod,
    });
  }

  _renderVerificationMethod(verificationMethod) {
    const { activeMethods } = this.state;

    let activeMethod = activeMethods.find(
      (method) => method.name == verificationMethod.method
    );

    verificationMethod.active = activeMethod != null;
    verificationMethod.primary = activeMethod?.is_primary;

    if (
      verificationMethod.method == "sms_api" &&
      AuthManager.currentUser.user.role != "company_member"
    ) {
      return null;
    }

    verificationMethod.canDeactivate =
      activeMethods.length == 1 || !verificationMethod.primary;

    return (
      <>
        <VerificationMethod
          verificationMethod={verificationMethod}
          onActivateClicked={() => this._activateMethod(verificationMethod)}
          onDeactivateClicked={() => this._deactivateMethod(verificationMethod)}
          onRegenerateBackupCodesClicked={() => {
            this.setState({
              showRegenerateBackupCodesModal: true,
              selectedVerificationMethod: verificationMethod,
            });
          }}
          onMakeDefaultClicked={() =>
            this._setDefaultMethod(verificationMethod)
          }
        />
      </>
    );
  }

  render() {
    const {
      isLoading,
      backupCodes,
      activeMethods,
      showDefaultMFAModal,
      showBackupCodesModal,
      selectedVerificationMethod,
      showActivateMFAMethodModal,
      showDeactivateMFAMethodModal,
      showRegenerateBackupCodesModal,
      activeVerificationMethods,
      inactiveVerificationMethods,
    } = this.state;

    if (activeMethods == null) {
      return null;
    }

    let primaryMethod = activeMethods.find(
      (activeMethod) => activeMethod?.is_primary
    );

    return (
      <Div className="col" disabled={isLoading}>
        <h4 className="mb-5 font-weight-bold text-dark">
          Multi Factor Authentication
        </h4>
        <p className="text-grey mb-10">
          Increase security for your account by using multiple authentication
          steps.
        </p>

        {activeVerificationMethods.map((verificationMethod) =>
          this._renderVerificationMethod(verificationMethod)
        )}

        {inactiveVerificationMethods.length > 0 && (
          <>
            <div
              style={{
                borderBottom: "2px solid whitesmoke",
                marginBottom: "20px",
              }}
            />

            <div className="font-weight-bold font-size-h6">
              Add Authentication Step
            </div>

            {inactiveVerificationMethods.map((verificationMethod) =>
              this._renderVerificationMethod(verificationMethod)
            )}
          </>
        )}

        {primaryMethod && (
          <p className="text-grey text-center mt-18 ml-1">
            If you lose your mobile device you can
            <a
              href="javascript:;"
              tabIndex="4"
              className="text-primary font-weight-bold text-hover-primary pt-5 ml-1 mr-1"
              style={{ textDecoration: "underline" }}
              onClick={() =>
                this.setState({
                  showRegenerateBackupCodesModal: true,
                })
              }
            >
              generate backup codes
            </a>
            to sign in to your account.
          </p>
        )}

        {showDeactivateMFAMethodModal && (
          <DeactivateMFAMethodModal
            show={showDeactivateMFAMethodModal}
            verificationMethod={selectedVerificationMethod}
            onHide={() =>
              this.setState({ showDeactivateMFAMethodModal: false })
            }
            onDeactivated={() => {
              this.setState(
                {
                  showDeactivateMFAMethodModal: false,
                },
                () => this._setActiveMethods()
              );
            }}
          />
        )}
        {showActivateMFAMethodModal && (
          <ActivateMFAMethodModal
            show={showActivateMFAMethodModal}
            verificationMethod={selectedVerificationMethod}
            onHide={() => this.setState({ showActivateMFAMethodModal: false })}
            onActivated={(backupCodes) => {
              this.setState(
                {
                  backupCodes,
                  showBackupCodesModal: true,
                  showActivateMFAMethodModal: false,
                },
                () => this._setActiveMethods()
              );
            }}
          />
        )}
        {showBackupCodesModal && backupCodes && (
          <BackupCodesModal
            show={showBackupCodesModal}
            backupCodes={backupCodes}
            verificationMethod={selectedVerificationMethod}
            onHide={() => {
              this.setState(
                {
                  backupCodes: null,
                  showBackupCodesModal: false,
                },
                () => this._setActiveMethods()
              );
            }}
          />
        )}
        {showRegenerateBackupCodesModal && (
          <RegenerateBackupCodesModal
            show={showRegenerateBackupCodesModal}
            verificationMethod={primaryMethod}
            onRegenerated={(backupCodes) => {
              this.setState({
                backupCodes,
                showBackupCodesModal: true,
                showRegenerateBackupCodesModal: false,
              });
            }}
            onHide={() => {
              this.setState({
                selectedVerificationMethod: null,
                showRegenerateBackupCodesModal: false,
              });
            }}
          />
        )}
        {showDefaultMFAModal && (
          <DefaultMFAMethodModal
            show={showDefaultMFAModal}
            newVerificationMethod={selectedVerificationMethod}
            currentPrimaryMethodName={primaryMethod?.name}
            onUpdated={() => {
              this._setActiveMethods();
              this.setState({
                showDefaultMFAModal: false,
              });
            }}
            onHide={() => {
              this.setState({
                showDefaultMFAModal: false,
              });
            }}
          />
        )}
      </Div>
    );
  }
}

export const VERIFICATION_METHODS = [
  {
    title: "Phone Number",
    subtitle: "Receive codes on your phone via SMS.",
    method: "sms_twilio",
  },
  {
    title: "Google Authenticator / Authy",
    subtitle: "Connect an authenticator app that generates codes.",
    method: "app",
  },
  {
    title: "Mobile App",
    subtitle: `Connect your ${window.General.Branding.Name} App to receive codes.`,
    method: "sms_api",
  },
];
