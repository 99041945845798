import React from "react"
import DateRange from "./components/common/DateRange";
import StatCard from "./components/common/StatCard";
import CompaniesTable from "./components/tables/CompaniesTable";
import PaymentRequestsTable from "./components/tables/PaymentRequestsTable";
import PaymentsTable from "./components/tables/PaymentsTable";
import PaymentBreakdown from "./components/stats/PaymentBreakdown";
import MonthlyPaymentVolume from "./components/stats/MonthlyPaymentVolume";
import Event from "../utils/Event";

import moment from 'moment'

import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import Stats from "../utils/Stats";

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      dateFrom: null,
      dateTo: null,
      currency: 0
    }
  }

  componentDidMount(){
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    let dateTo = moment()
    let dateFrom = moment().add(-1, "years")

    Backend.getProcesseedStats(dateFrom.toISOString(), dateTo.toISOString())
      .then(stats => {
        this.setState({
          stats: Stats.getProcesseedStats(stats.admin?.processed || stats.association?.processed)
        })
      })
  }

  _renderStatCards(){
    let {
      stats
    } = this.state

    if (!stats) return null


    for (var key in stats) {
        // check if the property/key is defined in the object itself, not in parent
        if (stats.hasOwnProperty(key)) {
            stats[key] = Currency.format(window.General.DefaultCurrency.symbol, stats[key])
        }
    }

    let processedStats = [
      {
        amount: stats.processed_today,
        title: 'Processed Today'
      },
      {
        amount: stats.processed_last_7_days,
        title: 'Processed Last 7 Days'
      },
      {
        amount: stats.processed_month,
        title: 'Processed Last 30 Days'
      },
      {
        amount: stats.commission_today,
        title: 'Commission Today'
      },
      {
        amount: stats.commission_last_7_days,
        title: 'Commission Last 7 Days'
      },
      {
        amount: stats.commission_month,
        title: 'Commission Lats 30 Days'
      },
    ]
    return processedStats.map(stat => (
        <StatCard
          amount={stat.amount}
          title={stat.title}
          icon={'graph'}
        />
    ))
  }

  render() {
    let {
      dateFrom,
      dateTo
    } = this.state

    return (
      <>
        <DateRange
          dateFrom={dateFrom}
          dateTo={dateTo}
          onFromDateUpdated={(dateFrom) => {
            this.setState({
              dateFrom
            })
          }}
          onToDateUpdated={(dateTo) => {
            this.setState({
              dateTo
            })
          }}
        />

        <div className={'row'}>
          <div className={'col-xl-4'}>
            <PaymentBreakdown
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
          <div className={'col-xl-8'}>
            <MonthlyPaymentVolume
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </div>
          {this._renderStatCards()}
        </div>

        <CompaniesTable
          title={'Latest Signups'}
          showExport={false}
          latestResults={true}
        />

        <br/>

        <PaymentRequestsTable
          title={'Latest Requests Sent'}
          showExport={false}
          latestResults={true}
        />

        <br/>

        <PaymentsTable
          title={'Latest Payments Received'}
          showExport={false}
          latestResults={true}
        />
      </>
    )
  }

}
