import React from "react"

import Div from "../layouts/Div"

import ImageEdit from "../common/ImageEdit"

import Alert from "../alert/Alert"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class ExistingDomainSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      domain: AuthManager.currentUser.association.associate,
      loading: false,
      updating: false,
    }
  }

  _transfer(){
    let {
      name
    } = this.state

    this.setState({ updating: true })

    Backend.transferDomain(name)
    .then(domain => {
      this.props.onTransferredDomain(domain)
    })
    .catch(error => {
      this.setState({
        updating: false
      })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      dns,
      mode,
      name,
      loading,
      updating
    } = this.state

    return (
      <Div disabled={updating} className="offset-xxl-2 col-xxl-8 use-existing-domain">
        <div className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <h4 className="mb-10 font-weight-bold text-dark">{window.General.Branding.Name} Domain Settings</h4>

            <div className="form-group row mt-5">
              <label className="col-lg-4 col-form-label">Domain:</label>
              <div className="col-lg-8 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      https://
                    </span>
                  </div>
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-solid"
                    value={ name }
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={() => {
                    if(!name || name === ""){
                      Notify.error("Please enter a valid domain")
                      return
                    }

                    this._transfer()
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>

          </div>
        </div>
      </Div>
    )
  }
}
