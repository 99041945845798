import React from 'react'
import $ from 'jquery'

import bigDecimal from 'js-big-decimal'

import moment from 'moment'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PasswordInput from './components/common/PasswordInput'

import Section from './components/common/Section'

import RecurringCell from './components/tables/cells/RecurringCell'

import EditCell from './components/tables/cells/EditCell'
import RandomiseCell from './components/tables/cells/RandomiseCell'
import DeleteCell from './components/tables/cells/DeleteCell'

import CheckCell from './components/tables/cells/CheckCell'

import DemoPaymentModal from './components/modal/DemoPaymentModal'
import DemoPaymentRequestModal from './components/modal/DemoPaymentRequestModal'
import DemoProductModal from './components/modal/DemoProductModal'
import DemoPaymentLinkModal from './components/modal/DemoPaymentLinkModal'

import DemoPayments from './components/splink/DemoPayments'

import Div from './components/layouts/Div'

import Alert from './components/alert/Alert'

import ImageEdit from './components/common/ImageEdit'
import AsyncSelect from './components/common/AsyncSelect'
import SwatchColorPicker from './components/common/SwatchColorPicker'

import General from '../utils/General'
import Partner from '../utils/Partner'
import Splink from '../utils/Splink'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'
import Backend from '../utils/Backend'
import Color from '../utils/Color'

import LineChart from './components/chart/LineChart'

export default class Association extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      loading: true,
    }
  }

  _getState(props) {
    let association = props.association || {
      rate: 0,
      link_name: 'splink',
      terms_url: '',
      primary_color: '#3699ff',
      text_on_primary_color: '#ffffff',
      take_color: '#3699ff',
      request_color: '#f64e60',
      shop_color: '#1BC5BD',
      recurring_color: '#3699ff',
      associates: [
        {
          user: {},
        },
      ],
      companies: [
        Partner.createCompany(),
        Partner.createCompany(),
        Partner.createCompany(),
      ],
      rates: {
        default: {
          card: null
        }
      }
    }

    association = this._removeHTTP(association)

    return {
      ...props,
      association,
      editMode: false,
    }
  }

  componentDidMount() {
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let id = params.get('async_task_id')
    let associationId = this.props.match.params.association_id
    if (id) {
      this._setupAsyncTask(id)
    } else if (associationId) {
      this._setupAssociation(associationId)
    } else {
      this.setState({ loading: false })
    }
  }

  _setupAsyncTask(id) {
    Backend.getAsyncTask(id)
      .then((asyncTask) => {
        if (asyncTask.type !== 'create_association') {
          Notify.error('invalid task id')
          return
        }
        this.setState({
          asyncTask,
          association: asyncTask.data._raw,
          loading: false,
        })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _setupAssociation(id) {
    Backend.getAssociation(id)
      .then((association) => {
        association = this._removeHTTP(association)
        this.setState({ association, loading: false, editMode: true })
      })
      .catch((error) => {
        Notify.error(error.message)
      })
  }

  _isFormValid() {
    let { association } = this.state

    let error = null

    if (!association.name) {
      error = 'Please enter a partner name'
    }

    if (!error && association.associates) {
      association.associates.map((member) => {
        let { first_name, last_name, email } = member.user

        if (!first_name) {
          error = 'Please enter a first name'
        } else if (!last_name) {
          error = 'Please enter a last name'
        } else if (!email) {
          error = 'Please enter an email'
        }
      })
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAssociation() {
    let { editMode, association } = this.state

    if (editMode) {
      this._updateAssociation()
      return
    }

    if (!this._isFormValid()) {
      return
    }

    this._addAssociation()
  }

  _addAssociation() {
    let { association, asyncTask } = this.state

    this.setState({ loading: true })
    Backend.addAssociation(association)
      .then((association) => {
        Notify.success('Partner Add Request Created Successfully')
        if (!asyncTask) {
          this._goToPartnersPage()
        } else {
          Backend.deleteAsyncTask(asyncTask)
            .then(() => this._goToPartnersPage())
            .catch((error) => this._goToPartnersPage())
        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _updateAssociation() {
    let { association } = this.state

    this.setState({ loading: true })
    Backend.updateAssociation(association)
      .then((association) => {
        Notify.success('Partner Updated Successfully')
        this._goToPartnersPage()
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _goToPartnersPage() {
    this.setState({ loading: false })
    this.props.history.push('/partners')
  }

  _removeHTTP(association) {
    let urlKeys = ['terms_url', 'support_url', 'not_found_url', 'add_ons_url']
    for (var i = 0; i < urlKeys.length; i++) {
      let key = urlKeys[i]

      association[key] = association[key]
        ?.replace('http://', '')
        .replace('https://', '')
    }
    return association
  }

  _updateCompany(company, index) {
    let { association } = this.state
    association.companies[index] = company
    this.setState({ association })
  }

  _updateCompanyMemberUser(e, companyIndex) {
    const selectedCompany = this.state.association.companies[companyIndex]
    let member = selectedCompany.members[0]
    member.user[e.target.name] = e.target.value

    selectedCompany.members[0] = member
    this._updateCompany(selectedCompany, companyIndex)
  }

  _renderSection(
    title,
    content,
    collapsed = false,
    collapsible = false,
    subtitle = null
  ) {
    return (
      <Section
        title={title}
        subtitle={subtitle}
        content={content}
        collapsed={collapsed}
        collapsible={collapsible}
      />
    )
  }

  _renderPartnerForm() {
    let { editMode, association, selectedCompany } = this.state

    let associate = editMode ? null : association.associates[0]
    let bridgeClassName = selectedCompany ? 'bridge-show' : 'bridge-hide'

    let logoLight, logoDark, favIcon
    if (association.logo_banner) {
      logoLight = association.logo_banner.banner
    }
    if (association.logo_small) {
      logoDark = association.logo_small.banner
    }
    if (association.favicon) {
      favIcon = association.favicon.banner
    }

    const data = {
      labels: ['1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgba(255, 99, 132, 0.2)',
        },
      ],
    }

    let cardRate = association.rates?.default?.card || {}

    return (
      <>
        {/* starts : 01 Section Details */}
        {this._renderSection(
          'Details',
          <div className="section details">
            {/* starts : Partner Name */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Partner Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="name"
                  placeholder={'Partner Name'}
                  className="form-control form-control-solid"
                  value={association.name}
                  onChange={(e) => {
                    association.name = e.target.value
                    this.setState({ association, error: null })
                  }}
                />
              </div>
            </div>
            {/* end : Partner Name */}

            {/* starts : Payment Link Name */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                Payment Link Name
              </label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="link_name"
                  placeholder={'Payment Link Name'}
                  className="form-control form-control-solid"
                  value={association.link_name}
                  onChange={(e) => {
                    association.link_name = e.target.value
                    this.setState({ association, error: null })
                  }}
                />
              </div>
            </div>
            {/* end : Payment Link Name */}
          </div>,
          false,
          true
        )}
        {/* end : 01 Section Details */}

        {this._renderSection(
          'Links & Tracking Codes',
          <div className="section details">
            {/* starts : Terms Url: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">Terms Url:</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">https://</span>
                  </div>
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.terms_url}
                    onChange={(e) => {
                      association.terms_url = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : Terms Url: */}

            {/* starts : Support Url: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">Support Url:</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">https://</span>
                  </div>
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.support_url}
                    onChange={(e) => {
                      association.support_url = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : Support Url: */}

            {/* starts : 404 Url: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">404 Url:</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">https://</span>
                  </div>
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.not_found_url}
                    onChange={(e) => {
                      association.not_found_url = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : 404 Url: */}

            {/* starts : Add Ons Url: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">Add Ons Url:</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">https://</span>
                  </div>
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.add_ons_url}
                    onChange={(e) => {
                      association.add_ons_url = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : Add Ons Url: */}

            {/* starts : Client GTM ID: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">
                Client Dashboard GTM ID:
              </label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.client_gtm_id}
                    onChange={(e) => {
                      association.client_gtm_id = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : Client GTM ID: */}

            {/* starts : Payment GTM ID: */}
            <div className="form-group row mt-5">
              <label className="col-lg-3 col-form-label">
                Payment Portal GTM ID:
              </label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    type="text"
                    name="terms"
                    className="form-control form-control-solid"
                    value={association.payment_gtm_id}
                    onChange={(e) => {
                      association.payment_gtm_id = e.target.value
                      this.setState({ association })
                    }}
                  />
                </div>
              </div>
            </div>
            {/* end : Payment GTM ID: */}
          </div>,
          false,
          true
        )}

        {/* starts : 02 Section Main Contact */}
        {!editMode &&
          this._renderSection(
            'Main Contact',
            <div className="section main-contact">
              {/* starts : First Name */}
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">First Name</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="first_name"
                    placeholder={'First Name'}
                    className="form-control form-control-solid"
                    value={associate.user.first_name}
                    onChange={(e) => {
                      association.associates[0].user.first_name = e.target.value
                      this.setState({ association, error: null })
                    }}
                  />
                </div>
              </div>
              {/* end : First Name */}

              {/* starts : Last Name */}
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Last Name</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="last_name"
                    placeholder={'Last Name'}
                    className="form-control form-control-solid"
                    value={associate.user.last_name}
                    onChange={(e) => {
                      association.associates[0].user.last_name = e.target.value
                      this.setState({ association, error: null })
                    }}
                  />
                </div>
              </div>
              {/* end : Last Name */}

              {/* starts : Phone Number */}
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Phone</label>
                <div className="col-lg-9 my-auto">
                  <PhoneInput
                    value={'+353'}
                    onChange={(value, data) => {
                      association.associates[0].user.phone_country_code = `+${data.dialCode}`
                      association.associates[0].user.phone_number = value.slice(
                        data.dialCode.length
                      )
                      this.setState({
                        association,
                      })
                    }}
                    inputProps={{
                      autocomplete: 'off',
                      className: 'form-control form-control-solid',
                    }}
                  />
                </div>
              </div>
              {/* end : Phone Number */}

              {/* starts : Email */}
              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Email</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    name="email"
                    placeholder={'Email'}
                    className="form-control form-control-solid"
                    value={associate.user.email}
                    onChange={(e) => {
                      association.associates[0].user.email = e.target.value
                      this.setState({ association, error: null })
                    }}
                  />
                </div>
              </div>
              {/* end : Email */}
              {/* starts : Password */}
              <PasswordInput
                containerClassName="form-group row"
                inputContainerClassName="col-lg-9 my-auto"
                labelClassName="col-lg-3 col-form-label"
                className="form-control form-control-solid"
                value={associate.user.password}
                onChange={(value) => {
                  association.associates[0].user.password = value
                  this.setState({ association, error: null })
                }}
                autoComplete="off"
                showValidationCheck
              />
              {/* end : Password */}
            </div>,
            false,
            true
          )}
        {/* end : 02 Section Main Contact */}

        {/* starts : 03 Section Processors */}
        {this._renderSection(
          'Processors',
          <div className="section processors">
            {/* starts : Default Live Processor */}
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Default Live Processor
              </label>
              <div className="col-9">
                <AsyncSelect
                  endpoint={window.Api.Processors}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  filter="live=true"
                  value={
                    association.default_live_processor
                      ? General.getSingleAsyncOption(
                          association.default_live_processor
                        )
                      : null
                  }
                  onSelected={(processor) => {
                    association.default_live_processor = processor
                    this.setState({ association })
                  }}
                  isClearable={true}
                  getOptions={(currencies) =>
                    General.getAsyncOptions(currencies)
                  }
                />
              </div>
            </div>
            {/* end : Default Live Processor */}

            {/* starts : Default Demo Processor */}
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Default Demo Processor
              </label>
              <div className="col-9">
                <AsyncSelect
                  endpoint={window.Api.Processors}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  filter="live=false"
                  value={
                    association.default_demo_processor
                      ? General.getSingleAsyncOption(
                          association.default_demo_processor
                        )
                      : null
                  }
                  onSelected={(processor) => {
                    association.default_demo_processor = processor
                    this.setState({ association })
                  }}
                  isClearable={true}
                  getOptions={(currencies) =>
                    General.getAsyncOptions(currencies)
                  }
                />
              </div>
            </div>
            {/* end : Default Demo Processor */}
          </div>,
          false,
          true
        )}
        {/* end : 03 Section Processors */}

        {/* starts : 04 Section Financials */}
        {this._renderSection(
          'Financials',
          <div className="section financials">

            {/* starts : Commission */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Commission</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  placeholder={'Commission (%)'}
                  className="form-control form-control-solid"
                  defaultValue={association.rate * 100 || ''}
                  onChange={(e) => {
                    association.rate = (e.target.value / 100).toFixed(2)
                    this.setState({ association, error: null })
                  }}
                />
              </div>
            </div>
            {/* end : Commission */}

            {/* starts : Currency */}
            <div className="form-group row">
              <label className="col-3 col-form-label">Currency</label>
              <div className="col-9">
                <AsyncSelect
                  endpoint={window.Api.Currencies}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  params={{}}
                  value={
                    association.currency
                      ? General.getSingleAsyncOption(association.currency)
                      : null
                  }
                  onSelected={(currency) => {
                    association.currency = currency
                    if (association.companies) {
                      association.companies = [
                        Partner.createCompany(currency),
                        Partner.createCompany(currency),
                        Partner.createCompany(currency),
                      ]
                    }
                    this.setState({ association })
                  }}
                  getOptions={(currencies) =>
                    General.getAsyncOptions(currencies)
                  }
                />
              </div>
            </div>
            {/* end : Currency */}

            {/* starts : Splink Commission */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Splink Commission</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  placeholder={'Commission (%)'}
                  className="form-control form-control-solid"
                  defaultValue={cardRate.platform_percentage ? bigDecimal.multiply(cardRate.platform_percentage, 100) : ''}
                  onChange={(e) => {
                    let value = e.target.value ? parseFloat( e.target.value) : null
                    let percentage = value ? parseFloat(bigDecimal.divide(e.target.value, 100)) : null
                    let defaultRates = association.rates?.default || {}
                    PAYMENT_METHOD_TYPES.forEach(paymentMethodType => {
                      let paymentMethodRate = defaultRates[paymentMethodType] || {}
                      paymentMethodRate["platform_percentage"] = percentage
                      defaultRates[paymentMethodType] = paymentMethodRate
                    })
                    association.rates.default = defaultRates
                    this.setState({ association, error: null })
                  }}
                />
              </div>
            </div>
            {/* end : Splink Commission */}

            {/* starts : Splink Flat Fee */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Splink Flat Fee</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  placeholder={'Flat Fee'}
                  className="form-control form-control-solid"
                  defaultValue={cardRate.platform_flat_charge ? cardRate.platform_flat_charge / 100 : ''}
                  onChange={(e) => {
                    let flatCharge = e.target.value ? e.target.value * 100 : null
                    let defaultRates = association.rates?.default || {}
                    PAYMENT_METHOD_TYPES.forEach(paymentMethodType => {
                      let paymentMethodRate = defaultRates[paymentMethodType] || {}
                      paymentMethodRate["platform_flat_charge"] = flatCharge
                      defaultRates[paymentMethodType] = paymentMethodRate
                    })
                    association.rates.default = defaultRates
                    this.setState({ association, error: null })
                  }}
                />
              </div>
            </div>
            {/* end : Splink Flat Fee */}

          </div>,
          false,
          true
        )}
        {/* end : 04 Section financials */}

        {/* starts : 05 Section Branding */}
        {this._renderSection(
          'Branding',
          <div className="section branding">
            <div className="row">
              {/* starts : Column one */}
              <div className="col-6 fields">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label>Favicon</label>
                      <div className="mt-1">
                        <ImageEdit
                          image={association.favicon}
                          type="photo"
                          cropImage={true}
                          hidePreview={false}
                          aspectRatio={1}
                          onUpdated={(image) => {
                            association.favicon = image
                            this.setState({ association })
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label>Logo Light</label>
                      <div className="mt-1">
                        <ImageEdit
                          image={association.logo_banner}
                          type="photo"
                          hidePreview={false}
                          aspectRatio={1.5}
                          onUpdated={(image) => {
                            association.logo_banner = image
                            this.setState({ association })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label>Logo Dark</label>
                      <div className="mt-1">
                        <ImageEdit
                          image={association.logo_small}
                          type="photo"
                          hidePreview={false}
                          aspectRatio={1.5}
                          onUpdated={(image) => {
                            association.logo_small = image
                            this.setState({ association })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-0">Brand Colour</label>
                  <div>
                    <SwatchColorPicker
                      color={association.primary_color}
                      onColorChange={(color) => {
                        association.primary_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-0">
                    Text Colour When On Brand Colour
                  </label>
                  <div>
                    <SwatchColorPicker
                      color={association.text_on_primary_color}
                      onColorChange={(color) => {
                        association.text_on_primary_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-0">Take Colour</label>
                  <div>
                    <SwatchColorPicker
                      color={association.take_color}
                      onColorChange={(color) => {
                        association.take_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-0">Requests Colour</label>
                  <div>
                    <SwatchColorPicker
                      color={association.request_color}
                      onColorChange={(color) => {
                        association.request_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="mb-0">Shop Colour</label>
                  <div>
                    <SwatchColorPicker
                      color={association.shop_color}
                      onColorChange={(color) => {
                        association.shop_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>

                <div className="form-group mb-0">
                  <label className="mb-0">Recurring Colour</label>
                  <div>
                    <SwatchColorPicker
                      color={association.recurring_color}
                      onColorChange={(color) => {
                        association.recurring_color = color.hex
                        this.setState({ association })
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* end : Column one */}

              {/* starts : Column two */}
              <div className="col-6 preview">
                <div className="favicon">
                  {favIcon && <img src={favIcon} />}
                  {!favIcon && <span>Fav Icon will appear here</span>}
                </div>

                <div className="card card-custom card-stretch">
                  <div
                    className="logo light"
                    style={{
                      backgroundColor: association.primary_color,
                    }}
                  >
                    {logoLight && (
                      <>
                        <img src={logoLight}></img>
                        <span>Text Colour on Brand</span>
                      </>
                    )}
                    {!logoLight && <span>Logo Light will appear here</span>}
                  </div>

                  <div className="logo dark">
                    {logoDark && <img src={logoDark}></img>}
                    {!logoDark && <span>Logo Dark will appear here</span>}
                  </div>

                  <div
                    className="card-body p-0 d-flex flex-column"
                    style={{ position: 'relative' }}
                  >
                    <div className="flex-grow-1 card-spacer">
                      <div className="row">
                        <div className="col mb-5 px-0">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0">
                              <div className="symbol-label association-take-color">
                                <span className="svg-icon svg-icon-info svg-icon-2x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="2"
                                        y="5"
                                        width="20"
                                        height="14"
                                        rx="2"
                                      ></rect>
                                      <rect
                                        fill="#000000"
                                        x="2"
                                        y="8"
                                        width="20"
                                        height="3"
                                      ></rect>
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        x="16"
                                        y="14"
                                        width="4"
                                        height="2"
                                        rx="1"
                                      ></rect>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="font-size-h4 text-dark-75 font-weight-bolder">
                                €362
                              </div>
                              <div className="font-size-sm text-muted font-weight-bold mt-1">
                                Take
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col mb-5 px-0">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                              <div className="symbol-label association-request-color">
                                <span className="svg-icon svg-icon-danger svg-icon-2x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                        fill="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="font-size-h4 text-dark-75 font-weight-bolder">
                                €161
                              </div>
                              <div className="font-size-sm text-muted font-weight-bold mt-1">
                                Request
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col mb-5 px-0">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                              <div className="symbol-label association-shop-color">
                                <span className="svg-icon svg-icon-lg svg-icon-success">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                      ></path>
                                      <path
                                        d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                                        fill="#000000"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="font-size-h4 text-dark-75 font-weight-bolder">
                                €100
                              </div>
                              <div className="font-size-sm text-muted font-weight-bold mt-1">
                                Shop
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col mb-5 px-0">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
                              <div className="symbol-label association-recurring-color">
                                <span className="svg-icon svg-icon-primary svg-icon-2x">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <path
                                        d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                      ></path>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="font-size-h4 text-dark-75 font-weight-bolder">
                                €236
                              </div>
                              <div className="font-size-sm text-muted font-weight-bold mt-1">
                                Recurring
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <LineChart
                      key={association.primary_color}
                      data={[
                        {
                          strokeWidth: 2,
                          label: 'Net Profit',
                          strokeColor: association.primary_color,
                          pointColor: association.primary_color,
                          data: [
                            15, 10, 15, 20, 20, 25, 28, 25, 20, 20, 25, 25,
                          ],
                        },
                      ]}
                      labels={[
                        'Oct',
                        'Nov',
                        'Dec',
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                      ]}
                      backgroundColor={Color.alpha(
                        association.primary_color,
                        0.1
                      )}
                    />
                  </div>

                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
                      .custom-form .branding .preview .logo.light{
                        color: ${association.text_on_primary_color} !important;
                      }
                      .symbol.symbol-light-info .symbol-label.association-take-color{
                        background-color: ${association.take_color}1A !important;
                      }
                      .association-take-color .svg-icon.svg-icon-info svg g [fill]{
                        fill: ${association.take_color} !important;
                      }
                      .symbol.symbol-light-danger .symbol-label.association-request-color{
                        background-color: ${association.request_color}1A !important;
                      }
                      .association-request-color .svg-icon.svg-icon-danger svg g [fill]{
                        fill: ${association.request_color} !important;
                      }
                      .symbol.symbol-light-success .symbol-label.association-shop-color{
                        background-color: ${association.shop_color}1A !important;
                      }
                      .association-shop-color .svg-icon.svg-icon-success svg g [fill]{
                        fill: ${association.shop_color} !important;
                      }
                      .symbol.symbol-light-primary .symbol-label.association-recurring-color{
                        background-color: ${association.recurring_color}1A !important;
                      }
                      .association-recurring-color .svg-icon.svg-icon-primary svg g [fill]{
                        fill: ${association.recurring_color} !important;
                      }
                      .fields > .form-group {
                        margin-top: 2.6rem !important;
                      }
                      .fields > .form-group:first-child {
                        margin-top: 4px !important;
                      }
                      .custom-form .branding .preview .logo.dark {
                        padding-top: 16px;
                        padding-bottom: 16px;
                        min-height: 100px;
                      }
                    `,
                    }}
                  />
                </div>
              </div>
              {/* end : Column two */}
            </div>
          </div>,
          false,
          true
        )}
        {/* end : 05 Section Branding */}

        {/* starts : 06 Section Demo Data */}
        {!editMode &&
          this._renderSection(
            'Demo Data',
            <div className={`section demo-data ${bridgeClassName}`}>
              {/* starts : Table */}
              <table className="table c-table">
                <thead>
                  <tr>
                    <td width="30%">Name</td>
                    <td>Splink</td>
                    <td width="150">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {association.companies.map((company, index) => {
                    return (
                      <tr className="demo">
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ml-2">
                              <div className="text-dark-75 font-weight-bold line-height-sm">
                                {company.name}
                              </div>
                              <a
                                href="javascript:;"
                                className="font-size-sm text-dark-50 text-hover-primary"
                              >
                                {company.members[0].user.email}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          {company.slug}.{window.General.WebsiteDomain}
                        </td>
                        <td>
                          <EditCell
                            onEditClicked={() => {
                              // disable the parent/body scroll
                              $('body').addClass('disable-scroll')
                              this.setState({
                                selectedCompany: company,
                                selectedCompanyIndex: index,
                              })
                            }}
                          />
                          <RandomiseCell
                            onRandomiseClicked={() => {
                              company = Partner.createCompany(
                                association.currency
                              )
                              association.companies[index] = company
                              this.setState({ association })
                            }}
                          />
                          <DeleteCell
                            tooltip="Delete"
                            onDeleteClicked={() => {
                              association.companies.splice(index, 1)
                              this.setState({ association })
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {/* end : Table */}

              {/* starts : Add new Client */}
              <div className="row mt-6 mb-0 pt-2">
                <div className="col text-right">
                  <a
                    className="btn btn-light-primary font-weight-bolder"
                    onClick={() => {
                      association.companies.push(
                        Partner.createCompany(association.currency)
                      )
                      this.setState({ association })
                    }}
                  >
                    <i className="la la-plus"></i>Add New Client
                  </a>
                </div>
              </div>
              {/* end : Add new Client */}
            </div>,
            false,
            true
          )}
        {/* end : 06 Section Demo Data */}
      </>
    )
  }

  _renderPartnerCompanyForm() {
    let { association, selectedCompany, selectedCompanyIndex } = this.state

    if (!selectedCompany) {
      return null
    }

    let member = selectedCompany.members[0]

    let { payments, payment_requests, payment_links, products } =
      selectedCompany.demo_data

    return (
      <div className="client-detail">
        <div className="card mb-4 p-8">
          <h3 className="card-label mb-0">{selectedCompany.name}</h3>
        </div>

        {/* starts : 01 Section Client Details */}
        {this._renderSection(
          'Details',
          <div className="section details">
            {/* starts : Client Business Name */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Business Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="client_business_name"
                  placeholder={'Client Business Name'}
                  className="form-control form-control-solid"
                  value={selectedCompany.name}
                  onChange={(e) => {
                    selectedCompany.name = e.target.value
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                  onBlur={(e) => {
                    //this._setSlug()
                  }}
                />
              </div>
            </div>
            {/* end : Client Business Name */}

            {/* starts : Client Splink */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                {window.General.Branding.LinkName}
              </label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    className="form-control form-control-solid"
                    type="text"
                    placeholder="business"
                    value={selectedCompany.slug}
                    name={'slug'}
                    onChange={(e) => {
                      selectedCompany.slug = e.target.value
                      this._updateCompany(selectedCompany, selectedCompanyIndex)
                    }}
                    onBlur={(e) => {
                      //this._checkSlug()
                    }}
                  />
                  <div className={`input-group-append ${''}`}>
                    <span
                      className="input-group-text"
                      style={{ border: 'none' }}
                    >
                      .{window.General.WebsiteDomain}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* end : Client Splink */}

            {/* starts : Client First Name */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">First Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="first_name"
                  placeholder={'First Name'}
                  className="form-control form-control-solid"
                  value={member.user.first_name}
                  onChange={(e) =>
                    this._updateCompanyMemberUser(e, selectedCompanyIndex)
                  }
                />
              </div>
            </div>
            {/* end : Client First Name */}

            {/* starts : Client Last Name */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Last Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="last_name"
                  placeholder={'Last Name'}
                  className="form-control form-control-solid"
                  value={member.user.last_name}
                  onChange={(e) =>
                    this._updateCompanyMemberUser(e, selectedCompanyIndex)
                  }
                />
              </div>
            </div>
            {/* end : Client Last Name */}

            {/* starts : Client Email */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Email</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="email"
                  placeholder={'Client Email'}
                  className="form-control form-control-solid"
                  value={member.user.email}
                  onChange={(e) =>
                    this._updateCompanyMemberUser(e, selectedCompanyIndex)
                  }
                />
              </div>
            </div>
            {/* end : Client Email */}

            {/* starts : Client Phone Number */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Phone</label>
              <div className="col-lg-9 my-auto">
                <PhoneInput
                  value={
                    member.user.phone_country_code + member.user.phone_number
                  }
                  inputProps={{
                    autocomplete: 'off',
                    className: 'form-control form-control-solid',
                  }}
                  onChange={(value, data) => {
                    member.user.phone_country_code = `+${data.dialCode}`
                    member.user.phone_number = value.slice(data.dialCode.length)
                    selectedCompany.members[0] = member
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                />
              </div>
            </div>
            {/* end : Client Phone Number */}

            {/* starts : Client Logo */}
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Logo</label>
              <div className="col-lg-9 my-auto">
                <ImageEdit
                  image={selectedCompany.logo}
                  type="photo"
                  hidePreview={false}
                  aspectRatio={1}
                  onUpdated={(image) => {
                    selectedCompany.logo = image
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                />
              </div>
            </div>
            {/* end : Client Logo */}
          </div>,
          false,
          true
        )}
        {/* end : 01 Section Client Details */}

        {/* starts : 02 Section Payments */}
        {this._renderSection(
          `Payments`,
          <div className="section open-splink-payments">
            <DemoPayments
              company={selectedCompany}
              payments={payments}
              onUpdated={(payments) => {
                selectedCompany.demo_data.payments = payments
                this._updateCompany(selectedCompany, selectedCompanyIndex)
              }}
            />
          </div>,
          true,
          true
        )}
        {/* end : 02 Section Payments */}

        {/* starts : 03 Section Payment Requests */}
        {this._renderSection(
          'Payment Requests',
          <div className="section payment-requests">
            {/* starts : Table */}
            <table className="table c-table">
              <thead>
                <tr>
                  <td width="30%">Customer</td>
                  <td>Amount</td>
                  <td>Recurring</td>
                  <td>Paid</td>
                  <td width="150">Actions</td>
                </tr>
              </thead>
              <tbody>
                {payment_requests.map((paymentRequest, index) => {
                  let { customer, payment_plans } = paymentRequest
                  let paymentPlan = payment_plans[0]
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-2">
                            <div className="text-dark-75 font-weight-bold line-height-sm">
                              {customer.first_name + ' ' + customer.last_name}
                            </div>
                            <a
                              href="javascript:;"
                              className="font-size-sm text-dark-50 text-hover-primary"
                            >
                              {customer.email}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        {Currency.format(
                          paymentPlan.currency.symbol,
                          paymentPlan.total
                        )}
                      </td>
                      <td>
                        {paymentPlan.type === 'one_time' ? null : (
                          <RecurringCell />
                        )}
                      </td>
                      <td>{paymentRequest.paid ? 'Yes' : 'No'}</td>
                      <td>
                        <EditCell
                          onEditClicked={() => {
                            this.setState({
                              showPaymentRequestModal: true,
                              selectedPaymentRequest: paymentRequest,
                              selectedPaymentRequestIndex: index,
                            })
                          }}
                        />
                        <RandomiseCell
                          onRandomiseClicked={() => {
                            selectedCompany.demo_data.payment_requests[index] =
                              Partner.generateRandomDemoPaymentRequest(
                                association.currency
                              )
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                        <DeleteCell
                          tooltip="Delete"
                          onDeleteClicked={() => {
                            selectedCompany.demo_data.payment_requests.splice(
                              index,
                              1
                            )
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* end : Table */}

            {/* starts : Add Payment Request */}
            <div className="row mt-6 mb-0 pt-2">
              <div className="col text-right">
                <a
                  className="btn btn-light-primary font-weight-bolder"
                  onClick={() => {
                    selectedCompany.demo_data.payment_requests.push(
                      Partner.generateRandomDemoPaymentRequest(
                        association.currency
                      )
                    )
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                >
                  <i className="la la-plus"></i>Add Payment Request
                </a>
              </div>
            </div>
            {/* end : Add Payment Request */}
          </div>,
          true,
          true
        )}
        {/* end : 03 Section Payment Requests */}

        {/* starts : 04 Section Shop */}
        {this._renderSection(
          'Shop',
          <div className="section shop">
            {/* starts : Table */}
            <table className="table c-table">
              <thead>
                <tr>
                  <td width="30%">Product</td>
                  <td>Amount</td>
                  <td>Recurring</td>
                  <td>Payments</td>
                  <td>Questions</td>
                  <td width="150">Actions</td>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => {
                  let { payments, payment_plans, questions } = product
                  let payment_plan = payment_plans[0]
                  return (
                    <tr>
                      <td>{product.name}</td>
                      <td>
                        {Currency.format(
                          payment_plan.currency.symbol,
                          payment_plan.total
                        )}
                      </td>
                      <td>
                        {payment_plan.type === 'one_time' ? null : (
                          <RecurringCell />
                        )}
                      </td>
                      <td>{payments.length > 0 ? 'Yes' : 'No'}</td>
                      <td>{questions.length > 0 ? 'Yes' : 'No'}</td>
                      <td>
                        <EditCell
                          onEditClicked={() => {
                            this.setState({
                              showProductModal: true,
                              selectedProduct: product,
                              selectedProductIndex: index,
                            })
                          }}
                        />
                        <RandomiseCell
                          onRandomiseClicked={() => {
                            selectedCompany.demo_data.products[index] =
                              Partner.generateRandomDemoProduct(
                                association.currency
                              )
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                        <DeleteCell
                          tooltip="Delete"
                          onDeleteClicked={() => {
                            selectedCompany.demo_data.products.splice(index, 1)
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* end : Table */}

            {/* starts : Add Product */}
            <div className="row mt-6 mb-0 pt-2">
              <div className="col text-right">
                <a
                  className="btn btn-light-primary font-weight-bolder"
                  onClick={() => {
                    selectedCompany.demo_data.products.push(
                      Partner.generateRandomDemoProduct(association.currency)
                    )
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                >
                  <i className="la la-plus"></i>Add Product
                </a>
              </div>
            </div>
            {/* end : Add Product */}
          </div>,
          true,
          true
        )}
        {/* end : 04 Section Shop */}

        {/* starts : 05 Section Channels */}
        {this._renderSection(
          'Channels',
          <div className="section channels">
            {/* starts : Table */}
            <table className="table c-table">
              <thead>
                <tr>
                  <td width="30%">Channel</td>
                  <td>Amount</td>
                  <td>Recurring</td>
                  <td>Payments</td>
                  <td>Questions</td>
                  <td width="150">Actions</td>
                </tr>
              </thead>
              <tbody>
                {payment_links.map((paymentLink, index) => {
                  let { payments, payment_plans, questions } = paymentLink
                  let payment_plan = payment_plans[0]
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ml-2">
                            <div className="text-dark-75 font-weight-bold line-height-sm">
                              {paymentLink.title}
                            </div>
                            <a
                              href="javascript:;"
                              className="font-size-sm text-dark-50 text-hover-primary"
                            >
                              {'/channels/' + paymentLink.slug}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        {Currency.format(
                          payment_plan.currency.symbol,
                          payment_plan.total
                        )}
                      </td>
                      <td>
                        {payment_plan.type === 'one_time' ? null : (
                          <RecurringCell />
                        )}
                      </td>
                      <td>{payments.length > 0 ? 'Yes' : 'No'}</td>
                      <td>{questions.length > 0 ? 'Yes' : 'No'}</td>
                      <td>
                        <EditCell
                          onEditClicked={() => {
                            this.setState({
                              showPaymentLinkModal: true,
                              selectedPaymentLink: paymentLink,
                              selectedPaymentLinkIndex: index,
                            })
                          }}
                        />
                        <RandomiseCell
                          onRandomiseClicked={() => {
                            selectedCompany.demo_data.payment_links[index] =
                              Partner.generateRandomDemoPaymentLink(
                                association.currency
                              )
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                        <DeleteCell
                          tooltip="Delete"
                          onDeleteClicked={() => {
                            selectedCompany.demo_data.payment_links.splice(
                              index,
                              1
                            )
                            this._updateCompany(
                              selectedCompany,
                              selectedCompanyIndex
                            )
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* end : Table */}

            {/* starts : Add Channel */}
            <div className="row mt-6 mb-0 pt-2">
              <div className="col text-right">
                <a
                  className="btn btn-light-primary font-weight-bolder"
                  onClick={() => {
                    selectedCompany.demo_data.payment_links.push(
                      Partner.generateRandomDemoPaymentLink(
                        association.currency
                      )
                    )
                    this._updateCompany(selectedCompany, selectedCompanyIndex)
                  }}
                >
                  <i className="la la-plus"></i>Add Channel
                </a>
              </div>
            </div>
            {/* end : Add Channel */}
          </div>,
          true,
          true
        )}
        {/* end : 05 Section Channels */}
      </div>
    )
  }

  render() {
    let {
      loading,
      editMode,
      asyncTask,

      selectedCompany,
      selectedCompanyIndex,

      showPaymentModal,
      selectedPayment,
      selectedPaymentIndex,

      showPaymentRequestModal,
      selectedPaymentRequest,
      selectedPaymentRequestIndex,

      showProductModal,
      selectedProduct,
      selectedProductIndex,

      showPaymentLinkModal,
      selectedPaymentLink,
      selectedPaymentLinkIndex,
    } = this.state

    let formClassName = selectedCompany ? 'level-two-open' : 'level-two-close'

    return (
      <>
        {asyncTask?.error && (
          <div className="row">
            <div className="col-8">
              <Alert text={asyncTask.error} closable={false} type="warning" />
            </div>
          </div>
        )}

        <Div
          className={`row custom-form ${formClassName}`}
          disabled={loading}
        >
          {/* Starts : Level one */}
          <div className="col-8 level one">
            {this._renderPartnerForm()}

            <div className="kt-portlet__foot">
              <div className="kt-form__actions pull-right">
                <button
                  type="button"
                  className="btn btn-primary ml-3"
                  disabled={loading}
                  onClick={() => this._handleAssociation()}
                >
                  {editMode ? 'Save' : 'Add Partner'}
                </button>
              </div>
            </div>

            <a
              className="mask"
              href="javascript:void(0)"
              onClick={(e) => {
                // remove disable scroll from the body tag
                $('body').removeClass('disable-scroll')
                this.setState({ selectedCompany: null })
              }}
            />
          </div>
          {/* End : Level one */}

          {/* Starts : Level two */}
          <div className="col-8 level two">
            {this._renderPartnerCompanyForm()}
          </div>
          {/* End : Level two */}

          <DemoPaymentRequestModal
            show={showPaymentRequestModal}
            company={selectedCompany}
            paymentRequest={selectedPaymentRequest}
            onHide={() => {
              this.setState({
                showPaymentRequestModal: false,
                selectedPaymentRequest: null,
                selectedPaymentRequestIndex: null,
              })
            }}
            onUpdated={(paymentRequest) => {
              selectedCompany.demo_data.payment_requests[
                selectedPaymentRequestIndex
              ] = paymentRequest
              this._updateCompany(selectedCompany, selectedCompanyIndex)
            }}
          />

          <DemoProductModal
            show={showProductModal}
            company={selectedCompany}
            product={selectedProduct}
            onHide={() => {
              this.setState({
                showProductModal: false,
                selectedProduct: null,
                selectedProductIndex: null,
              })
            }}
            onUpdated={(product) => {
              selectedCompany.demo_data.products[selectedProductIndex] = product
              this._updateCompany(selectedCompany, selectedCompanyIndex)
            }}
          />

          <DemoPaymentLinkModal
            show={showPaymentLinkModal}
            company={selectedCompany}
            paymentLink={selectedPaymentLink}
            onHide={() => {
              this.setState({
                showPaymentLinkModal: false,
                selectedPaymentLink: null,
                selectedPaymentLinkIndex: null,
              })
            }}
            onUpdated={(paymentLink) => {
              selectedCompany.demo_data.payment_links[
                selectedPaymentLinkIndex
              ] = paymentLink
              this._updateCompany(selectedCompany, selectedCompanyIndex)
            }}
          />
        </Div>
      </>
    )
  }
}

const PAYMENT_METHOD_TYPES = [
  "card",
  "sepa",
  "sepa_instant",
  "ach",
  "eft",
  "interac",
  "digital_wallet",
  "klarna",
  "faster_payments",
  "google_pay",
  "apple_pay"
]
