import React from "react"

import DateRange from "./components/common/DateRange";
import StatCard from "./components/common/StatCard";

import AssociationsTable from "./components/tables/AssociationsTable";

import AssociationAlert from './components/alert/AssociationAlert';

import Currency from "../utils/Currency";
import Backend from "../utils/Backend";
import Event from "../utils/Event";
import Stats from "../utils/Stats";

export default class AssociationAccounts extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    Backend.getAssociationStats()
    .then(stats => {
      this.setState({
        stats: stats.admin
      })
    })
  }

  _renderStatCards(){
    let {
      stats
    } = this.state

    if(!stats) return null

    let week = stats.associations.sign_ups.last_week || 0
    let month = stats.associations.sign_ups.last_30_days || 0
    let valueProcessed = Currency.format(window.General.DefaultCurrency.symbol, Stats.getTotalPaymentsValue(stats.associations))

    return (
      <>
        <StatCard
          amount={week}
          title={'Partner Accounts - Last 7 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={month}
          title={'Partner Accounts - Last 30 Days'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={valueProcessed}
          title={'Total Value Processed'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <AssociationAlert/>

        <div className={'row'}>
          {this._renderStatCards()}
        </div>

        <AssociationsTable
          title={'All Partners'}
          showExport={false}
        />
      </>
    )
  }

}
