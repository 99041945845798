var demoToggled = null

const KEY_DEMO_DATA = 'demoData'
export default class Mode {
  static isDemoToggled(){
    return Mode.demoToggled || false
  }

  static updateDemoToggled(toggled){
    Mode.demoToggled = toggled
  }
}
