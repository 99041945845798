import React from "react"
import Backend from "../../../utils/Backend";
import moment from "moment";
import Stats from "../../../utils/Stats";
import BarChart from "../chart/BarChart";
import LineChart from "../chart/LineChart";
import Event from "../../../utils/Event";

export default class MonthlyPaymentVolume extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      stats: null,
      currency: 0,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._loadStats())
  }

  _loadStats(){
    let {
      dateFrom,
      dateTo
    } = this.state

    dateTo = dateTo || moment()
    dateFrom = dateFrom || moment().add(-1, "years")

    Backend.getMonthlyPaymentStats(dateFrom.toISOString(), dateTo.toISOString())
    .then(stats => {
      this.setState({
        stats: stats.admin || stats.association
      })
    })
  }

  render() {
    let {
      stats,
      currency
    } = this.state

    if(!stats) return null

    let borderWidth = 1

    let labels = Stats.getMonthlyLabels(stats)

    let data = [
      {
        borderWidth,
        label: 'Monthly Transaction Total',
        backgroundColor: window.General.Branding.Colors.primary,
        borderColor: window.General.Branding.Colors.primary,
        data: Stats.getMonthlyTotals(stats)
      }
    ]

    return (
      <div className="card card-custom gutter-b card-stretch">
        <div className="card-header border-0 h-auto">
          <div className="card-title py-5">
            <div className="card-label">Monthly Transactions
              <div className="font-size-sm text-muted mt-2">
                Monthly Transaction Total
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0 d-flex flex-column" style={{position: 'relative'}}>
          <BarChart
            data={data}
            labels={labels}
            color={'#000'}
          />
        </div>
      </div>
    )
  }
}
