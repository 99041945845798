import React from 'react'
import Modal from 'react-bootstrap/Modal'


import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import DeleteCell from '../tables/cells/DeleteCell'
import RandomiseCell from '../tables/cells/RandomiseCell'

import Partner from "../../../utils/Partner"

export default class DemoQuestions extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      questions: props.questions,
    }
  }

  render() {
    let {
      questions,
    } = this.state

    return (
      <>
        <table className="table c-table">
            <thead>
              <tr>
                <td>Question</td>
                <td>Type</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>

                { questions.map((question, index) => {
                    return (
                      <tr className="demo">
                        <td>
                          <input
                            type="text"
                            name="question"
                            placeholder={"question"}
                            className="form-control form-control-solid"
                            value={question.text}
                            onChange={e => {
                              questions[index].text = e.target.value
                              this.props.onUpdated(questions)
                            }}
                          />
                        </td>
                        <td>
                          <select
                            className="form-control form-control-solid"
                            value={question.type}
                            onChange={e => {
                              questions[index].type = e.target.value
                              this.props.onUpdated(questions)
                            }}
                          >
                            <option value="text">Text</option>
                            <option value="checkbox">Checkbox</option>
                          </select>
                        </td>
                        <td>
                          <RandomiseCell
                            onRandomiseClicked={() => {
                              questions[index] = Partner.getRandonQuestion()
                              this.props.onUpdated(questions)
                            }}
                          />
                          <DeleteCell
                            tooltip="Delete"
                            onDeleteClicked={() => {
                              questions.splice(index, 1)
                              this.props.onUpdated(questions)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })
                }

            </tbody>
        </table>

        <div className="row mt-6 mb-0 pt-2">
          <div className="col text-right">
            <a
              className="btn btn-light-primary font-weight-bolder"
              onClick={() => {
                questions.push(Partner.getRandonQuestion())
                this.props.onUpdated(questions)
              }}
            >
              <i className="la la-plus"></i>Add Question
            </a>
          </div>
        </div>
      </>
    )
  }
}

DemoQuestions.defaultProps = {

}
