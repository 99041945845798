
import moment from 'moment'

import Currency from './Currency'

export default class Stats {

  static getTakeTotal(paymentBreakdown, currency=window.General.DefaultCurrency){
    let open = Stats.getPaymentBreakDownTypeTotal("open", paymentBreakdown, currency)
    let paymentLink = Stats.getPaymentBreakDownTypeTotal("payment_link", paymentBreakdown, currency)

    return Currency.format(currency.symbol, open + paymentLink)
  }

  static getPaymentRequestsTotal(paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = Stats.getPaymentBreakDownTypeTotal("payment_request", paymentBreakdown, currency)

    return Currency.format(currency.symbol, total)
  }

  static getRecurringTotal(paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = Stats.getPaymentBreakDownTypeTotal("recurring", paymentBreakdown, currency)

    return Currency.format(currency.symbol, total)
  }

  static getShopTotal(paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = Stats.getPaymentBreakDownTypeTotal("shop", paymentBreakdown, currency)

    return Currency.format(currency.symbol, total)
  }

  static getPaymentBreakDownTypeTotal(type, paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = 0

    let splinkType = paymentBreakdown[type].find(stat => stat.currency.id === currency.id)
    if(splinkType){
      total += splinkType.total
    }

    return total
  }

  static getTotalPayments(paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = 0

    let value = paymentBreakdown.total_value_processed.find(stat => stat.currency.id === currency.id)
    if(value){
      total =+ value.count
    }

    return total
  }

  static getTotalPaymentsValue(paymentBreakdown, currency=window.General.DefaultCurrency){
    let total = 0

    let value = paymentBreakdown.total_value_processed.find(stat => stat.currency.id === currency.id)
    if(value){
      total =+ value.total
    }

    return total
  }

  static getTotalCommissionValue(paymentBreakdown, currency=window.General.DefaultCurrency){
    let fee = 0

    let value = paymentBreakdown.total_value_processed.find(stat => stat.currency.id === currency.id)
    if(value){
      fee =+ value.commission
    }

    return fee
  }

  static getMonthlyLabels(paymentBreakdown, currency=window.General.DefaultCurrency){
    let months = paymentBreakdown.monthly.total.find(stat => stat.currency.id === currency.id)

    return months?.stats.map(stat => moment(stat.date).format('MMM')) || []
  }

  static getMonthlyTotals(paymentBreakdown, currency=window.General.DefaultCurrency){
    return Stats.getMonthKey("total", paymentBreakdown, currency=window.General.DefaultCurrency)
  }

  static getMonthlyFees(paymentBreakdown, currency=window.General.DefaultCurrency){
    return Stats.getMonthKey("fee", paymentBreakdown, currency=window.General.DefaultCurrency)
  }
  static getMonthlyCommission(paymentBreakdown, currency=window.General.DefaultCurrency){
    return Stats.getMonthKey("commission", paymentBreakdown, currency=window.General.DefaultCurrency)
  }

  static getMonthKey(key, paymentBreakdown, currency=window.General.DefaultCurrency){
    let months = paymentBreakdown.monthly[key].find(stat => stat.currency.id === currency.id)

    return months?.stats.map(stat => stat.count / 100) || []
  }

  // todo update .fee to commission
  static getProcesseedStats(stats, currency=window.General.DefaultCurrency){
    let processedStats = {
      commission_today: 0,
      commission_last_7_days: 0,
      commission_month: 0,
      processed_today_no: 0,
      processed_today: 0,
      processed_last_7_days_no: 0,
      processed_last_7_days: 0,
      processed_month_no: 0,
      processed_month: 0,
    }

    let month = stats.month.find(stat => stat.currency.id === currency.id)
    if(month){
      processedStats.commission_month = month.commission
      processedStats.processed_month = month.total
      processedStats.processed_month_no = month.count
    }

    let week = stats.week.find(stat => stat.currency.id === currency.id)
    if(week){
      processedStats.commission_last_7_days = week.commission
      processedStats.processed_last_7_days = week.total
      processedStats.processed_last_7_days_no = week.count
    }

    let today = stats.today.find(stat => stat.currency.id === currency.id)
    if(today){
      processedStats.commission_today = today.commission
      processedStats.processed_today = today.total
      processedStats.processed_today_no = today.count
    }

    return processedStats
  }

  static getPaymentRequestStats(paymentBreakdown, currency=window.General.DefaultCurrency){

    let sent = paymentBreakdown.payment_requests.sent || 0
    let paid = paymentBreakdown.payment_requests.paid.reduce((accum, item) => accum + item.count, 0)
    let totalPaid = 0

    let paidPaymentRequests = paymentBreakdown.payment_requests.paid.find(stat => stat.currency.id === currency.id)
    if(paidPaymentRequests){
      totalPaid =  Currency.format(currency.symbol,paidPaymentRequests.total)
    }

    return {
      sent,
      paid,
      totalPaid
    }
  }

  static getAllTimeRecurringCount(recurring, currency=window.General.DefaultCurrency){
    let all_time = recurring?.all_time.find(stat => stat.currency.id === currency.id)

    return all_time?.count || 0
  }

  static getAllTimeRecurringAmount(recurring, currency=window.General.DefaultCurrency){
    let all_time = recurring?.all_time.find(stat => stat.currency.id === currency.id)

    return Currency.format(currency.symbol, all_time?.total)
  }

  static getShopStats(shop, currency=window.General.DefaultCurrency){

    let customers = shop.companies.count || 0
    let productsCount = shop.product_count
    let totalProcessed = 0

    let processedAmount = shop.all_time.find(stat => stat.currency.id === currency.id)
    if(processedAmount){
      totalProcessed =  Currency.format(currency.symbol, processedAmount.total)
    }

    return {
      customers,
      productsCount,
      totalProcessed
    }
  }

  static getChannelsStats(channels, currency=window.General.DefaultCurrency){

    let customers = channels.companies.count || 0
    let paymentLinksCount = channels.payment_links_count
    let totalProcessed = 0

    let processedAmount = channels.all_time.find(stat => stat.currency.id === currency.id)
    if(processedAmount){
      totalProcessed =  Currency.format(currency.symbol, processedAmount.total)
    }

    return {
      customers,
      paymentLinksCount,
      totalProcessed
    }
  }

}
