import React from "react"

// import ImageEdit from "../common/ImageEdit"

import Div from "../layouts/Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
// import Event from "../../../utils/Event"

export default class GeneralSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: AuthManager.currentUser,
    }
  }

  isFormValid(){
    let {
      data
    } = this.state

    let {
      first_name,
      last_name,
      email
    } = data.user

    let error = null

    if(!first_name || first_name === ""){
      error = "Please enter a valid first name"
    }
    else if(!last_name || last_name === ""){
      error = "Please enter a valid last name"
    }
    else if(!Email.isValid(email)){
      error = "Please enter a valid email"
    }

    if(error){
      Notify.error(error)
      return false

    }

    return true
  }

  _update(){
    let {
      data
    } = this.state

    if(!this.isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.updateAccount(data)
    .then(user => {
      AuthManager.currentUser = user
      // Event.emit('USER_UPDATED')
      Notify.success('Your details have been changed successfully')
      this.setState({
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  render() {
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <div className="offset-xxl-2 col-xxl-8">
          <div className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_form">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              <h4 className="mb-10 font-weight-bold text-dark">Control your basic default settings</h4>
              <div className="form-group row">
                <label className="col-3 col-form-label">First Name</label>
                <div className="col-9">
                  <input className="form-control form-control-solid"
                         type="text"
                         name="first_name"
                         defaultValue={ data.user.first_name }
                         onChange={e => this._handleChangeUser(e)}
                  /><br/>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Last Name</label>
                <div className="col-9">
                  <input className="form-control form-control-solid"
                         name="last_name"
                         type="text"
                         defaultValue={ data.user.last_name }
                         onChange={e => this._handleChangeUser(e)}
                  /><br/>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input className="form-control form-control-solid"
                         name="email"
                         type="email"
                         defaultValue={ data.user.email }
                         disabled
                  /><br/>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{margin: '0 auto'}}>
                <button className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        disabled={loading}
                        onClick={() => this._update()}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
