import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'

import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import ImageEdit from '../common/ImageEdit';
import ColorPicker from "../common/ColorPicker";
import AsyncSelect from "../common/AsyncSelect";

import DemoCustomer from '../splink/DemoCustomer'
import DemoPaymentPlans from '../splink/DemoPaymentPlans'
import DemoCustomData from '../splink/DemoCustomData'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"

export default class DemoPaymentRequestModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      company: props.company,
      paymentRequest: props.paymentRequest,
      show: props.show,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      paymentRequest,
      company,
      error,
    } = this.state

    if(!paymentRequest){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={"sub-modal"}
        dialogClassName={"modal-50"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment Request</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <DemoCustomer
            customer={paymentRequest.customer}
            onUpdated={customer => {
              paymentRequest.customer = customer
              this.props.onUpdated(paymentRequest)
            }}
          />

          <div className="c-separator form-group row mt-4 align-items-center text-center">
            <div className="col-3">
              <hr/>
            </div>
            <div className="col-6">
              <span>
                <strong>
                  Payment Details
                </strong>
              </span>
            </div>
            <div className="col-3">
              <hr/>
            </div>
          </div>

          <DemoPaymentPlans
            showOptional={false}
            showCurrency={true}
            priceTitle="Amount"
            company={company}
            paymentPlans={paymentRequest.payment_plans}
            updatePaymentPlan={(paymentPlan, index) => {
              paymentRequest.payment_plans[index] = paymentPlan
              this.props.onUpdated(paymentRequest)
            }}
          />

          <div className="form-group row">
            <label className="col-3 col-form-label">Due Date & Time*</label>
            <div className="col-9">
              <div className="input-group date">

                <div className="input-group-prepend">
                  <span className="input-group-text border-0">
                    <i className="la la-calendar"></i>
                  </span>
                </div>

                <Flatpickr
                  className="form-control form-control-solid"
                  options={{
                    enableTime: true,
                    noCalendar: false,
                    dateFormat: "Y-m-d h:i"
                  }}
                  value={moment(paymentRequest.due_at).toDate()}
                  onChange={dates => {
                    paymentRequest.due_at = moment(dates[0]).format('YYYY-MM-DDThh:mm')
                    this.props.onUpdated(paymentRequest)
                  }}
                />

              </div>

            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Description*</label>
            <div className="col-9">
              <textarea
                className="form-control form-control-solid"
                placeholder="Description"
                name="description"
                value={paymentRequest.description}
                onChange={e => {
                  paymentRequest.description = e.target.value
                  this.props.onUpdated(paymentRequest)
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Paid*</label>
            <div className="col-9">
              <div className="row no-gutters">
                <div className="col-auto mt-3">
                  <span className="switch switch-outline switch-icon switch-primary">
                    <label>
                    <input
                      type="checkbox"
                      name="emailSelect"
                      checked={paymentRequest.paid}
                      onChange={e => {
                        paymentRequest.paid = e.target.checked
                        this.props.onUpdated(paymentRequest)
                      }}
                    />
                    <span></span>
                    </label>
                  </span>
                </div>
                <label htmlFor="emailSelect" className="col col-form-label align-left pl-3 mt-3">
                  Marking as Paid will create a Payment for this Payment Request
                </label>
              </div>
            </div>
          </div>


        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this.props.onHide()}
          >
            Ok
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
