import React from "react";

import { ChromePicker } from 'react-color';

export default class ColorPicker extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      color: props.color,
      displayColorPicker: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      color,
      displayColorPicker
    } = this.state

    let backgroundColor;

    if(color.charAt(0) === '#'){
      backgroundColor = color
    }else {
      color = JSON.parse(color)
      backgroundColor = `rgb(${color.r}, ${color.g}, ${color.b}, ${color.a})`
    }

    return (
      <>
        <div
          style={{padding: 5, background: '#F3F6F9', borderRadius: 1, cursor: "pointer", display: "inline-block"}}
          onClick={() => {
            this.setState({
              displayColorPicker: !displayColorPicker
            })
          }}
        >
          <div style={{width: 50, height: 25, borderRadius: 2, background: backgroundColor}} />
        </div>
        {
          displayColorPicker &&
          <div style={{position: "absolute", zIndex: 2}}>
            <ChromePicker
              color={color}
              onChange={updatedColor => {
                this.props.onColorChange(updatedColor)
              }}
            />
          </div>
        }
      </>
    )
  }

}