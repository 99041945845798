import React, { Component } from "react";

import moment from "moment";

import Collapsible from "react-collapsible";

import JSONPretty from "react-json-pretty";

import General from "../../../utils/General";

export default class PaymentAttemptLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requests: props.requests,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { requests } = this.state;

    if (!requests) {
      return null;
    }

    return requests.map((request) => {
      let title = General.snakeCaseToTitleCase(request.reason);
      let time = moment(request.created_at).format("H:mm:ss")
      let className = "varation-1 ";

      if (request.reason != "pay" && (request.status_code >= 400 && request.status_code <= 599)) {
        let response = request.response;
        
        let error = response.errorMessage || response.technical_message || response.resptext ||  response.error

        if (error) {
          title += ` - ${error}`;
          className += "error-log";
        }
      }

      return (
        <>
          <Collapsible
            className={className}
            openedClassName={className}
            trigger={[
              <span class="time label label-lg font-weight-bold label-light-primary label-inline">{time}</span>,
              title
            ]}
          >
            <span className="text-dark font-weight-bold">
              Request Data
            </span>
            <JSONPretty
              json={request.data}
              className="json-pretty"
            />
            <hr />
            <span className="text-dark font-weight-bold">
              Response Data
            </span>
            <JSONPretty
              json={request.response}
              className="json-pretty"
            />
          </Collapsible>
        </>
      );
    });
  }
}
