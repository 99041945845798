import React from 'react'

// import ImageEdit from "../common/ImageEdit"
import PasswordInput from '../common/PasswordInput'

import Div from '../layouts/Div'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Notify from '../../../utils/Notify'

export default class PasswordSettings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      data: AuthManager.currentUser,
    }

    this.newPassword = React.createRef()
  }

  isFormValid() {
    let { data } = this.state

    let { current_password, password, verifyPassword } = data.user

    let isValid = true
    let error = null

    if (!current_password || current_password === '') {
      isValid = false
      error = 'Please enter your current password'
    }
    if (!password || password === '') {
      isValid = false
      error = 'Please enter a valid password'
    } else if (password !== verifyPassword) {
      isValid = false
      error = "Passwords don't match"
    }
    else if(!this.newPassword.current.isValid()){
      error = "Your new password is not strong enough, please enter a stronger password"
    }

    if (error) {
      Notify.error(error)
      return
    }

    return isValid
  }

  _update() {
    let { data } = this.state

    if (!this.isFormValid()) {
      return
    }

    this.setState({ loading: true })
    Backend.updateAccount(data)
      .then(() => {
        data.user.password = ''
        data.user.verifyPassword = ''
        data.user.current_password = ''
        this.setState({
          loading: false,
          data,
        })
        Notify.success('Your password has been changed successfully!')
      })
      .catch((error) => {
        Notify.error(
          'Failed to update your password, please make sure your old/current password is correct'
        )
        this.setState({
          loading: false,
        })
      })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  render() {
    let { data, loading } = this.state

    return (
      <>
        <div className="offset-xxl-2 col-xxl-8">
          <div
            className="form fv-plugins-bootstrap fv-plugins-framework"
            id="kt_form"
          >
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 className="mb-10 font-weight-bold text-dark">
                Keep your password safe
              </h4>
              <PasswordInput
                containerClassName="form-group row"
                inputContainerClassName="col-9"
                labelClassName="col-3 col-form-label"
                label="Old Password"
                className="form-control form-control-solid"
                value={data.user.current_password}
                onChange={(value) => {
                  let data = this.state.data
                  data.user['current_password'] = value
                  this.setState({ data, error: null })
                }}
                autoComplete="off"
              />
              <PasswordInput
                ref={this.newPassword}
                containerClassName="form-group row"
                inputContainerClassName="col-9"
                labelClassName="col-3 col-form-label"
                label="New Password"
                confirmLabel="Confirm New Password"
                className="form-control form-control-solid"
                value={data.user.password}
                matchValue={data.user.verifyPassword}
                onChange={(value, name) => {
                  let data = this.state.data
                  data.user[name] = value
                  this.setState({ data, error: null })
                }}
                onConfirmChange={(value) => {
                  let data = this.state.data
                  data.user['verifyPassword'] = value
                  this.setState({ data, error: null })
                }}
                autoComplete="off"
                showValidationCheck
              />
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div style={{ margin: '0 auto' }}>
                <button
                  className="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  disabled={loading}
                  onClick={() => this._update()}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
