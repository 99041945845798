import React from 'react'

import Modal from 'react-bootstrap/Modal'

import PhoneInput from '../common/PhoneInput'
import PasswordInput from '../common/PasswordInput'

import General from '../../../utils/General'
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import Phone from '../../../utils/Phone'

export default class AddCompanyModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      show: props.show,
      loading: false,
      error: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company || {
        members: [{ user: {} }],
        settings: { live: props.live },
        add_ons: {},
      },
      phone: Phone.getDefaultCountryCode(),
    }
  }

  _setSlug = General.debounce(() => {
    let { company } = this.state

    if (company.slug || !company.name) {
      return
    }

    return Backend.slugify(company.name)
      .then((response) => {
        let errorSlug = false
        if (company.slug && company.slug != company.name) {
          errorSlug = true
        }
        if (!company.slug) {
          company.slug = response.slug
        }
        this.setState({ errorSlug, company, isLoading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }, 500)

  _checkSlug() {
    let { company } = this.state

    this.setState({ isLoadingSlug: true })
    Backend.slugify(company.slug)
      .then((response) => {
        if (response.slug != company.slug) {
          this.setState({ errorSlug: true, isLoadingSlug: false })
        } else {
          this.setState({ errorSlug: false, isLoadingSlug: false })
        }
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoadingSlug: false })
      })
  }

  _handlePhoneUpdate(value, data) {
    let { company } = this.state

    company.members[0].user.phone_country_code = `+${data.dialCode}`
    company.members[0].user.phone_number = value.slice(data.dialCode.length)
    this.setState({
      phone: value,
      company,
    })
  }

  _isFormValid() {
    let { company } = this.state

    let error = null

    if (company.name == null || company.name.length === 0) {
      error = 'Please enter a company name'
    } else if (company.slug == null || company.slug.length === 0) {
      error = 'Please enter the company slug'
    }

    if (!error) {
      company.members.map((member) => {
        let { id, first_name, last_name, email, password, confirm_password } =
          member.user

        if (first_name == null || first_name.length === 0) {
          error = 'Please enter a first name'
        } else if (last_name == null || last_name.length === 0) {
          error = 'Please enter a last name'
        } else if (email == null || email.length === 0) {
          error = 'Please enter an email'
        }
      })
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddCompany() {
    if (!this._isFormValid()) {
      return
    }
    this._addCompany()
  }

  _addCompany() {
    let { company } = this.state

    company.stripe_test_connect_data = {
      country_short: 'ie',
      persons: [],
      representative: {
        first_name: company.members[0].user.first_name,
        last_name: company.members[0].user.last_name,
        email: company.members[0].user.email,
        phone: '+35312345678',
        relationship: {
          owner: true,
          director: true,
          executive: true,
        },
        dob: {
          year: 2000,
          month: 1,
          day: 1,
        },
        address: {
          line1: 'Line 1',
          line2: 'Line 2',
          city: 'City',
          state: 'State',
          country: 'ie',
          postal_code: 'Postal Code',
        },
        verification: {
          document: {
            front: 'file_identity_document_success',
          },
          additional_document: {
            front: 'file_identity_document_success',
          },
        },
      },
      currency: 'eur',
    }

    this.setState({ loading: true })
    Backend.addCompany(company)
      .then((company) => {
        Notify.success('Company Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(company)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderMembers() {
    let { company } = this.state

    let phone = Phone.getDefaultCountryCode()

    return company.members.map((member, index) => {
      return (
        <>
          <div className="form-group">
            <input
              type="text"
              name="first_name"
              placeholder={'First Name'}
              className="form-control form-control-solid"
              value={member.user.first_name}
              onChange={(e) => {
                company['members'][index]['user'][e.target.name] =
                  e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="last_name"
              placeholder={'Last Name'}
              className="form-control form-control-solid"
              value={member.user.last_name}
              onChange={(e) => {
                company['members'][index]['user'][e.target.name] =
                  e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          <div className="form-group">
            <PhoneInput
              value={phone}
              onChange={(value, data) => this._handlePhoneUpdate(value, data)}
              inputProps={{
                autocomplete: 'off',
                className: 'form-control form-control-solid',
              }}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="email"
              placeholder={'Email'}
              className="form-control form-control-solid"
              value={member.user.email}
              onChange={(e) => {
                company['members'][index]['user'][e.target.name] =
                  e.target.value
                this.setState({ company, error: null })
              }}
            />
          </div>

          <PasswordInput
            label={false}
            containerClassName="form-group"
            className="form-control form-control-solid"
            value={member.user.password}
            onChange={(value, name) => {
              company['members'][index]['user'][name] = value
              this.setState({ company, error: null })
            }}
            autoComplete="off"
            showValidationCheck
          />
        </>
      )
    })
  }

  render() {
    let { show, loading, company, error, errorSlug } = this.state

    let title = 'Add Company'
    let buttonTitle = 'Add'

    let className = ''

    if (errorSlug) {
      className += 'input-group-append-bg-dark-error'
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder={'Customer / Business Name'}
              className="form-control form-control-solid"
              value={company.name}
              onChange={(e) => {
                company.name = e.target.value
                this.setState({ company, error: null })
              }}
              onBlur={(e) => {
                this._setSlug()
              }}
            />
          </div>

          <div className="form-group">
            <div className="input-group">
              <input
                className="form-control form-control-solid"
                type="text"
                placeholder="business"
                value={company.slug}
                name={'slug'}
                onChange={(e) => {
                  company.slug = e.target.value
                  this.setState({ company, error: null })
                }}
                onBlur={(e) => {
                  this._checkSlug()
                }}
              />
              <div className={`input-group-append ${className}`}>
                <span className="input-group-text" style={{ border: 'none' }}>
                  .{window.General.WebsiteDomain}
                </span>
              </div>
            </div>
          </div>

          {/*<div className="form-group">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    name="website"*/}
          {/*    placeholder={"Company Website"}*/}
          {/*    className="form-control form-control-solid"*/}
          {/*    value={company.settings.website}*/}
          {/*    onChange={e => {*/}
          {/*      company['settings'][e.target.name] = e.target.value*/}
          {/*      this.setState({ company, error: null })*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className="form-group">*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    name="vat_no"*/}
          {/*    placeholder={"TAX Number"}*/}
          {/*    className="form-control"*/}
          {/*    value={company.settings.vat_no}*/}
          {/*    onChange={e => {*/}
          {/*      company['settings'][e.target.name] = e.target.value*/}
          {/*      this.setState({ company, error: null })*/}
          {/*    }}*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</div>*/}

          <br />

          {this._renderMembers()}
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold"
            onClick={() => this.props.onHide()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary font-weight-bold"
            id="add-success"
            disabled={loading}
            onClick={() => this._handleAddCompany()}
          >
            <span className="svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                  ></path>
                </g>
              </svg>
            </span>
            {buttonTitle}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
