import React, { Component } from "react";

export default class PaymentAttemptCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }
  render() {
    const { data } = this.state;

    return (
      <div
        className="rt-td"
        role="gridcell"
        
        style={{
          padding: 13
        }}
      >
        <span className="text-dark font-weight-bold">{data}</span>
      </div>
    );
  }
}
