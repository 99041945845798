import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Div from "./components/layouts/Div"

import Alert from "./components/alert/Alert"
import ExplainerAlert from './components/alert/ExplainerAlert';

import ConfirmModal from './components/modal/ConfirmModal'

import DNSRecordModal from './components/modal/DNSRecordModal'

import Aside from './components/Aside'

import SplinkDomainSettings from './components/settings/SplinkDomainSettings'
import ExternalDomainSettings from "./components/settings/ExternalDomainSettings"

import DomainEngagementCard from "./components/cards/DomainEngagementCard"

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Notify from "../utils/Notify"

class DomainSettings extends React.Component {
  constructor(props){
    super(props)

    let tabs = [
      {
        title:`01 - Use ${window.General.Branding.Name} Domain`,
        subtitle:`View your free ${window.General.Branding.Name} domain`,
        icon:"earth-globe",
        url: "/domain/splink"
      },
      {
        title:"02 - Use Existing Domain",
        subtitle:`Connect your existing domain to ${window.General.Branding.Name}`,
        icon:"attachment",
        url: "/domain/existing"
      },
      // {
      //   title:"03 - Buy New Domain",
      //   subtitle:"Get a domain for your site",
      //   icon:"shopping-basket",
      //   url: "/domain/purchased"
      // },
    ]

    this.state = {
      tabs,
      dns: null,
      purchasedDomain: AuthManager.currentUser.association.domain,
      loading: false,
      loadingDNS: true,
      activeTabIndex: this._getActiveTabIndex(tabs),
    }

  }

  componentDidMount(){
    this._loadDNS()
  }

  _loadDNS(){
    let {
      purchasedDomain
    } = this.state

    if(!purchasedDomain){
      this.setState({ loadingDNS: false })
      return null
    }

    this.setState({ loadingDNS: true })
    Backend.getDNS(purchasedDomain)
    .then(dns => {
      this.setState({
        dns,
        loadingDNS: false
      })
    })
    .catch(error => {
      this.setState({ loadingDNS: false })
      Notify.error(error.message)
    })
  }

  _refreshDNS(){
    let {
      purchasedDomain
    } = this.state

    return Backend.getDNS(purchasedDomain)
    .then(dns => {
      this.setState({
        dns,
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _deletePurchasedDomain(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingPurchasedDomain: true })
    Backend.deleteDomain(purchasedDomain)
    .then(() => {
      this.setState({ purchasedDomain: null , showDeletePurchasedDomainModal: false})
      Notify.success("Domain was successfully removed")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loadingPurchasedDomain: false })
    })
  }

  _deleteRecord(){
    let {
      website,
      selectedRecord,
      purchasedDomain
    } = this.state


    this.setState({ loadingDeleteRecord: true })
    Backend.deleteRecord(purchasedDomain, selectedRecord)
    .then(() => this._refreshDNS())
    .then(() => {
      this.setState({
        selectedRecord: null,
        loadingDeleteRecord: false,
        showConfrmDeleteRecordModal: false
      })
      Notify.success("Record Successfully Deleted")
    })
    .catch(error => {
      this.setState({
        loadingDeleteRecord: false,
      })
      Notify.error(error.message)
    })
  }

  _activationCheck(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingActivationCheck: true })
    Backend.activationCheck(purchasedDomain)
    .then(website => {
      this.setState({ loadingActivationCheck: false })
      Notify.success(`${window.General.Branding.Name} is now checking the nameservers for ${purchasedDomain.name}. Please wait a few hours for an update.`)
    })
    .catch(error => {
      this.setState({ loadingActivationCheck: false })
      Notify.error(error.message)
    })
  }

  _addGmailRecords(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingGmailRecords: true })
    Backend.addGmailRecords(purchasedDomain)
    .then(() => this._refreshDNS())
    .then(website => {
      this.setState({ loadingGmailRecords: false, showConfirmAddGmailRecordsModal: false })
      Notify.success(`Gmail Records Added`)
    })
    .catch(error => {
      this.setState({ loadingGmailRecords: false })
      Notify.error(error.message)
    })
  }

  _getActiveTabIndex(tabs){
    const { pathname } = this.props.location;
    for(var i=0; i < tabs.length; i++){
      let tab = tabs[i]
      if(pathname === tab.url){
        return i
      }
    }
    return 0
  }

  _renderCompleteTransfer(){
    let {
      dns,
      website,
      purchasedDomain,
      loadingPurchasedDomain,
      loadingActivationCheck,
      showDeletePurchasedDomainModal
    } = this.state

    let stepOneTitle = "Log Into Your Domain Provider (E.g. GoDaddy, LetsHost...)"
    if(dns.original_registrar_data.name){
      stepOneTitle = (
        <>
          Log Into Your <a href={`${dns.original_registrar_data.url}`}>{dns.original_registrar_data.name} Account</a>
        </>
      )
    }

    return (

        <Div
          className="col-lg-12 px-0"
          disabled={loadingPurchasedDomain}
        >
          {purchasedDomain.dns &&
            <div className="card mb-10 p-7">
              <div className="card-header">
                <div className="card-header-label">
                  <h3 className="card-header-title">
                    { purchasedDomain.name }
                  </h3>
                </div>
              </div>
              <div className="card-body">
                <p>Your domain and it's relevant settings are automatically managed by {window.General.Branding.Name}.</p>
              </div>
              <div className="card-footer">
                <div className="kt-form__actions ml-auto">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    disabled={loadingPurchasedDomain}
                    onClick={() => this.setState({ showDeletePurchasedDomainModal: true })}
                  >
                    Delete Domain
                  </button>
                </div>
              </div>
            </div>
          }

          {!purchasedDomain.dns &&
            <>
              <div className="card mb-10 p-7">
                <div className="card-header">
                  <div className="card-header-label">
                    <h3 className="card-header-title">
                      Complete Transferring Your Domain
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <p><b>{ purchasedDomain.name }</b> is not yet active.</p>
                  <p>Follow our handy guide below to get your doman active</p>
                </div>
                <div className="card-footer">
                  <div className="kt-form__actions ml-auto">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      disabled={loadingPurchasedDomain}
                      onClick={() => this.setState({ showDeletePurchasedDomainModal: true })}
                    >
                      Cancel Transfer
                    </button>
                  </div>
                </div>
              </div>

              <div className="card mb-10 p-7">
                <div className="card-header">
                  <div className="card-header-label">
                    <h3 className="card-header-title">
                      1. { stepOneTitle }
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div>Remove these nameservers:</div>
                  <ul className="nameservers">
                    { dns.original_name_servers.map(name_server => {
                        return (
                          <li>
                            { name_server }
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>

              <div className="card mb-10 p-7">
                <div className="card-header">
                  <div className="card-header-label">
                    <h3 className="card-header-title">
                      2. Replace Them With {window.General.Branding.Name}'s Nameservers
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className="nameservers">
                    { dns.name_servers.map((nameserver, index) => {
                        return (
                          <>

                            <div className="mb-2">Nameserver {index + 1}</div>
                            <div className="nameserver d-inline-flex align-items-center">

                              <div className="col-auto name">
                                { nameserver }
                              </div>

                              <div className="col">
                                <CopyToClipboard
                                  text={nameserver}
                                  onCopy={() => {
                                    Notify.success("Copied to clipboard")
                                  }}
                                >
                                  <button className="btn btn-outline-dark" id="copyButton" ata-clipboard-action="copy" data-clipboard-target="#shareLink">
                                    Copy Link
                                  </button>
                                </CopyToClipboard>
                              </div>

                            </div>

                          </>
                        )
                      })
                    }
                  </div>
                  <p className="mt-1 mb-0">
                    <b>Save your changes</b>
                    <p>Changes can take 24 hours to process.</p>
                  </p>
                </div>
              </div>


              <div className="nameserver row align-items-center bg-transparent b-color m-0 mb-7">
                <div className="col">
                  {window.General.Branding.Name} periodically checks for nameserver updates. To initiate a nameserver check now, Check nameservers.
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => this._activationCheck()}
                    disabled={loadingActivationCheck}
                  >
                    Check Nameservers
                  </button>
                </div>
              </div>
            </>
          }

          { this._renderDNSRecords(dns) }

          <ConfirmModal
            show={showDeletePurchasedDomainModal}
            title={`Are You Sure?`}
            message={`You cannot undo this action`}
            loading={loadingPurchasedDomain}
            confirmButtonText="Yes"
            cancelButtonText="No"
            buttonClassName="danger"
            onCancel={() => this.setState({ showDeletePurchasedDomainModal: false })}
            onConfirm={() => this._deletePurchasedDomain(purchasedDomain)}
          />

        </Div>

    )
  }

  _renderSettings(){
    let {
      tabs,
      domain,
      activeTabIndex
    } = this.state

    return (
      <>
        <div className="row">
          <DomainEngagementCard/>
        </div>
        <div className={"card card-custom"}>
          <div className={"card-body p-0"}>
            <div className={"wizard wizard-2"} id={"kt_wizard_v2"}
                 data-wizard-state={"first"} data-wizard-clickable={"false"}
            >
              <div className={"wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"}>
                <Aside
                  tabs={tabs}
                  activeTabIndex={activeTabIndex}
                  onClick={(tab, index) => {
                    this.setState({
                      activeTabIndex: index
                    }, () =>{
                      this.props.history.push(tab.url)
                    })
                  }}
                />
              </div>
              <div className={"wizard-body py-8 px-8 py-lg-20 px-lg-10"}>
                <div className={"row"}>
                  <Switch>
                    <Route exact path="/domain/splink" component={SplinkDomainSettings} />
                    <Route exact path="/domain/existing"
                      render={(props) => (
                        <ExternalDomainSettings {...props}
                          onTransferredDomain={domain => {
                            window.location = "/domain/splink"
                          }}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderDNSRecords(dns){
    let {
      selectedRecord,
      showRecordModal,
      purchasedDomain,
      loadingDeleteRecord,
      loadingGmailRecords,
      showConfrmDeleteRecordModal,
      showConfirmAddGmailRecordsModal
    } = this.state

    if(!dns){
      return null
    }

    return (
      <>
        <div className="card mt-3 p-7">

          <div className="card-header">
            <div className="card-header-label justify-content-between row mx-5">
              <h3 className="card-header-title">
                DNS Records
              </h3>
              { dns.dns_records.find(record => record.type === "MX") == null &&

                <button
                  type="button"
                  className="btn btn-outline-dark ml-auto"
                  onClick={() => this.setState({ showConfirmAddGmailRecordsModal: true })}
                >
                  Add Gmail Records
                </button>
              }
              <button
                type="button"
                className="btn btn-outline-dark ml-3"
                onClick={() => this.setState({ selectedRecord: null, showRecordModal: true })}
              >
                Add Record
              </button>
            </div>
          </div>

          <div className="card-body py-3">
            <div className="dataTables_wrapper">

              { dns.status === "pending" &&
                <ExplainerAlert
                  text={ `To avoid disruption to your email / other services ${window.General.Branding.Name} has automatically brought over DNS Records from your domain. Update these as necessary to ensure there is no disruption to your existing services. Please contact support for help with this if required.`}
                />
              }
              <table className="table table-striped- table-hover dataTable">

                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Content</th>
                    <th>TTL</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  { dns.dns_records.map((record, index) => {
                    let ttl = "Auto"

                    if(record.ttl != null && record.ttl !== 1){
                      ttl = record.ttl
                    }

                    return (
                      <tr>
                        <td>
                          { record.type }
                        </td>
                        <td>
                          { record.name }
                        </td>
                        <td className="text-break">
                          { record.content }
                        </td>
                        <td>
                          { ttl }
                        </td>
                        <td width="200" align="center">
                          { record.editable &&
                            <>
                              <a
                                href="javascript:;"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                                title="Edit"
                                onClick={() => this.setState({ selectedRecord: record, showRecordModal: true }) }
                              >
                                <i className="flaticon2-pen text-primary icon-sm"></i>
                              </a>
                              <a
                                href="javascript:;"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                                title="Delete"
                                onClick={() => this.setState({ selectedRecord: record, showConfrmDeleteRecordModal: true }) }
                              >
                                <i className="flaticon2-trash text-primary icon-sm"></i>
                              </a>
                            </>
                          }
                          { !record.editable &&
                            <>
                              <div className="py-2">Locked</div>
                            </>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ConfirmModal
          show={showConfirmAddGmailRecordsModal}
          title={`Are You Sure?`}
          message={`Doing this will add the relevant Gmail MX Records to your DNS Records`}
          loading={loadingGmailRecords}
          confirmButtonText="Add Gmail Records"
          onCancel={() => this.setState({ showConfirmAddGmailRecordsModal: false })}
          onConfirm={() => this._addGmailRecords(purchasedDomain)}
        />

        <DNSRecordModal
          show={showRecordModal}
          record={selectedRecord}
          domain={purchasedDomain}
          onCancel={() => this.setState({ selectedRecord: null, showRecordModal: false })}
          onUpdated={() =>  {
            this._refreshDNS()
            .then(() => {
              this.setState({ selectedRecord: null, showRecordModal: false })
            })
          }}
        />

        <ConfirmModal
          show={showConfrmDeleteRecordModal}
          title={`Are You Sure?`}
          message={`You cannot under this action`}
          loading={loadingDeleteRecord}
          confirmButtonText="Delete Record"
          buttonClassName="danger"
          onCancel={() => this.setState({ showConfrmDeleteRecordModal: false })}
          onConfirm={() => this._deleteRecord(selectedRecord)}
        />
      </>
    )
  }

  render() {
    let {
      dns,
      loadingDNS,
      loadingDomain,
      purchasedDomain
    } = this.state

    if(loadingDomain || loadingDNS){
      return null
    }

    if(purchasedDomain){
      return this._renderCompleteTransfer()
    }
    else{
      return this._renderSettings()
    }
  }
}

export default withRouter(DomainSettings);
