import React from 'react'

import General from '../../../../utils/General'

export default class PaymentInstrumentCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      paymentInstrument: props.paymentInstrument,
      className: props.className
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      className,
      paymentInstrument,
    } = this.state

    if(!paymentInstrument){
      return null
    }

    let type = paymentInstrument.payment_method.type
    type = General.snakeCaseToTitleCase(type)

    if(type == "Digital Wallet"){
      type = "Google/Apple Pay"
    }

    if(type == "Ach"){
      type = "ACH"
    }

    let id = paymentInstrument.identifier
    if(id){
      id = `**** ${id}`
    }

    return (
      <div className="d-flex align-items-center">
        <div className={className}>
          <div className="text-dark-75 font-weight-bold line-height-sm">{type}</div>
          <a href="javascript:;" className="font-size-sm text-dark-50 text-hover-primary">{id}</a>
        </div>
      </div>
    )
  }
}

PaymentInstrumentCell.defaultProps = {
  className: 'ml-2'
}
