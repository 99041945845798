import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify"
import ImageEdit from "../../common/ImageEdit";

import UnconnectedProcessors from "./UnconnectedProcessors"
import StripeConnectProcessor from "./StripeConnectProcessor"
import AibConnectProcessor from "./AibConnectProcessor"

import AsyncSelect from "../../common/AsyncSelect";
import ColorPicker from "../../common/ColorPicker";

import Select from "../../common/CustomSelect";

const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  },
]

export default class CardConnectProcessor extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      company: props.company,
      data: props.data ||  {
        username: "",
        password: "",
        site: "",
        google_pay_enabled: false,
        apple_pay_enabled: false
      },
    }
  }

  _isFormValid(){
    let { data } = this.state

    let {
      username,
      password,
      site
    } = data

    let error = null
    if(!username){
      error = "Please provide a valid username"
    }
    else if(!password){
      error = "Please provide a valid password"
    }
    else if(!site){
      error = "Please provide a valid site"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _connect(){
    let {
      company,
      data,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectToCardConnect(company, data)
    .then(data => {
      Notify.success("Connected Successfully")
      this.setState({ loading: false })
      this.props.onUpdated()
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }


  render(){
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-3 col-form-label">Merchant ID</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.merchid}
                  onChange={e => {
                    data.merchid = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Username</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.username}
                  onChange={e => {
                    data.username = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Password</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.password}
                  onChange={e => {
                    data.password = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Site</label>
            <div className="col-9">
              <input
                  className="form-control form-control-solid"
                  type="text"
                  value={data.site}
                  onChange={e => {
                    data.site = e.target.value
                    this.setState({ data })
                  }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Google Pay Enabled</label>
            <div className="col-9">
              <Select
                value={YES_NO_OPTIONS.find(option => option.value == data.google_pay_enabled)}
                className="form-control h-auto border-0 form-control-solid c-selectbox"
                name="account_holder_type"
                placeholder="Please Select A Value"
                onChange={option => {
                  data.google_pay_enabled = option.value
                  this.setState({ data })
                }}
                options={YES_NO_OPTIONS}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Apple Pay Enabled</label>
            <div className="col-9">
              <Select
                value={YES_NO_OPTIONS.find(option => option.value == data.apple_pay_enabled)}
                className="form-control h-auto border-0 form-control-solid c-selectbox"
                name="account_holder_type"
                placeholder="Please Select A Value"
                onChange={option => {
                  data.apple_pay_enabled = option.value
                  this.setState({ data })
                }}
                options={YES_NO_OPTIONS}
              />
            </div>
          </div>

          { data.apple_pay_enabled &&
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">Apple Pay Merchat ID</label>
                <div className="col-9">
                  <input
                      className="form-control form-control-solid"
                      type="text"
                      value={data.apple_pay_merchant_id}
                      onChange={e => {
                        data.apple_pay_merchant_id = e.target.value
                        this.setState({ data })
                      }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Apple Pay Domain verification</label>
                <div className="col-9">
                  <input
                      className="form-control form-control-solid"
                      type="text"
                      value={data.apple_pay_domain_verification}
                      onChange={e => {
                        data.apple_pay_domain_verification = e.target.value
                        this.setState({ data })
                      }}
                  />
                </div>
              </div>

            </>
          }

        </Modal.Body>

        <Modal.Footer>

            <button
              type="button"
              className="btn btn-outline-primary ml-3"
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>

            <button
              type="button"
              disabled={loading}
              className="btn btn-primary ml-3"
              onClick={() => this._connect()}
            >
              Save
            </button>

        </Modal.Footer>
      </>
    )
  }
}
