import React from "react"

import moment from 'moment'

import StatCard from "./components/common/StatCard";
import DateRange from "./components/common/DateRange";
import PaymentsTable from "./components/tables/PaymentsTable";
import Backend from "../utils/Backend";
import Stats from "../utils/Stats";
import Currency from "../utils/Currency";
import Event from "../utils/Event";

export default class Payments extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dateFrom: null,
      dateTo: null,
    }
  }

  componentDidMount() {
    Event.on('CURRENCY_CHANGED', () => {
      this._loadStats()
    })
    if(window.General.DefaultCurrency){
      this._loadStats()
    }
  }

  _loadStats(){
    let dateTo = moment()
    let dateFrom = moment().add(-1, "years")

    Backend.getProcesseedStats(dateFrom.toISOString(), dateTo.toISOString())
    .then(stats => {

      let data = stats.admin || stats.association

      let paymentsNo = Stats.getTotalPayments(data)
      let commissionValue = Stats.getTotalCommissionValue(data)

      this.setState({
        stats: Stats.getProcesseedStats(data.processed),
        avgCommission: Currency.format(window.General.DefaultCurrency.symbol, commissionValue / paymentsNo),
      })
    })
  }

  _renderStatCards(){
    let {
      stats,
      avgCommission
    } = this.state

    if(!stats) return null

    return (
      <>
        <StatCard
          amount={stats.processed_last_7_days_no}
          title={'Payments This Week'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={stats.processed_month_no}
          title={'Payments This Month'}
          icon={'chart'}
          plain={true}
        />
        <StatCard
          amount={avgCommission}
          title={'Avg Commission Per Transaction'}
          icon={'chart'}
          plain={true}
        />
      </>
    )
  }

  render() {
    let {
      dateFrom,
      dateTo
    } = this.state

    return (
      <>
        <DateRange
          dateFrom={dateFrom}
          dateTo={dateTo}
          onFromDateUpdated={(dateFrom) => {
            this.setState({
              dateFrom
            }, () => this._loadStats())
          }}
          onToDateUpdated={(dateTo) => {
            this.setState({
              dateTo
            }, () => this._loadStats())
          }}
        />
        <div className={'row'}>
          {this._renderStatCards()}
        </div>
        <PaymentsTable
          title={'All Payments'}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </>
    )
  }

}
