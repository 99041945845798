import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import PaymentAttemptItem from "./PaymentAttemptItem";

import LoadingItem from "../common/LoadingItem";

import noContentImg from "../../../assets/media/svg/no-content/no-content.svg";


export default class PaymentAttemptsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.lazyLoadingList = React.createRef();
  }

  _renderPlaceholderItems() {
    return (
      <>
        <div>
          <LoadingItem />
        </div>
      </>
    );
  }

  _renderNoContent() {
    return (
      <div className="no-content">
        <div className="no-content-section text-center mt-10">
          <h3>Oops, no payment attempts found...</h3>
          <img
            src={noContentImg}
            alt="No Content"
            style={{ width: "40%", margin: "1rem auto" }}
          />
        </div>
      </div>
    );
  }

  render() {
    let searchTerm = this.props.searchTerm || "";
    let status = this.props.paymentAttemptStatus;
    let minDate = this.props.dateFrom?.format("YYYY-MM-DD") || "";
    let maxDate = this.props.dateTo?.format("YYYY-MM-DD") || "";
    let company = this.props.company
    let url = `${window.Api.PaymentAttempts}?search_term=${searchTerm}&min_date=${minDate}&max_date=${maxDate}`;

    if (status) {
      url += `&statuses=${status.value}`;
    }
    if(company){
      url += `&company_id=${company.id}`
    }

    return (
      <>
        <LazyLoadingList
          ref={this.lazyLoadingList}
          endpoint={url}
          onItemUpdated={(paymentAttempts, isInitialLoading) => {
            this.setState({
              paymentAttempts,
              isLoading: true,
            });

            if (isInitialLoading && paymentAttempts.length > 0) {
              this.props.onClick(paymentAttempts[0]);
            }
          }}
          renderItem={(paymentAttempt) => {
            return (
              <PaymentAttemptItem
                paymentAttempt={paymentAttempt}
                selectedPaymentAttempt={this.props.selectedPaymentAttempt}
                onUpdated={() => this.lazyLoadingList.current.refresh()}
                onClick={() => this.props.onClick(paymentAttempt)}
              />
            );
          }}
          renderInitialLoading={() => this._renderPlaceholderItems()}
          renderLoadingMore={() => this._renderPlaceholderItems}
          renderNoContent={() => this._renderNoContent()}
        />
      </>
    );
  }
}
